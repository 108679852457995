/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../modal/modal';
import classNames from 'classnames';
import styles from './help.scss';
import ReactMarkdown from 'react-markdown';

const buttonClasses = classNames('rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 w-full cursor-pointer my-1');


export const Icon = (props) => {
  return (
    <svg className={styles.gravysvg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1" x="0px" y="0px" width="20" height="20">
      <title>{title}</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect fillRule="nonzero" x="0" y="0" width="24" height="24"></rect>
        <path d="M11.5,19 C15.6421356,19 19,15.6421356 19,11.5 C19,7.35786438 15.6421356,4 11.5,4 C7.35786438,4 4,7.35786438 4,11.5 C4,15.6421356 7.35786438,19 11.5,19 Z M11.5,20 C6.80557963,20 3,16.1944204 3,11.5 C3,6.80557963 6.80557963,3 11.5,3 C16.1944204,3 20,6.80557963 20,11.5 C20,16.1944204 16.1944204,20 11.5,20 Z" fill="#000000" fillRule="nonzero"></path>
        <rect fill="#000000" fillRule="nonzero" x="11" y="10" width="1" height="5"></rect>
        <rect fill="#000000" fillRule="nonzero" x="10" y="15" width="3" height="1"></rect>
        <rect fill="#000000" fillRule="nonzero" x="10" y="10" width="2" height="1"></rect>
        <path d="M11.5,8.5 C10.9477153,8.5 10.5,8.05228475 10.5,7.5 C10.5,6.94771525 10.9477153,6.5 11.5,6.5 C12.0522847,6.5 12.5,6.94771525 12.5,7.5 C12.5,8.05228475 12.0522847,8.5 11.5,8.5 Z" fill="#000000" fillRule="nonzero"></path>
      </g>
    </svg>
  )
}

const HelpModel = (props) => {
  const { title, onClose, markdown, buttonText, buttonURL } = props;

  let button = null;
  if (buttonText && buttonURL) {
    button = <button
      onClick={(e) => { e.preventDefault(); window.open(buttonURL, '_blank') }}
      className={buttonClasses}>{buttonText}</button>;
  }

  return (
    <Modal
      title={title}
      customCloseHandler={onClose}
    >
      <div className={styles.markdown}>
        <ReactMarkdown children={markdown} />
      </div>
      {button}
    </Modal>
  )
};

const Help = (props) => {
  const {
    title, markdown, buttonText, buttonURL, showModal, setShowModal, url
  } = props;

  // If we are given just a URL, link directly to the content
  if (url) {
    return (
      <a href={url} className={styles.help} target="_blank">
        <svg className={styles.gravysvg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1" x="0px" y="0px" width="20" height="20">
          <title>{title}</title>
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect fillRule="nonzero" x="0" y="0" width="24" height="24"></rect>
            <path d="M11.5,19 C15.6421356,19 19,15.6421356 19,11.5 C19,7.35786438 15.6421356,4 11.5,4 C7.35786438,4 4,7.35786438 4,11.5 C4,15.6421356 7.35786438,19 11.5,19 Z M11.5,20 C6.80557963,20 3,16.1944204 3,11.5 C3,6.80557963 6.80557963,3 11.5,3 C16.1944204,3 20,6.80557963 20,11.5 C20,16.1944204 16.1944204,20 11.5,20 Z" fill="#000000" fillRule="nonzero"></path>
            <rect fill="#000000" fillRule="nonzero" x="11" y="10" width="1" height="5"></rect>
            <rect fill="#000000" fillRule="nonzero" x="10" y="15" width="3" height="1"></rect>
            <rect fill="#000000" fillRule="nonzero" x="10" y="10" width="2" height="1"></rect>
            <path d="M11.5,8.5 C10.9477153,8.5 10.5,8.05228475 10.5,7.5 C10.5,6.94771525 10.9477153,6.5 11.5,6.5 C12.0522847,6.5 12.5,6.94771525 12.5,7.5 C12.5,8.05228475 12.0522847,8.5 11.5,8.5 Z" fill="#000000" fillRule="nonzero"></path>
          </g>
        </svg>
      </a>
    )
  }

  // Normal help button operation
  return (
    <React.Fragment>
      <div className={styles.help}>
        <svg className={styles.gravysvg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1" x="0px" y="0px" width="20" height="20">
          <title>{title}</title>
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect fillRule="nonzero" x="0" y="0" width="24" height="24"></rect>
            <path d="M11.5,19 C15.6421356,19 19,15.6421356 19,11.5 C19,7.35786438 15.6421356,4 11.5,4 C7.35786438,4 4,7.35786438 4,11.5 C4,15.6421356 7.35786438,19 11.5,19 Z M11.5,20 C6.80557963,20 3,16.1944204 3,11.5 C3,6.80557963 6.80557963,3 11.5,3 C16.1944204,3 20,6.80557963 20,11.5 C20,16.1944204 16.1944204,20 11.5,20 Z" fill="#000000" fillRule="nonzero"></path>
            <rect fill="#000000" fillRule="nonzero" x="11" y="10" width="1" height="5"></rect>
            <rect fill="#000000" fillRule="nonzero" x="10" y="15" width="3" height="1"></rect>
            <rect fill="#000000" fillRule="nonzero" x="10" y="10" width="2" height="1"></rect>
            <path d="M11.5,8.5 C10.9477153,8.5 10.5,8.05228475 10.5,7.5 C10.5,6.94771525 10.9477153,6.5 11.5,6.5 C12.0522847,6.5 12.5,6.94771525 12.5,7.5 C12.5,8.05228475 12.0522847,8.5 11.5,8.5 Z" fill="#000000" fillRule="nonzero"></path>
          </g>
        </svg>
      </div>
      {showModal &&
        <HelpModel
          title={title}
          markdown={markdown}
          buttonText={buttonText}
          buttonURL={buttonURL}
          onClose={setShowModal}
        />
      }
    </React.Fragment>
  );
};

Help.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  buttonText: PropTypes.string,
  buttonURL: PropTypes.string,
  showModal: PropTypes.bool,
  url: PropTypes.string,
};

export default Help;
