import { createApiAction, API_URL } from './api';


export const RESET_REVIEW_FEEDBACK_CREATED = 'RESET_REVIEW_FEEDBACK_CREATED';

export const resetReviewFeedbackCreated = () => ({
  type: RESET_REVIEW_FEEDBACK_CREATED,
});

export const createOrUpdateReviewFeedback = createApiAction('CREATE_OR_UPDATE_REVIEW_FEEDBACK', (formData) => ({
  endpoint: `${API_URL}/api/student/review_feedback/create_or_update/`,
  method: 'POST',
  body: JSON.stringify(formData),
}));

export const getAssignmentReviewFeedbacks = createApiAction('GET_ASSIGNMENT_REVIEWFEEDBACKS', (assignmentID) => ({
  endpoint: `${API_URL}/api/admin/assignments/${assignmentID}/feedbacks/`,
  method: 'GET',
}));

export const getUserReviewFeedbacks = createApiAction('GET_USER_REVIEWFEEDBACKS', (userID) => ({
  endpoint: `${API_URL}/api/users/${userID}/feedbacks/`,
  method: 'GET',
}));

export const getFeedback = createApiAction('GET_FEEDBACK', (feedbackID) => ({
  endpoint: `${API_URL}/api/admin/review_feedback/${feedbackID}/`,
  method: 'GET',
}));

