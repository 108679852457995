import React from 'react';
import classNames from 'classnames';

const LeftArrow = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-6 w-6" aria-hidden fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16l-4-4m0 0l4-4m-4 4h18" />
    </svg>
  );
};

const RightArrow = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-6 w-6" aria-hidden fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
    </svg>
  );
};

const handleClick = (nextPage, setNext) => {
  setNext(nextPage);
};

const PageButton = (props) => {
  const { nextPage, setNext } = props;
  const disabled = !nextPage;
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={() => handleClick(nextPage, setNext)}
      className={classNames("inline-flex mr-2 items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500", {
        'bg-grey-500': disabled,
        'bg-white': !disabled,
        'hover:bg-blue-100': !disabled,
        'hover:border-blue-300': !disabled,
        'cursor-not-allowed': disabled,
      })}>
      {props.children}
    </button>
  );
};

const Pagination = (props) => {
  const { nextPageNum, previousPageNum, setCurrentPage, currentPage, totalPages } = props;

  return (
    <div className="inline-flex items-center">
      <PageButton nextPage={previousPageNum} setNext={setCurrentPage}><LeftArrow /> <span className="ml-2">Previous Page</span></PageButton>
      <PageButton nextPage={nextPageNum} setNext={setCurrentPage}><span className="mr-2">Next Page</span><RightArrow /></PageButton>
      <span className="mx-2 text-gray-500">Showing Page {currentPage} of {totalPages}</span>
    </div>
  );
};

export default Pagination;
