import produce from 'immer';
import { arrayToObject } from '../common/utils';
import {
  getCourses, createCourse, RESET_COURSE_CREATED, getCourse, updateCourse
} from '../actions/courses';


const initialState = {
  courses: {},
  courseDetail: {},
  loading: false,
  courseCreated: false,
};

const submissions = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case getCourses.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case getCourses.SUCCESS: {
      return {
        ...state,
        loading: false,
        courses: arrayToObject(action.payload.results),
      };
    }
    case getCourses.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case createCourse.REQUEST: {
      return {
        ...state,
        loading: true,
        courseCreated: false,
      };
    }
    case createCourse.SUCCESS: {
      const stateDraft = draft;
      const course = action.payload;
      stateDraft.courses[course.id] = course;
      stateDraft.loading = false;
      stateDraft.courseCreated = true;
      break;
    }
    case createCourse.FAILURE: {
      return {
        ...state,
        loading: false,
        courseCreated: false,
      };
    }
    case RESET_COURSE_CREATED: {
      return {
        ...state,
        loading: false,
        courseCreated: false,
      };
    }
    case getCourse.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case getCourse.SUCCESS: {
      return {
        ...state,
        loading: false,
        courseDetail: action.payload,
      };
    }
    case getCourse.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case updateCourse.REQUEST: {
      return {
        ...state,
        loading: true,
        courseCreated: false,
      };
    }
    case updateCourse.SUCCESS: {
      const stateDraft = draft;
      const course = action.payload;
      stateDraft.courses[course.id] = course;
      stateDraft.courseDetail = course;
      stateDraft.loading = false;
      stateDraft.courseCreated = true;
      break;
    }
    case updateCourse.FAILURE: {
      return {
        ...state,
        loading: false,
        courseCreated: false,
      };
    }
    default:
      return state;
  }
});

export default submissions;
