// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".course-list-item__container___3UTMC{\n  width:25%\n}\n\n.course-list-item__item___13LMv{\n  overflow:hidden;\n  box-shadow:0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);\n  margin:0.5rem;\n  min-height:200px\n}\n\n.course-list-item__item___13LMv:hover{\n  background-color:#f3f3f3;\n  cursor:pointer\n}\n\n.course-list-item__content___1ZQ9M{\n  padding-left:1.5rem;\n  padding-right:1.5rem;\n  padding-top:1rem;\n  padding-bottom:1rem\n}\n\n.course-list-item__title___ojkqr{\n  font-weight:700;\n  font-size:1.25rem;\n  margin-bottom:0.5rem\n}\n\n.course-list-item__subtitle___2B8Gt{\n  --text-opacity:1;\n  color:#4a5568;\n  color:rgba(74, 85, 104, var(--text-opacity));\n  font-size:0.75rem\n}\n\n.course-list-item__footer___2RjQS{\n  padding-left:1.5rem;\n  padding-right:1.5rem;\n  padding-top:1rem;\n  padding-bottom:1rem\n}\n\n.course-list-item__tag___1sMXe{\n  font-weight:bold\n}", "",{"version":3,"sources":["course-list-item.scss","<no source>"],"names":[],"mappings":"AAAA;ECAA;ADAuB;;AAAC;ECAxB,gBAAA;EAAA,2EAAA;EAAA,cAAA;EDAiE;AAAgB;;AAAC;EAAY,wBAAwB;EAAC;AAAc;;AAAC;ECAtI,oBAAA;EAAA,qBAAA;EAAA,iBAAA;EAAA;ADA+J;;AAAC;ECAhK,gBAAA;EAAA,kBAAA;EAAA;ADAoM;;AAAC;ECArM,iBAAA;EAAA,cAAA;EAAA,6CAAA;EAAA;ADA2O;;AAAC;ECA5O,oBAAA;EAAA,qBAAA;EAAA,iBAAA;EAAA;ADAoQ;;AAAC;EAAK;AAAgB","file":"course-list-item.scss","sourcesContent":[".container{@apply w-1/4}.item{@apply overflow-hidden shadow  m-2;min-height:200px}.item:hover{background-color:#f3f3f3;cursor:pointer}.content{@apply px-6 py-4}.title{@apply font-bold text-xl mb-2}.subtitle{@apply text-gray-700 text-xs}.footer{@apply px-6 py-4}.tag{font-weight:bold}",null]}]);
// Exports
exports.locals = {
	"container": "course-list-item__container___3UTMC",
	"item": "course-list-item__item___13LMv",
	"content": "course-list-item__content___1ZQ9M",
	"title": "course-list-item__title___ojkqr",
	"subtitle": "course-list-item__subtitle___2B8Gt",
	"footer": "course-list-item__footer___2RjQS",
	"tag": "course-list-item__tag___1sMXe"
};
module.exports = exports;
