import { connect } from 'react-redux';

import {
  getFeedback,
} from '../../../actions/review-feedbacks';
import FeedbackDetail from './feedback-detail';

const mapStateToProps = (state) => ({
  user: state.auth.user,
  feedbackDetail: state.reviewFeedbacks.reviewFeedbackDetail,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const { match: { params: { id } } } = ownProps;
  return {
    getFeedback: () => dispatch(getFeedback(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackDetail);
