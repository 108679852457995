import { connect } from 'react-redux';

import { getSections } from '../../../actions/sections';
import { getCourses } from '../../../actions/courses';
import { getSubmissions } from '../../../actions/submissions';
import { coursesSelector } from '../../../selectors/courses';

import Dashboard from './dashboard';

const mapStateToProps = (state) => ({
  courses: coursesSelector(state.courses.courses),
});

const mapDispatchToProps = {
  getCourses,
  getSections,
  getSubmissions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
