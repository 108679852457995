import produce from 'immer';
import { arrayToObject } from '../common/utils';
import {
  createOrUpdateReviewFeedback, RESET_REVIEW_FEEDBACK_CREATED,
  getAssignmentReviewFeedbacks, getUserReviewFeedbacks, getFeedback
} from '../actions/review-feedbacks';


const initialState = {
  reviewFeedbacks: {},
  reviewFeedbackDetail: {},
  loading: false,
  reviewFeedbackCreated: false,
};

const reviewFeedbacks = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case RESET_REVIEW_FEEDBACK_CREATED: {
      return {
        ...state,
        loading: false,
        reviewFeedbackDetail: {},
        reviewFeedbackCreated: false,
      };
    }
    case createOrUpdateReviewFeedback.REQUEST: {
      return {
        ...state,
        loading: true,
        reviewFeedbackCreated: false,
      };
    }
    case createOrUpdateReviewFeedback.SUCCESS: {
      return {
        ...state,
        loading: false,
        reviewFeedbackCreated: true,
      };
    }
    case createOrUpdateReviewFeedback.FAILURE: {
      return {
        ...state,
        loading: false,
        reviewFeedbackCreated: false,
      };
    }
    case getAssignmentReviewFeedbacks.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case getAssignmentReviewFeedbacks.SUCCESS: {
      return {
        ...state,
        loading: false,
        reviewFeedbacks: arrayToObject(action.payload.results),
      };
    }
    case getAssignmentReviewFeedbacks.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case getUserReviewFeedbacks.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case getUserReviewFeedbacks.SUCCESS: {
      return {
        ...state,
        loading: false,
        reviewFeedbacks: arrayToObject(action.payload.results),
      };
    }
    case getUserReviewFeedbacks.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case getFeedback.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case getFeedback.SUCCESS: {
      return {
        ...state,
        loading: false,
        reviewFeedbackDetail: action.payload,
      };
    }
    case getFeedback.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
});

export default reviewFeedbacks;
