// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".list__container___2nWX5{\n  display:flex;\n  flex-wrap:wrap\n}\n\n.list__fullWidth___2UbCI{\n  width:100%;\n  display:flex;\n  flex-flow:row wrap\n}\n\n.list__wrapper___2emgT{\n  width:100%;\n  display:flex;\n  flex-flow:row wrap;\n  margin-left:-20px;\n  margin-right:-20px\n}", "",{"version":3,"sources":["list.scss","<no source>"],"names":[],"mappings":"AAAA;ECAA,aAAA;EDAuB;AAAc;;AAAC;EAAW,UAAU;EAAC,YAAY;EAAC;AAAkB;;AAAC;EAAS,UAAU;EAAC,YAAY;EAAC,kBAAkB;EAAC,iBAAiB;EAAC;AAAkB","file":"list.scss","sourcesContent":[".container{@apply flex;flex-wrap:wrap}.fullWidth{width:100%;display:flex;flex-flow:row wrap}.wrapper{width:100%;display:flex;flex-flow:row wrap;margin-left:-20px;margin-right:-20px}",null]}]);
// Exports
exports.locals = {
	"container": "list__container___2nWX5",
	"fullWidth": "list__fullWidth___2UbCI",
	"wrapper": "list__wrapper___2emgT"
};
module.exports = exports;
