import produce from 'immer';
import { arrayToObject } from '../common/utils';
import {
  getSections, getSectionDetail, getSectionMembers, getSectionsForCourse,
  createSection, RESET_SECTION_CREATED, updateSection,
  createSectionParticipants, RESET_PARTICIPANTS_CREATED,
  createSingleSectionParticipant
} from '../actions/sections';


const initialState = {
  sections: {},
  sectionDetail: {},
  sectionMembers: {},
  loading: false,
  sectionCreated: false,
  participantsCreated: false,
};

const submissions = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    // ---------------------------------------------- GET SECTIONS
    case getSections.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case getSections.SUCCESS: {
      return {
        ...state,
        loading: false,
        sections: arrayToObject(action.payload.results),
      };
    }
    case getSections.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    // ---------------------------------------------- GET SECTION DETAIL
    case getSectionDetail.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case getSectionDetail.SUCCESS: {
      const stateDraft = draft;
      const section = action.payload;
      stateDraft.sectionDetail = section;
      stateDraft.loading = false;
      break;
    }
    case getSectionDetail.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    // ---------------------------------------------- GET SECTIONS FOR COURSE
    case getSectionsForCourse.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case getSectionsForCourse.SUCCESS: {
      return {
        ...state,
        loading: false,
        sections: arrayToObject(action.payload.results),
      };
    }
    case getSectionsForCourse.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    // ---------------------------------------------- CREATE SECTION
    case createSection.REQUEST: {
      return {
        ...state,
        loading: true,
        sectionCreated: false,
      };
    }
    case createSection.SUCCESS: {
      const stateDraft = draft;
      const section = action.payload;
      stateDraft.sections[`'${section.id}'`] = section;
      stateDraft.loading = false;
      stateDraft.sectionCreated = true;
      break;
    }
    case createSection.FAILURE: {
      return {
        ...state,
        loading: false,
        sectionCreated: false,
      };
    }
    // ---------------------------------------------- RESET SECTION CREATED
    case RESET_SECTION_CREATED: {
      return {
        ...state,
        loading: false,
        sectionCreated: false,
      };
    }
    // ---------------------------------------------- UPDATE SECTION
    case updateSection.REQUEST: {
      return {
        ...state,
        loading: true,
        sectionCreated: false,
      };
    }
    case updateSection.SUCCESS: {
      const stateDraft = draft;
      const section = action.payload;
      stateDraft.sections[section.id] = section;
      stateDraft.sectionDetail = section;
      stateDraft.loading = false;
      stateDraft.sectionCreated = true;
      break;
    }
    case updateSection.FAILURE: {
      return {
        ...state,
        loading: false,
        sectionCreated: false,
      };
    }
    // ---------------------------------------------- GET SECTION MEMBERS
    case getSectionMembers.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case getSectionMembers.SUCCESS: {
      const stateDraft = draft;
      stateDraft.sectionMembers = arrayToObject(action.payload);
      stateDraft.loading = false;
      break;
    }
    case getSectionMembers.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    // ---------------------------------------------- CREATE PARTICIPANTS
    case createSectionParticipants.REQUEST: {
      return {
        ...state,
        loading: true,
        participantsCreated: false,
      };
    }
    case createSectionParticipants.SUCCESS: {
      return {
        ...state,
        loading: true,
        participantsCreated: true,
      };
    }
    case createSectionParticipants.FAILURE: {
      return {
        ...state,
        loading: false,
        sectionCreated: false,
      };
    }
    // ---------------------------------------------- RESET PARTICIPANTS CREATED
    case RESET_PARTICIPANTS_CREATED: {
      return {
        ...state,
        loading: false,
        participantsCreated: false,
      };
    }
    // ---------------------------------------------- CREATE PARTICIPANTS
    case createSingleSectionParticipant.REQUEST: {
      return {
        ...state,
        loading: true,
        participantsCreated: false,
      };
    }
    case createSingleSectionParticipant.SUCCESS: {
      const stateDraft = draft;
      const sectionMember = action.payload;
      stateDraft.sectionMembers[`'${sectionMember.id}'`] = sectionMember;
      stateDraft.loading = false;
      stateDraft.participantsCreated = true;
      break;
    }
    case createSingleSectionParticipant.FAILURE: {
      return {
        ...state,
        loading: false,
        sectionCreated: false,
      };
    }
    // ---------------------------------------------- DEFAULT
    default:
      return state;
  }
});

export default submissions;
