import React from 'react';
import { useQuery } from 'react-query';
import { getMe, userQuerySettings } from '../../queries/user';

const PlatformName = (props) => {

  const user = useQuery('me', getMe, userQuerySettings);

  // Return null while loading
  if (user.isLoading) {
    return <>Loading</>;
  }

  // If they're HBP return different name
  if (user.isSuccess && user.data.is_hbp) {
    return (<>Peer Exchange</>)
  }

  // Return default for non-HBP
  return (<>Peer Learning</>)

};

export default PlatformName;