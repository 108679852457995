import React, { useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useParams, useHistory, NavLink } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import Loading from '../../../components/loading/loading';
import { getAssignment, transitionToCompleted } from '../../../queries/admin-queries';
import { useForm } from 'react-hook-form';
import { shortDate, durationBetween, nowUtil } from '../../../common/utils';
import { stateText, nextState } from '../../../common/state';

const pClasses = "my-4";
const buttonClasses = classNames('rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 w-full cursor-pointer my-1');

const labelClasses = classNames('ml-6 block font-medium text-gray-700')

const dateInPast = (d) => {
  const now = new Date();
  const compare = moment(d);
  if (compare <= now) {
    return true;
  } else {
    return false;
  }

};

const DeadlineWarning = (props) => {
  const { assignment } = props

  let deadline = assignment.review_feedback_due_date
  let stageText = stateText("review_feedback")

  if (assignment.skip_feedback_state) {
    deadline = assignment.review_due_date
    stageText = stateText("review")
  }

  const nextStage = nextState(assignment)
  const nextStageText = stateText(nextStage)

  if (dateInPast(deadline)) {
    return (null)
  } else {
    return (
      <div className="my-6 border border-red-500 p-6 bg-red-100">
        <h3 className="my-2 text-2xl text-red-700 font-bold">WARNING! Due date of {stageText} has not yet passed</h3>
        <p className="my-2">
          This assignment has a {stageText} deadline of {shortDate(deadline)} which is in {nowUtil(deadline)}.
          You can release feedback now, but this will end {stageText} for all students.
        </p>
      </div>
    )
  }
}

const AssignmentDetailComplete = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const queryClient = useQueryClient();
  const assignment = useQuery(['admin-assignment', id], () => getAssignment(id));
  const { register, handleSubmit } = useForm();

  if (assignment.isLoading) {
    return <Loading message="Loading assignment..." />;
  }

  const completeMutation = useMutation(transitionToCompleted, {
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries(['admin-assignment', variables.id]);
      history.push(`/assignments/${variables.id}/`);
    }
  });

  const onSubmit = (data) => {
    console.log("Submitting")
    console.dir(data)
    completeMutation.mutate({
      id: assignment.data.id,
      release_ai_feedback: data.release_ai_feedback,
      release_ai_critical_thinking_feedback: data.release_ai_critical_thinking_feedback,
      release_ai_writing_feedback: data.release_ai_writing_feedback,
    });
  }

  const isAI = assignment.data.is_ai;

  return (
    <React.Fragment>
      <h1 className="my-4 text-2xl font-bold">Release Feedback to Students</h1>
      <hr className="border-1 border-solid border-black my-2" />
      <DeadlineWarning assignment={assignment.data} />

      <p className="my-6">
        Students will receive written feedback from classmates. This step cannot be undone.
      </p>

      {assignment.data.is_ai && (
        <p className="my-6">You can now choose which ChatGPT generated feedback elements your students will see:</p>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        {isAI && (
          <div className="my-6 flex">
            <input
              type="checkbox"
              name="release_ai_feedback"
              id="release_ai_feedback"
              {...register("release_ai_feedback")}
            />
            <label htmlFor="release_ai_feedback" className={labelClasses}>Release ChatGPT generated reviews</label>
          </div>
        )}

        {isAI && assignment.data.include_ai_critical_thinking && (
          <div className="my-6 flex">
            <input
              type="checkbox"
              name="release_ai_critical_thinking_feedback"
              id="release_ai_critical_thinking_feedback"
              {...register("release_ai_critical_thinking_feedback")}
            />
            <label htmlFor="release_ai_critical_thinking_feedback" className={labelClasses}>Release ChatGPT Critical Thinking Feedback</label>
          </div>
        )}

        {isAI && assignment.data.include_ai_writing && (
          <div className="my-6 flex ">
            <input
              type="checkbox"
              name="release_ai_writing_feedback"
              id="release_ai_writing_feedback"
              {...register("release_ai_writing_feedback")}
            />
            <label htmlFor="release_ai_writing_feedback" className={labelClasses}>Release ChatGPT Writing Feedback</label>
          </div>
        )}

        {isAI && (
          <div className="my-8 flex">
            <p>
              Before releasing, you can review the ChatGPT generated feedback
              <NavLink to={`/assignments/${assignment.data.id}/reports`} className="ml-1 text-blue-500 hover:text-blue-700 underline">in this assignment's reports</NavLink>.
            </p>
          </div>
        )}

        <div className="my-8 flex">
          <button type="submit" className="rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 cursor-pointer my-1">
            Release Feedback to Students Now &amp; Complete Assignment
          </button>
          <a className="rounded py-2 px-4 text-lg text-gray-800 border border-gray-600 bg-gray-200 hover:bg-gray-400 cursor-pointer my-1 mx-4" onClick={() => { history.goBack() }}>Cancel</a>
        </div>
      </form>
    </React.Fragment>
  );
};

export default AssignmentDetailComplete;
