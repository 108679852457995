import React from 'react';
import PropTypes from 'prop-types';

import styles from './checkbox.scss';

const Checkbox = React.forwardRef((props, ref) => {
  const {
    name, label, onChange, disabled, checked
  } = props;

  return (
    <div className={styles.container}>
      <input
        type="checkbox"
        className={styles.input}
        name={name}
        id={name}
        disabled={disabled}
        onChange={onChange}
        checked={checked}
        ref={ref}
      />
      {label && (
        <label
          className={styles.label}
          htmlFor={name}
        >
          {label}
        </label>
      )}
    </div>
  );
});

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  label: undefined,
  onChange: () => { },
  checked: false,
  disabled: false,
};

export default Checkbox;
