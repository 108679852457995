import Cookies from 'js-cookie';
import { useQuery } from 'react-query';


export const getHelp = async (path) => {
  const response = await fetch(`/api/help/?path=${path}`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
  });
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
}

export const getFormHelp = async (form) => {
  const response = await fetch(`/api/formhelp/?form=${form}`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
  });
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
}

export const formHelpQuerySettings = {
  cacheTime: 60000,
  staleTime: 60000,
};