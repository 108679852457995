// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".file-upload__container___1rjTb{\n  display:flex;\n  flex-direction:row;\n  justify-content:center;\n  align-items:center;\n  border-radius:4px;\n  padding:10px 1rem;\n  transition:all .5s cubic-bezier(0.25, 0.1, 0.25, 1);\n  cursor:pointer;\n  background-color:#ebebeb\n}\n\n.file-upload__container___1rjTb:hover{\n  background-color:#f3f3f3\n}\n\n.file-upload__active___2l195{\n  background-color:silver\n}\n\n.file-upload__label___iD477{\n  margin-left:5px\n}", "",{"version":3,"sources":["file-upload.scss"],"names":[],"mappings":"AAAA;EAAW,YAAY;EAAC,kBAAkB;EAAC,sBAAsB;EAAC,kBAAkB;EAAC,iBAAiB;EAAC,iBAAiB;EAAC,mDAAmD;EAAC,cAAc;EAAC;AAAwB;;AAAC;EAAiB;AAAwB;;AAAC;EAAQ;AAAuB;;AAAC;EAAO;AAAe","file":"file-upload.scss","sourcesContent":[".container{display:flex;flex-direction:row;justify-content:center;align-items:center;border-radius:4px;padding:10px 1rem;transition:all .5s cubic-bezier(0.25, 0.1, 0.25, 1);cursor:pointer;background-color:#ebebeb}.container:hover{background-color:#f3f3f3}.active{background-color:silver}.label{margin-left:5px}"]}]);
// Exports
exports.locals = {
	"container": "file-upload__container___1rjTb",
	"active": "file-upload__active___2l195",
	"label": "file-upload__label___iD477"
};
module.exports = exports;
