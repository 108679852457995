import { createApiAction, API_URL } from './api';


export const RESET_REVIEW_CREATED = 'RESET_REVIEW_CREATED';

export const resetReviewCreated = () => ({
  type: RESET_REVIEW_CREATED,
});

export const createOrUpdateReview = createApiAction('CREATE_OR_UPDATE_REVIEW', (formData) => ({
  endpoint: `${API_URL}/api/student/reviews/create_or_update/`,
  method: 'POST',
  body: JSON.stringify(formData),
}));

export const getAssignmentReviews = createApiAction('GET_ASSIGNMENT_REVIEWS', (assignmentID) => ({
  endpoint: `${API_URL}/api/admin/assignments/${assignmentID}/reviews/`,
  method: 'GET',
}));

export const getUserReviews = createApiAction('GET_USER_REVIEWS', (userID) => ({
  endpoint: `${API_URL}/api/users/${userID}/reviews/`,
  method: 'GET',
}));

export const getReview = createApiAction('GET_REVIEW', (reviewID) => ({
  endpoint: `${API_URL}/api/admin/reviews/${reviewID}/`,
  method: 'GET',
}));

