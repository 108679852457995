import { connect } from 'react-redux';

import {
  getSubmission
} from '../../../actions/submissions';
import SubmissionDetail from './submission-detail';

const mapStateToProps = (state) => ({
  user: state.auth.user,
  currentSubmission: state.submissions.currentSubmission,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const { match: { params: { id } } } = ownProps;
  return {
    getSubmission: () => dispatch(getSubmission(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionDetail);
