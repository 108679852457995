import { connect } from 'react-redux';
import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import HelpLabel from '../../../components/help-label';
import CreateNewAssignmentButton from '../create-new-assignment-button';
import styles from './section-detail.scss';
import { getMe, userQuerySettings } from '../../../queries/user';
import { getFormHelp, formHelpQuerySettings } from '../../../queries/help';

const SectionDetailOverview = (props) => {
  const {
    sectionDetail
  } = props;

  const { id } = useParams();
  const user = useQuery('me', getMe, userQuerySettings);
  const help = useQuery(['form-help', 'section-overview'], () => getFormHelp('section-overview'), formHelpQuerySettings);

  if (user.isLoading || help.isLoading) {
    return <div>Loading...</div>;
  }

  let showEditLink = !user.data.is_hbp && !user.data.is_lti;
  let isDemo = user.data.is_demo;

  return (
    <React.Fragment>
      <div className={styles.actions}>

        <div className="container">
          {showEditLink && (
            <Link
              className={styles.smallButton}
              to={{ pathname: `/sections/${id}/edit`, state: { modal: true } }}
            >
              Edit Section
            </Link>
          )}
        </div>
      </div>
      <div className="container">
        <div className={styles.header}>
          <h3>
            {sectionDetail.name}
          </h3>
        </div>
        {!isDemo && (
          <>
            <div className="my-6">
              <CreateNewAssignmentButton sectionID={id} type="normal" isHBP={user.data.is_hbp} label="Create Individual Assignment" />
              <HelpLabel className="inline ml-4" label="One submission per student." name="individual_assignment" help={help} />
            </div>
            <div className="my-6">
              <CreateNewAssignmentButton sectionID={id} type="team" isHBP={user.data.is_hbp} label="Create Team Assignment" />
              <HelpLabel className="inline ml-4" label="One submission per team." name="team_assignment" help={help} />
            </div>
          </>
        )}
        {isDemo && (
          <div className="my-6 bg-blue-100 border border-blue-500 p-6">
            <p className="my-2">Creating new Assignments is disabled in the demo.</p>
          </div>
        )}
        <div className={styles.content}>
          <p>
            {sectionDetail.description}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

SectionDetailOverview.propTypes = {
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  sectionDetail: state.sections.sectionDetail,
  loading: state.sections.loading,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionDetailOverview);
