/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import produce from 'immer';

import RatingBox from '../rating/rating';
import Input from '../input/input';
import Checkbox from '../checkbox';
import styles from './rubric-form.scss';

function RubricForm(props) {
  const {
    data, submitForm, setFormState, readOnly,
  } = props;

  const [rubricFormState, setRubricFormState] = useState(data);

  useEffect(() => {
    if (data.length > 0) {
      const newState = produce(data, (draftState) => {
        for (let i = 0; i < draftState.length; i += 1) {
          if (draftState[i].type === 'description') {
            draftState[i].answer = null;
          }
        }
      });

      setRubricFormState(
        newState
      );
    }
  }, [data]);

  useEffect(() => {
    if (readOnly) { return; }

    if (!setFormState) { return; }

    if (rubricFormState.length === 0) {
      return;
    }

    if (setFormState) {
      setFormState({
        rubricFormState,
      });
    }
  }, [rubricFormState]);

  const handleSubmitForm = () => {
    submitForm(
      rubricFormState,
    );
  };

  const handleChangeInput = (item, e) => {
    let answer = e;
    if (item.type === 'text') {
      answer = e.target.value;
    }
    if (item.type === 'bool') {
      answer = e.target.checked;
    }
    const newState = produce(rubricFormState, (draftState) => {
      const objIndex = draftState.findIndex((obj => obj.id === item.id));
      draftState[objIndex].answer = answer;
    });
    setRubricFormState(newState);
  };

  let questionNumber = 0;
  const inputs = rubricFormState.map((item, index) => {

    if (item.type === 'description') {
      return (
        <div className={styles.row} key={`row-${index}`}>
          <p>{item.label}</p>
        </div>
      );
    }

    if (item.type === 'text') {
      questionNumber++;
      return (
        <div className={styles.row} key={`row-${index}`}>
          <label
            className={styles.label}
            htmlFor={item.id}
          >
            {questionNumber}. {item.label}
          </label>
          {!readOnly && (
            <Input
              type="textarea"
              name={`input-${item.id}`}
              defaultValue={item.answer}
              onChange={(e) => handleChangeInput(item, e)}
            />
          )}
          {readOnly && (
            <p>{item.answer}</p>
          )}
        </div>
      );
    }

    // ITEM TYPE 5-STARS
    if (item.type === '5-stars') {
      questionNumber++;
      return (
        <div className={styles.row} key={`row-${index}`}>
          <label className={styles.label} htmlFor={item.id}>
            {questionNumber}. {item.label}
          </label>
          <RatingBox
            className={styles.rating}
            initialRating={item.answer}
            readonly={readOnly}
            name={`input-${item.id}`}
            onRatingChange={(e) => handleChangeInput(item, e)}
            maxRating={5}
          />
        </div>
      );
    }

    // ITEM TYPE CHECKBOX
    if (item.type === 'bool') {
      questionNumber++;
      return (
        <div className={styles.row} key={`row-${index}`}>
          <label className={styles.label}>
            {questionNumber}. {item.label}
          </label>
          <Checkbox
            type="checkbox"
            name={`input-${item.id}`}
            checked={item.answer}
            disabled={readOnly}
            onChange={(e) => handleChangeInput(item, e)}
          />
        </div>
      );

    }
    return null;
  });

  let saveButton = (
    <button
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-4 px-12 rounded"
      onClick={handleSubmitForm}
    >
      Save
    </button>
  );

  if (readOnly) {
    saveButton = null;
  }

  return (
    <div className={styles.form}>
      {inputs}
      {saveButton}
    </div>
  );
}

RubricForm.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  submitForm: PropTypes.func,
  setFormState: PropTypes.func,
  readOnly: PropTypes.bool,
};

RubricForm.defaultProps = {
  submitForm: () => { },
  readOnly: false,
  setFormState: undefined,
};

export default RubricForm;
