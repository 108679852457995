import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import HelpLabel from '../help-label';
import styles from './fancy-checkbox.scss';

const FancyCheckbox = React.forwardRef((props, ref) => {
  const { name, label, disabled, register, defaultChecked, help } = props;

  const [checked, setChecked] = useState(defaultChecked);

  return (
    <div className={styles.container}>
      <input
        type="checkbox"
        className={styles.input}
        name={name}
        id={name}
        disabled={disabled}
        {...register(name)}
      />
      <HelpLabel
        label={label}
        name={name}
        help={help}
        disabled={disabled}
      />
    </div>
  );
});

FancyCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

FancyCheckbox.defaultProps = {
  label: undefined,
  checked: false,
  disabled: false,
};

export default FancyCheckbox;
