import React, { useEffect } from 'react';
import PropTypes from 'prop-types';


const LeaveWarning = (props) => {
  const { when } = props;

  useEffect(() => {
    if (!when) {
      return; // We aren't in effect
    }

    const handleTabClose = event => {
      event.preventDefault();
      event.returnValue = '';
    };

    console.log("adding event listener");
    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      console.log("removing event listener");
      window.removeEventListener('beforeunload', handleTabClose);
    };

  }, [when]);

  return (
    <React.Fragment></React.Fragment>
  );
};

LeaveWarning.propTypes = {
  when: PropTypes.bool.isRequired,
  message: PropTypes.string
};

export default LeaveWarning;