// same configuration you would create for the Rollbar.js SDK
const token = '9694fa81428c4ac6a381b6a4fe2c6bd5'

const getRollbarConfig = () => {
  const host = window.location.host;
  if (host == "dev.peerexchange.io") {
    return {
      accessToken: token,
      environment: 'development',
      server: {
        root: "http://dev.peerexchange.io/",
        branch: "main"
      }
    }
  }
  if (host === "dashboard.peerexchange.io") {
    return {
      accessToken: token,
      environment: 'production',
      server: {
        root: "http://dev.peerexchange.io/",
        branch: "main"
      }
    }
  }
  return {
    accessToken: token,
    environment: 'local',
    server: {
      root: "http://localhost:8000/",
      branch: "main"
    }
  }
}

export default getRollbarConfig
