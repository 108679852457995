import Cookies from 'js-cookie';

export const getSubmission = async (submissionId) => {
  const response = await fetch(`/api/admin/submissions/${submissionId}/`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  return response.json();
};

export const getAssignmentDefaults = {
  cacheTime: 600000,
  staleTime: 60000,
  refetchInterval: (data) => !data || data.state.includes('adv_') ? 2000 : undefined,
};

export const getAssignment = async (assignmentId) => {
  const response = await fetch(`/api/admin/assignments/${assignmentId}/`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
};

export const createAssignment = async (formData) => {
  const response = await fetch('/api/admin/assignments/', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
    body: JSON.stringify(formData),
  });
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
};

export const updateAssignment = async (assignmentId, formData) => {
  const response = await fetch(`/api/admin/assignments/${assignmentId}/`, {
    method: 'PATCH',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
    body: JSON.stringify(formData),
  });
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
};

export const getAssignmentSubmissions = async (assignmentId, currentPage, filter) => {
  const response = await fetch(`/api/admin/assignments/${assignmentId}/submissions/?page=${currentPage}&filter=${filter}`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  return response.json();
};

export const getAssignmentReviews = async (assignmentId, currentPage, filter) => {
  const response = await fetch(`/api/admin/assignments/${assignmentId}/reviews/?page=${currentPage}&filter=${filter}`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  return response.json();
};

export const getAssignmentFeedbacks = async (assignmentId, currentPage, filter) => {
  const response = await fetch(`/api/admin/assignments/${assignmentId}/feedbacks/?page=${currentPage}&filter=${filter}`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  return response.json();
};

export const getAssignmentSubmissionCounts = async (assignmentId) => {
  const response = await fetch(`/api/admin/assignments/${assignmentId}/submissioncounts/`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  return response.json();
};

export const getAssignmentReviewCounts = async (assignmentId) => {
  const response = await fetch(`/api/admin/assignments/${assignmentId}/reviewcounts/`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  return response.json();
};

export const getAssignmentFeedbackCounts = async (assignmentId) => {
  const response = await fetch(`/api/admin/assignments/${assignmentId}/feedbackcounts/`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  return response.json();
};

export const getAssignmentReviewDistribution = async (assignmentId) => {
  const response = await fetch(`/api/admin/assignments/${assignmentId}/reviewdistribution/`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  return response.json();
};

export const getAssignmentFeedbackDistribution = async (assignmentId) => {
  const response = await fetch(`/api/admin/assignments/${assignmentId}/feedbackdistribution/`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  return response.json();
};

export const getAssignmentParticipation = async (assignmentId) => {
  const response = await fetch(`/api/admin/assignments/${assignmentId}/participation/`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  return response.json();
};

export const getAssignmentStudentReport = async (assignmentId, stage, bands) => {
  const response = await fetch(`/api/admin/assignments/${assignmentId}/student_report/?stage=${stage}&bands=${bands}`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  return response.json();
};

export const getAssignmentAICriticalThinking = async (assignmentId) => {
  const response = await fetch(`/api/admin/assignments/${assignmentId}/ai_critical_thinking/`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  return response.json();
};

export const getAssignmentAIWriting = async (assignmentId) => {
  const response = await fetch(`/api/admin/assignments/${assignmentId}/ai_writing/`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  return response.json();
};

export const getLeagues = async (assignmentId) => {
  const response = await fetch(`/api/admin/assignments/${assignmentId}/leagues/`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  return response.json();
};

export const getLeague = async (leagueID) => {
  const response = await fetch(`/api/admin/leagues/${leagueID}/details/`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  return response.json();
};

export const getReview = async (id) => {
  const response = await fetch(`/api/admin/reviews/${id}/`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  return response.json();
};

export const getUserDetail = async (id) => {
  const response = await fetch(`/api/users/${id}/`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  return response.json();
};

export const togglePause = async (data) => {
  const response = await fetch(`/api/admin/assignments/${data.id}/pause/`, {
    method: 'PATCH',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
    body: JSON.stringify({
      pause: data.pause,
      pause_message: data.message,
    })
  });
  return response.json();
};

export const sendReminderEmail = async (data) => {
  const response = await fetch(`/api/admin/assignments/${data.id}/send_reminder_email/`, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
  });
  return response.json();
};

export const sendDelayedEmail = async (data) => {
  const response = await fetch(`/api/admin/assignments/${data.id}/send_delayed_stage_email/`, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
  });
  return response.json();
};

export const transitionToSubmission = async (data) => {
  const response = await fetch(`/api/admin/assignments/${data.id}/to_submission_state/`, {
    method: 'PATCH',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
  });
  return response.json();
};

export const transitionToReview = async (data) => {
  const response = await fetch(`/api/admin/assignments/${data.id}/to_review_state/`, {
    method: 'PATCH',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
  });
  return response.json();
};

export const transitionToReviewFeedback = async (data) => {
  const response = await fetch(`/api/admin/assignments/${data.id}/to_review_feedback_state/`, {
    method: 'PATCH',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
  });
  return response.json();
};

export const transitionToOral = async (data) => {
  const response = await fetch(`/api/admin/assignments/${data.id}/to_oral_state/`, {
    method: 'PATCH',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
  });
  return response.json();
};

export const transitionToCompletedSkipFeedback = async (data) => {
  const response = await fetch(`/api/admin/assignments/${data.id}/to_completed_skip_feedback_state/`, {
    method: 'PATCH',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
  });
  return response.json();
};

export const transitionToCompleted = async (data) => {
  const response = await fetch(`/api/admin/assignments/${data.id}/to_completed_state/`, {
    method: 'PATCH',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
    body: JSON.stringify({
      release_ai_feedback: data.release_ai_feedback,
      release_ai_critical_thinking_feedback: data.release_ai_critical_thinking_feedback,
      release_ai_writing_feedback: data.release_ai_writing_feedback,
    })
  });
  return response.json();
};

export const publish = async (data) => {
  const response = await fetch(`/api/admin/assignments/${data.id}/publish/`, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
  });
  return response.json();
};


export const getCourse = async (id) => {
  const response = await fetch(`/api/admin/courses/${id}/`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  return response.json();
};

export const getCourseDefaults = {
  cacheTime: 10000,
  staleTime: 5000,
};

export const getCourseEmptySections = async (id) => {
  const response = await fetch(`/api/admin/courses/${id}/empty_sections/`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  return response.json();
};

export const createTeams = async (assignmentID, formData) => {
  const response = await fetch(`/api/admin/assignments/${assignmentID}/create_teams/`, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
    body: JSON.stringify(formData),
  });
  return response.json();
};

export const getNoTeamMembers = async (assignmentID) => {
  const response = await fetch(`/api/admin/assignments/${assignmentID}/no_team_members/`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
  });
  return response.json();
};

export const addTeamMember = async (values) => {
  const { leagueID, sectionMemberID } = values
  console.log(`In Query LeagueID: ${leagueID} SectionMemberID: ${sectionMemberID}`)
  const response = await fetch(`/api/admin/leagues/${leagueID}/add_team_member/`, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
    body: JSON.stringify({ section_member_id: sectionMemberID }),
  });
  return response.json();
};

export const removeTeamMember = async (values) => {
  const { leagueID, sectionMemberID } = values
  console.log(`In Query LeagueID: ${leagueID} SectionMemberID: ${sectionMemberID}`)
  const response = await fetch(`/api/admin/leagues/${leagueID}/remove_team_member/`, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
    body: JSON.stringify({ section_member_id: sectionMemberID }),
  });
  return response.json();
};

export const randomizeTeams = async (assignmentID) => {
  const response = await fetch(`/api/admin/assignments/${assignmentID}/randomize_teams/`, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
  });
  return response.json();
};

export const deleteTeam = async (id) => {
  const response = await fetch(`/api/admin/leagues/${id}/delete/`, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
  });
  return response.json();
};

export const getSectionMembers = async (assignmentID) => {
  const response = await fetch(`/api/admin/assignments/${assignmentID}/sectionmembers/`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
  });
  return response.json();
};

export const getStudentResult = async (assignmentID, sectionMemberID) => {
  const response = await fetch(`/api/admin/assignment-results/${assignmentID}/${sectionMemberID}/`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
  });
  return response.json();
};

export const getStudentResultCompare = async (assignmentID, sectionMemberID) => {
  const response = await fetch(`/api/admin/assignment-results-compare/${assignmentID}/${sectionMemberID}/`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
  });
  return response.json();
};

export const getStudentReviewFeedback = async (assignmentID, sectionMemberID) => {
  console.log("In Query")
  console.log(assignmentID)
  console.log(sectionMemberID)
  const response = await fetch(`/api/admin/assignment-results-feedback/${assignmentID}/${sectionMemberID}/`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  console.dir(response)
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
};
