import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Input from '../../components/input/input';
import Button from '../../components/button/button';

import { isAuthenticated } from '../../common/localstorage';
import { useInterval } from '../../common/utils';

import Logo from './logo';
import styles from './login.scss';


const Login = (props) => {
  const {
    register, handleSubmit, setError, formState: { errors, isSubmitting, isSubmitted }
  } = useForm();
  const [retryCount, setRetryCount] = useState(0);
  const { loginUser, history } = props;

  const onSubmit = (data) => { loginUser(data, setError); };

  useInterval(() => {
    if (retryCount < 30) {
      if (isAuthenticated(false)) {
        history.push('/dashboard');
      }
    }
    setRetryCount(retryCount + 1);
  }, 250);

  useEffect(() => {
    setRetryCount(0);
  }, [isSubmitting, isSubmitted])

  return (
    <div className={styles.container}>
      <div className="w-full max-w-xs">
        <form onSubmit={handleSubmit(onSubmit)} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <h1 className="text-2xl font-bold my-4">Peer Exchange Login</h1>
          <div className="mb-4">
            <Input
              label="Email"
              name="email"
              error={errors.email}
              {...register('email', { required: true })}
            />
          </div>
          <div className="mb-6">
            <Input
              label="Password"
              name="password"
              error={errors.password}
              type="password"
              {...register('password', { required: true })}
            />
          </div>
          <Button
            type="submit"
          >
            Login
          </Button>
        </form>
        <div className="w-full p-4 text-center">
          <Link to="/password-reset/" className="text-blue-800 hover:text-blue-600 underline">Forgotten your password?</Link>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
};


export default Login;
