import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Switch, NavLink, useParams
} from 'react-router-dom';
import { useQuery } from 'react-query';
import ProtectedRoute from '../../../routes/protected-route';
import Navigation from '../../../components/navigation/navigation';
import ScrollArea from '../../../components/scroll-area/scroll-area';

import ParticipantDetailSubmissions from './participant-detail-submissions';
import ParticipantDetailReviews from './participant-detail-reviews';
import ParticipantDetailFeedbacks from './participant-detail-feedbacks';
import { getUserDetail } from '../../../queries/admin-queries';
import { Checkmark, X } from '../../../';
import Loading from '../../../components/loading/loading';
import styles from './participant-detail.scss';


const ParticipantDetail = (props) => {
  const {
    match,
    getUserSubmissions,
    getUserReviews,
    getUserReviewFeedbacks,
  } = props;
  const { id } = useParams();
  const { path } = match;
  useEffect(() => {
    getUserSubmissions(id);
    getUserReviews(id);
    getUserReviewFeedbacks(id);
  }, []);

  const user = useQuery(['admin-user', id], () => getUserDetail(id));

  if (user.isLoading) {
    return <Loading />;
  }
  try {

    return (
      <div className={styles.container}>
        <Navigation
          title={user.data.display_name}
          hasBackButton
        />
        <ScrollArea>
          <div className={styles.tabsContainer}>
            <div className={styles.tabs}>
              <NavLink
                className={styles.tabLink}
                activeClassName={styles.tabLinkActive}
                to={`/participants/${id}`}
              >
                Submissions
              </NavLink>
              <NavLink
                className={styles.tabLink}
                activeClassName={styles.tabLinkActive}
                to={`/participants/${id}/reviews`}
              >
                Reviews
              </NavLink>
              <NavLink
                className={styles.tabLink}
                activeClassName={styles.tabLinkActive}
                to={`/participants/${id}/feedbacks`}
              >
                Feedbacks
              </NavLink>
            </div>
          </div>
          <div className={styles.content}>
            <Switch>
              <ProtectedRoute exact path={`${path}`} component={ParticipantDetailSubmissions} />
              <ProtectedRoute path={`${path}/reviews`} component={ParticipantDetailReviews} />
              <ProtectedRoute path={`${path}/feedbacks`} component={ParticipantDetailFeedbacks} />
            </Switch>
          </div>
        </ScrollArea>
      </div>
    );
  } catch (e) { console.dir(e); }
};

ParticipantDetail.propTypes = {
  getUserSubmissions: PropTypes.func.isRequired,
  getUserReviews: PropTypes.func.isRequired,
  getUserReviewFeedbacks: PropTypes.func.isRequired,
};

export default ParticipantDetail;
