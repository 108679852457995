import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getAssignment, getAssignmentDefaults, getAssignmentFeedbacks, getAssignmentFeedbackCounts } from '../../../queries/admin-queries';

import Loading from '../../../components/loading/loading';

import { FilterTable } from './common';

const AssignmentDetailFeedbacks = (props) => {
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState(null);
  const assignment = useQuery(['admin-assignment', id], () => getAssignment(id), getAssignmentDefaults);
  const feedbacks = useQuery(['admin-assignment-feedbacks', id, currentPage, filter], () => getAssignmentFeedbacks(id, currentPage, filter), { cacheTime: 600000, staleTime: 10000, keepPreviousData: true });
  const counts = useQuery(['admin-assignment-feedbackcounts', id], () => getAssignmentFeedbackCounts(id), { cacheTime: 600000, staleTime: 10000 });

  // What to refresh when user clients refresh
  const refreshKeys = [
    "admin-assignment-feedbackcounts",
    "admin-assignment-feedbacks",
  ];

  if (assignment.isLoading) {
    return (<Loading message="Loading assignment..." />);
  }

  if (feedbacks.isSuccess && feedbacks.data.length === 0) {
    return (
      <p>There are currently no Score Reviews</p>
    );
  }

  return (
    <React.Fragment>
      <table className="my-4">
        <thead>
          <tr>
            <th>Complete Score Reviews</th>
            <th>Incomplete Score Reviews</th>
            <th>Total Score Reviews</th>
          </tr>
        </thead>
        <tbody>
          {counts.isLoading && <tr><td colSpan="3"><Loading message="Loading counts..." /></td></tr>}
          {counts.isSuccess &&
            <tr>
              <td>{counts.data.complete_feedbacks}</td>
              <td>{counts.data.incomplete_feedbacks}</td>
              <td>{counts.data.total_feedbacks}</td>
            </tr>}
        </tbody>
      </table>
      {feedbacks.isLoading && <Loading message="Loading score reviews..." />}
      {feedbacks.isSuccess &&
        <FilterTable
          items={feedbacks.data.results}
          linkPrefix="feedbacks"
          assignmentID={id}
          refreshKeys={refreshKeys}
          currentPage={currentPage}
          nextPageNum={feedbacks.data.next_page}
          previousPageNum={feedbacks.data.previous_page}
          totalPages={feedbacks.data.total_pages}
          setCurrentPage={setCurrentPage}
          setQueryFilter={setFilter}
        />
      }
    </React.Fragment>
  );
};

AssignmentDetailFeedbacks.propTypes = {
};


export default AssignmentDetailFeedbacks;
