import { connect } from 'react-redux';

import { passwordResetConfirm } from '../../actions/auth';
import { formApiAction } from '../../actions/api';
import ForgotPasswordConfirm from './forgot-password-confirm';

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  loggedIn: state.auth.loggedIn,
  user: state.auth.user,
});

const mapDispatchToProps = {
  resetConfirm: formApiAction(passwordResetConfirm),
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordConfirm);
