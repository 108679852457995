import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useQuery } from 'react-query';
import { getSubmission } from '../../../queries/admin-queries';
import Navigation from '../../../components/navigation/navigation';
import FilePreview from '../../../components/file-preview';
import Editor from '../../../components/editor/editor';

import styles from './submission-detail.scss';

const ShowFiles = (props) => {
  const { currentSubmission } = props;
  // Don't show during renders and if there are no files
  if (!currentSubmission || !currentSubmission.files || currentSubmission.files.length == 0) {
    return null;
  }

  return (
    <div className={styles.filesPreviewContainer}>
      <div className="p-4">
        <strong>Submission Files</strong>
      </div>
      {currentSubmission.files.map((file) => (
        <FilePreview
          file={file}
          key={file.file}
          readOnly
        />
      ))}
    </div>
  );
};

const SubmissionDetail = () => {
  const history = useHistory();
  const { id } = useParams();
  const {
    isLoading, isSuccess, data  } = useQuery(['admin-submission', id], () => getSubmission(id));

  let subtitle = '';
  if (isSuccess) {
    subtitle = `${data.author.first_name} ${data.author.last_name}`;
  }

  return (
    <div className={styles.container}>
      <Navigation
        history={history}
        title="Submission"
        subtitle={subtitle}
        hasBackButton
      />
      {isLoading && <h1>Loading...</h1>}
      {isSuccess && (
      <React.Fragment>
        <ShowFiles currentSubmission={data} />
        <Editor
          body={data.body}
          fullViewPort
          className={styles.editor}
          readOnly
          toolbarHidden
        />
      </React.Fragment>
      )}
    </div>
  );
};

SubmissionDetail.propTypes = {
};

SubmissionDetail.defaultProps = {
};

export default SubmissionDetail;
