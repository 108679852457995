import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Loading from '../../../components/loading/loading';
import { getAssignment, getAssignmentSubmissions, getAssignmentSubmissionCounts } from '../../../queries/admin-queries';

import { FilterTable } from './common';

const AssignmentDetailSubmissions = (props) => {
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState(null);
  const assignment = useQuery(['admin-assignment', id], () => getAssignment(id));
  const submissions = useQuery(['admin-assignment-submissions', id, currentPage, filter], () => getAssignmentSubmissions(id, currentPage, filter), { cacheTime: 10000, staleTime: 9000, keepPreviousData: true });
  const counts = useQuery(['admin-assignment-submissioncounts', id], () => getAssignmentSubmissionCounts(id), { cacheTime: 10000, staleTime: 9000 });


  // What to refresh when user clients refresh
  const refreshKeys = [
    "admin-assignment-submissioncounts",
    "admin-assignment-submissions",
  ];

  if (assignment.isLoading) {
    return (<Loading message="Loading assignment..." />);
  }
  if (submissions.isLoading) {
    return (<p>Loading submissions...</p>);
  }

  return (
    <React.Fragment>
      <table className="my-4">
        <thead>
          <tr>
            <th>Complete Submissions</th>
            <th>Incomplete Submissions</th>
            <th>Total Submissions</th>
          </tr>
        </thead>
        <tbody>
          {counts.isLoading && <tr><td colSpan="3">Loading...</td></tr>}
          {counts.isSuccess
            && (
              <tr>
                <td>{counts.data.complete_submissions}</td>
                <td>{counts.data.incomplete_submissions}</td>
                <td>{counts.data.total_submissions}</td>
              </tr>
            )}
        </tbody>
      </table>
      {submissions.isLoading && <Loading message="Loading submissions..." />}
      {submissions.isSuccess &&
        <FilterTable
          items={submissions.data.results}
          linkPrefix="submissions"
          assignmentID={id}
          refreshKeys={refreshKeys}
          currentPage={currentPage}
          nextPageNum={submissions.data.next_page}
          previousPageNum={submissions.data.previous_page}
          totalPages={submissions.data.total_pages}
          setCurrentPage={setCurrentPage}
          setQueryFilter={setFilter}
          isTeamAssignment={assignment.data.type == 'team'}
        />
      }
    </React.Fragment>
  );
};

export default AssignmentDetailSubmissions;
