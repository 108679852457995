import produce from 'immer';
import {
  getTodos, getTodoDetail
} from '../actions/todos';


const initialState = {
  todos: [],
  todoDetail: {},
  loading: false,
};

const todos = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case getTodos.REQUEST: {
      const stateDraft = draft;
      stateDraft.loading = true;
      break;
    }
    case getTodos.SUCCESS: {
      const stateDraft = draft;
      stateDraft.todos = action.payload;
      stateDraft.loading = false;
      break;
    }
    case getTodos.FAILURE: {
      const stateDraft = draft;
      stateDraft.loading = false;
      break;
    }
    case getTodoDetail.REQUEST: {
      const stateDraft = draft;
      const todo = {};
      stateDraft.todoDetail = todo;
      stateDraft.loading = true;
      break;
    }
    case getTodoDetail.SUCCESS: {
      const stateDraft = draft;
      const todo = action.payload;
      stateDraft.todoDetail = todo;
      stateDraft.loading = false;
      break;
    }
    case getTodoDetail.FAILURE: {
      const stateDraft = draft;
      const todo = {}
      stateDraft.todoDetail = todo;
      stateDraft.loading = true;
      break;
    }
    default:
      return state;
  }
});

export default todos;
