// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".participant-create__container___2brPU{\n  display:flex;\n  position:absolute;\n  top:0;\n  right:0;\n  bottom:0;\n  left:0;\n  z-index:999;\n  background-color:#fff\n}\n\n.participant-create__row___2ZGzD{\n  margin-bottom:1rem\n}", "",{"version":3,"sources":["participant-create.scss","<no source>"],"names":[],"mappings":"AAAA;EAAW,YAAY;EAAC,iBAAiB;EAAC,KAAK;EAAC,OAAO;EAAC,QAAQ;EAAC,MAAM;EAAC,WAAW;EAAC;AAAqB;;AAAC;ECA1G;ADA0H","file":"participant-create.scss","sourcesContent":[".container{display:flex;position:absolute;top:0;right:0;bottom:0;left:0;z-index:999;background-color:#fff}.row{@apply mb-4}",null]}]);
// Exports
exports.locals = {
	"container": "participant-create__container___2brPU",
	"row": "participant-create__row___2ZGzD"
};
module.exports = exports;
