import produce from 'immer';
import { arrayToObject } from '../common/utils';
import {
  TOGGLE_SUBMISSION_JOY_RIDE, TOGGLE_REVIEW_JOY_RIDE, TOGGLE_FEEDBACK_JOY_RIDE
} from '../actions/ui';


const initialState = {
  joyride_submission: true,
  joyride_review: true,
  joyride_feedback: true,
};

const ui = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case TOGGLE_SUBMISSION_JOY_RIDE: {
      return {
        ...state,
        joyride_submission: false,
      };
    }
    case TOGGLE_REVIEW_JOY_RIDE: {
      return {
        ...state,
        joyride_review: false,
      };
    }
    case TOGGLE_FEEDBACK_JOY_RIDE: {
      return {
        ...state,
        joyride_feedback: false,
      };
    }
    default:
      return state;
  }
});

export default ui;
