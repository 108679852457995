import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import RubricCreateForm from '../../../components/rubric-create-form/rubric-create-form';
import StepSection from '../../../components/step-section/step-section';
import LeaveWarning from '../../../components/leave-warning/leave-warning';

import styles from './assignment-edit.scss';

const Step4 = (props) => {
  const history = useHistory();
  const { handleSubmit, formState: { isDirty } } = useForm();
  const [showDirtyMessage, setShowDirtyMessage] = useState(false);
  const {
    setAssignmentDraft, assignmentDraft, goToNextStep, updateAssignment, skipStep4, setCanClose, help
  } = props;

  const [rubricState, setRubricState] = useState(
    assignmentDraft.submission_rubric,
  );

  // Track react-hook-form and editor dirty state
  useEffect(() => {
    let editorDirty = assignmentDraft.submission_rubric != rubricState;
    if (isDirty || editorDirty) {
      setShowDirtyMessage(true);
      setCanClose(false);
    } else {
      setShowDirtyMessage(false);
      setCanClose(true);
    }
  }, [isDirty, rubricState]);

  const onSubmit = () => {
    // set draft and move to step 4
    if (skipStep4) {
      // No step four, so save
      updateAssignment({
        ...assignmentDraft,
        submission_rubric: rubricState,

      });
      let url = `/assignments/${assignmentDraft.id}`;
      history.push(url);
    } else {
      setAssignmentDraft({
        ...assignmentDraft,
        submission_rubric: rubricState,
      });
      goToNextStep();
    }
  };

  return (
    <React.Fragment>
      <LeaveWarning when={showDirtyMessage} />
      <form
        className={styles.step}
        id="step-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <StepSection noPadding fullWidth shadow={false} bgGray transparentContainer>
          <RubricCreateForm
            rubricState={rubricState}
            setRubricState={setRubricState}
            name="step_4_rubric"
            help={help}
          />
        </StepSection>
      </form>
    </React.Fragment>
  );
};

Step4.propTypes = {
  setAssignmentDraft: PropTypes.func.isRequired,
  updateAssignment: PropTypes.func.isRequired,
  assignmentDraft: PropTypes.shape({}).isRequired,
  goToNextStep: PropTypes.func.isRequired,
};

Step4.defaultProps = {
};

export default Step4;
