/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './button.scss';

const Button = (props) => {
  const {
    children, onClick, badgeContent, hollow, type, small,
    disabled, inactive, submitting, className
  } = props;

  return (
    <button
      className={classNames(
        styles.button, {
          [styles.inactive]: inactive,
          [styles.hollow]: hollow,
          [styles.disabled]: disabled,
          [styles.submitting]: submitting,
          [styles.small]: small,
        },
        className,
      )}
      onClick={onClick}
      data-badge={badgeContent}
      disabled={disabled}
      type={type}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  submitting: PropTypes.bool,
  inactive: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  hollow: PropTypes.bool,
  badgeContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  type: PropTypes.string,
};

Button.defaultProps = {
  submitting: false,
  inactive: false,
  disabled: false,
  onClick: undefined,
  hollow: false,
  badgeContent: '',
  type: 'button',
  small: false,
};

export default Button;
