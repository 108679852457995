import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Navigation from '../../../components/navigation/navigation';
import ScrollArea from '../../../components/scroll-area/scroll-area';
import Input from '../../../components/input/input';
import Button from '../../../components/button/button';

import styles from './settings.scss';


const Settings = (props) => {
  const { user, changePassword, passwordUpdated, resetPasswordChanged } = props;
  const history = useHistory();

  const {
    register, handleSubmit, setError, isSubmitting, formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    const formData = data;
    formData.org = user.email;
    changePassword(formData, setError);
  };

  useEffect(() => {
    if (passwordUpdated) {
      toast('Password successfully changed', { containerId: 'RIGHT' });
      resetPasswordChanged();
    }
  }, [passwordUpdated]);


  return (
    <div className={styles.container}>
      <Navigation
        history={history}
        backURL="/dashboard"
        title="Settings"
        hasBackButton
      />
      <ScrollArea>
        <div className={styles.content}>
          <h3 className={styles.title}>Account Settings</h3>
          <div className={styles.row}>
            <div className={styles.left}>
              <p>Change Password</p>
            </div>
            <div className={styles.right}>
              <form onSubmit={handleSubmit(onSubmit)} >
                <div className={styles.input}>
                  <Input
                    label="New Password"
                    name="new_password1"
                    error={errors.new_password1}
                    placeholder="New Password"
                    type="password"
                    {...register('new_password1', { required: true })}
                  />
                </div>
                <div className={styles.input}>
                  <Input
                    label="Repeat Password"
                    name="new_password2"
                    error={errors.new_password2}
                    placeholder="Repeat New Password"
                    type="password"
                    {...register('new_password2', { required: true })}
                  />
                </div>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                >
                  Update Password
                </Button>
              </form>
            </div>
          </div>
        </div>
      </ScrollArea>
    </div>
  );
};

Settings.propTypes = {
  history: PropTypes.shape().isRequired,
};


export default Settings;
