import React, { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import classNames from 'classnames';
import { getAssignment, getAssignmentDefaults, getAssignmentAIWriting } from '../../../queries/admin-queries';
import { getMe, userQuerySettings } from '../../../queries/user';
import Loading from '../../../components/loading/loading';

const Demo = (props) => {
  return (
    <>
      <div className="my-6 bg-blue-100 border border-blue-300 p-6">
        The demo does not currently include any ChatGPT-generated responses.  However, you can view some
        example Writing Feedback responses below in the form the students would see it.
      </div>

      <div className="my-6">
        <h4 className="text-2xl font-bold border border-1 border-red-400 border-solid p-2 bg-red-200">
          ChatGPT Writing Feedback #1
        </h4>
        <p className="my-4">The Submission: Accounting and Financial Management. MBA midterm exam requiring a memo and recommendations regarding “material misstatements” in payroll expenses.</p>
        <div className="my-4 p-1 border border-1 border-red-400 bg-red-100 p-4">
          <p className="my-4">Overall, the writing in this submission is clear and concise. The writer effectively communicates the purpose of the analysis and provides a brief overview of the dataset and findings. However, there are a few areas where the writing could be improved to enhance logical coherence and strengthen the argumentation.</p>
          <p className="my-4">Firstly, the structure of the submission could benefit from clearer section headings or paragraphs to separate different stages of the analysis. This would help the reader to follow the logical progression of the investigation more easily.</p>
          <p className="my-4">Secondly, while the writer mentions that missing values were reviewed, there is no discussion of the impact of these missing values on the analysis. Including a brief evaluation of the potential impact and any steps taken to address it would provide a stronger foundation for the conclusions drawn.</p>
          <p className="my-4">Additionally, when discussing the findings of the analysis, the writer should provide more specific details and numerical evidence to support their claims. For example, instead of stating that "discrepancies in 2017" were found, the writer should explain the nature of these discrepancies and provide specific examples or calculations to illustrate the material misstatements.</p>
          <p className="my-4">In terms of business terminology, the submission utilizes relevant terms such as "material misstatements," "financial statements," and "revenue generated." However, to enhance the professional impact of the writing, the writer could consider using more precise and specific language. For example, instead of using the term "errors," they could reference "payroll discrepancies" or "duplicated payments."</p>
          <p className="my-4">To improve the professional impact of the writing, it is recommended to proofread for grammatical accuracy. While there are no major grammatical errors, minor issues such as missing articles and ambiguous pronoun references can be corrected to ensure a higher level of professionalism.</p>
          <p className="my-4">In conclusion, the submission effectively communicates the purpose and findings of the analysis. To refine the organization and enhance logical coherence, the writer should consider clearer section headings or paragraphs. Providing more specific details and numerical evidence would strengthen the argumentation. Using more precise and specific business terminology would improve the professional impact of the writing. Lastly, proofreading for grammatical accuracy is recommended to ensure a polished final submission.</p>
        </div>
      </div>

      <div className="my-6">
        <h4 className="text-2xl font-bold border border-1 border-red-400 border-solid p-2 bg-red-200">
          ChatGPT Writing Feedback #2
        </h4>
        <p className="my-4">Submission: An Undergraduate course Business & Society. The draft paper focuses on the detrimental effects of adding sugar to food.  The recommendation: McDonalds purchase Sweetgreen's</p>
        <div className="my-4 p-1 border border-1 border-red-400 bg-red-100 p-4">
          <p className="my-4">Overall, the submission addresses the topic of health-conscious fast food and its potential impact on reducing added sugar consumption in low-income communities. The writing is clear and concise, making effective use of evidence and statistics to support the arguments made. The structure of the submission is logical, with clear subheadings and transitions between ideas. The use of business terminology is appropriate for a business context, enhancing the professional impact of the writing.</p>
          <p className="my-4">The submission could further enhance logical coherence by explicitly stating the link between the risks of added sugar consumption and low-income communities. While it is implied that these communities are more likely to consume fast food with high added sugar content, it would strengthen the argument to explicitly connect the two.</p>
          <p className="my-4">In terms of bolstering argumentation, the submission could benefit from addressing and refuting potential counterarguments. This would strengthen the overall argument by acknowledging and debunking potential challenges or opposing viewpoints.</p>
          <p className="my-4">The professional impact of the writing could be improved by maintaining consistent formatting and citation style throughout the submission. Ensuring that all sources are properly cited and formatting references in a consistent manner would enhance the overall professionalism of the work.</p>
          <p className="my-4">In conclusion, the submission effectively communicates the intended messages and makes a strong case for the potential impact of McDonald's purchasing Sweetgreen on reducing added sugar consumption in low-income communities. By refining the organization, enhancing logical coherence, bolstering argumentation, and improving the professional impact of the writing through consistent formatting and citation style, the submission could further strengthen its analysis in a business context.</p>
        </div>
      </div>

      <div className="my-6">
        <h4 className="text-2xl font-bold border border-1 border-red-400 border-solid p-2 bg-red-200">
          ChatGPT Critical Thinking Feedback #3
        </h4>
        <p className="my-4">The Submission: An undergraduate course: Human Resource management. The student's draft paper is on The Impact of Abusive Supervision on Employees' Emotions</p>

        <div className="my-4 p-1 border border-1 border-red-400 bg-red-100 p-4">
          <p className="my-4">The submission provides an overview of the impact of abusive supervision on employees' emotions, particularly in a business context. The writing is generally clear and concise, using simple language to explain complex concepts. However, there are some areas where the writing could be improved to enhance logical coherence and strengthen the arguments.</p>
          <p className="my-4">The structure of the submission is generally logical, starting with an introduction that highlights the importance of the topic and then presenting the main points in the body paragraphs. However, there is room for improvement in organizing the information more cohesively. For example, the author could consider presenting the negative effects of abusive supervision on employees' emotions and the organization's operations in separate paragraphs for clarity.</p>
          <p className="my-4">The arguments presented in the submission are mostly well-supported, with references to studies and research findings. However, it would be beneficial to provide specific citations for each study mentioned to enhance the credibility of the arguments. Additionally, the submission could benefit from providing more context and explanation for the referenced studies to help the reader understand their relevance.</p>
          <p className="my-4">In terms of business terminology, the submission uses simple and accessible language, which is appropriate for a business audience. However, there is a missed opportunity to incorporate more specific terminology related to human resources and organizational behavior. The use of specialized vocabulary would further demonstrate the author's expertise and strengthen the connection to the business context.</p>
          <p className="my-4">The overall effectiveness of the writing in communicating the intended messages is good, but there are some areas for improvement. The submission could benefit from clearer transitions between ideas and paragraphs to improve the flow of information. Additionally, the conclusion could be strengthened by summarizing the main points and restating the importance of addressing abusive supervision in the workplace.</p>
          <p className="my-4">To refine the organization and enhance logical coherence, the author could consider reorganizing the information into separate paragraphs based on topic or subtopic. This would help readers to follow the progression of ideas more easily.</p>
          <p className="my-4">To bolster argumentation, the author should provide specific citations for each referenced study and provide more context and explanation for the research findings to demonstrate their relevance to the topic.</p>
          <p className="my-4">Overall, the submission provides a good foundation for discussing the impact of abusive supervision on employees' emotions in a business context. With some refinements to the organization, increased use of specific citations and business terminology, and clearer transitions between ideas, the writing could be further improved to effectively communicate the intended messages.</p>
        </div>
      </div>
    </>
  )
}

const Writing = (props) => {
  const { writing } = props
  const [expanded, setExpanded] = useState(false);

  const tdStyle = {
    "width": "50%",
    "maxWidth": "50%",

  }
  const smallStyle = {
    "maxHeight": "100px",
    "textOverflow": "ellipsis",
    "overflow": "hidden",
    "overWrap": "break-word",
    "whiteSpace": "normal",
    "WebkitLineClamp": "6",
    "WebkitBoxOrient": "vertical",
    "display": "-webkit-box",
  }
  const largeStyle = {
    "overflow": "hidden",
    "whiteSpace": "pre-line",
  }

  const toggle = () => {
    setExpanded(!expanded);
  }

  return (
    <td onClick={() => toggle()} style={tdStyle}>
      <span style={expanded ? largeStyle : smallStyle}>{writing}</span>
      {!expanded && <span>...</span>}
    </td>
  )
}

const AssignmentReportsAIWriting = (props) => {
  const { id } = useParams();
  const user = useQuery('me', getMe, userQuerySettings);
  const assignment = useQuery(['admin-assignment', id], () => getAssignment(id), getAssignmentDefaults);
  const writing = useQuery(['admin-assignment-ai-writing', id], () => getAssignmentAIWriting(id), { cacheTime: 60000, staleTime: 10000, keepPreviousData: true });
  const buttonClasses = classNames('rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 w-full cursor-pointer my-4');

  if (assignment.isLoading || writing.isLoading || user.isLoading) {
    return <Loading />;
  }

  if (user.data.is_demo) {
    return (<Demo />)
  }

  if (!assignment.data.include_ai_writing) {
    return (
      <React.Fragment>
        <h1 className="text-2xl font-bold my-6">ChatGPT Writing</h1>
        <p>This feedback type was not turned on for this assignment.</p>
      </React.Fragment>
    )
  }

  if (assignment.data.state == 'pending' || assignment.data.state == 'submission') {
    return (
      <React.Fragment>
        <h1 className="text-2xl font-bold mb-4">ChatGPT Writing</h1>
        <p className="p-4 mt-8 border bg-blue-100">
          This report is only available in later stages of an Assignment.
        </p>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <h1 className="text-2xl font-bold my-6">ChatGPT Writing</h1>
      <table className="table-fixed">
        <thead>
          <tr>
            <th className="w-1/4 align-top">Participant</th>
            <th className="w-1/2">Writing</th>
            <th className="w-1/4">Submission</th>
          </tr>
        </thead>
        <tbody>
          {writing.data.map((w) => {
            return (
              <tr key={w.id} className="hover:bg-blue-100">
                <td className="w-1/4 align-top">
                  <span className="font-bold">{w.first_name} {w.last_name}</span><br />
                  <span className="text-gray-500">{w.email}</span>
                </td>
                <Writing key={w.id} writing={w.writing} />
                <td className="w-1/4 align-middle">
                  <NavLink
                    exact
                    className={buttonClasses}
                    to={`/submissions/${w.submission_id}`}
                  >
                    View Submission
                  </NavLink>
                </td>
              </tr>
            )
          })}
          {writing.data.length === 0 && (
            <tr>
              <td colSpan="5">No Writing data found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </React.Fragment>

  );
};


export default AssignmentReportsAIWriting;
