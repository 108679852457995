import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { sectionsSelector } from '../../../selectors/sections';
import UserListItem from '../../../components/user-list-item/user-list-item';
import { getMe, userQuerySettings } from '../../../queries/user';

import styles from './section-detail.scss';

const SectionDetailParticipants = (props) => {
  const {
    sectionMembers, loading
  } = props;
  const { id } = useParams();
  const user = useQuery('me', getMe, userQuerySettings);

  if (user.isLoading) {
    return (<div>Loading...</div>);
  }

  return (
    <React.Fragment>
      {!user.data.is_lti &&
        <div className={styles.actions}>
          <div className="container">
            <Link
              className={styles.smallButton}
              to={{ pathname: `/sections/${id}/participant/create`, state: { modal: true } }}
            >
              Add participant
            </Link>
            <Link
              className={styles.smallButton}
              to={{ pathname: `/sections/${id}/participants/create`, state: { modal: true } }}
            >
              Import Participants via CSV
            </Link>
          </div>
        </div>
      }
      <section className={styles.section}>
        <div className="container">
          {loading === true && (
            <h3>Loading...</h3>
          )}
          {sectionMembers.map((member, index) => (
            <UserListItem key={index} user={member} />
          ))}
        </div>
      </section>
    </React.Fragment>
  );
};

SectionDetailParticipants.propTypes = {
  sectionMembers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.sections.loading,
  sectionMembers: sectionsSelector(state.sections.sectionMembers),
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionDetailParticipants);
