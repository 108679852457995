import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../button/button';

import styles from './assignment-section-title.scss';

function AssignmentSectionTitle(props) {
  const {
    title, backgroundGray, showActions, handleSave, backgroundGreen
  } = props;

  return (
    <div className={classNames(
      styles.container, {
        [styles.backgroundGray]: backgroundGray,
        [styles.backgroundGreen]: backgroundGreen,
      }
    )}
    >
      {title}
      {showActions && (
        <Button
          type="submit"
          onClick={handleSave}
        >
          Save
        </Button>
      )}
    </div>
  );
}

AssignmentSectionTitle.propTypes = {
  title: PropTypes.string,
  backgroundGray: PropTypes.bool,
  showActions: PropTypes.bool,
  handleSave: PropTypes.func,
  backgroundGreen: PropTypes.bool,
};

AssignmentSectionTitle.defaultProps = {
  title: '',
  backgroundGray: false,
  showActions: false,
  handleSave: () => { },
  backgroundGreen: false,
};

export default AssignmentSectionTitle;
