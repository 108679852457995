import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';

import { assignmentsSelector } from '../../../selectors/assignments';
import AssignmentListItem from '../../../components/assignment-list-item/assignment-list-item';
import List from '../../../components/list/list';

import styles from './section-detail.scss';

const SectionDetailOverview = (props) => {
  const {
    assignments, history
  } = props;

  const { id } = useParams();

  return (
    <React.Fragment>
      <section className={styles.section}>
        <List>
          {assignments.length === 0 && (
            <h3>This Section currently has no assignments</h3>
          )}
          {assignments.map((assignment) => (
            <AssignmentListItem
              history={history}
              url="assignments"
              id={assignment.id}
              title={assignment.title}
              dueDate={assignment.due_date}
              imageURL={assignment.thumbnail}
              state={assignment.state}
              paused={assignment.paused}
              key={assignment.id}
              type={assignment.type}
            />
          ))}
        </List>
      </section>
    </React.Fragment>
  );
};

SectionDetailOverview.propTypes = {
  assignments: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  assignments: assignmentsSelector(state.assignments.assignments),
  course: state.courses.course,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionDetailOverview);
