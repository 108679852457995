import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import CloseIcon from '../../../assets/icons/close.svg';

import styles from './step-navigation.scss';


const StepNavigationItem = (props) => {
  const {
    currentStep, index, goToStep, title
  } = props;

  return (
    <div
      className={classNames(
        styles.stepButton, {
        [styles.stepButtonActive]: currentStep === index + 1,
      }
      )}
      role="button"
      onClick={() => { goToStep(index + 1) }}
    >
      {title}
    </div>
  );
};

StepNavigationItem.propTypes = {
  currentStep: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  goToStep: PropTypes.func,
  title: PropTypes.string

};

StepNavigationItem.defaultProps = {
  goToStep: () => { },
  title: '',
};


const StepNavigation = (props) => {

  const {
    steps, currentStep, goToStep, nextType, forForm, nextTitle, finalTitle,
    customNextButton, locked, children, skipStep5, canClose,
  } = props;

  const history = useHistory();

  const handleBack = () => {
    if (canClose) {
      history.goBack();
    } else {
      if (window.confirm("You may have unsaved changes. Do you want to leave anyway?")) {
        history.goBack();
      }
    }
  };

  let finalSteps = [];

  if (skipStep5) {
    finalSteps = steps.slice(0, -1);
  } else {
    finalSteps = steps;
  }

  let buttonTitle = finalSteps.length === currentStep ? finalTitle : nextTitle;

  if (locked) {
    buttonTitle = steps[currentStep - 1].lockedTitle
  }
  let nextButton = null;
  if (!customNextButton) {
    nextButton = (
      <button
        className={classNames(
          styles.nextButton, {
          [styles.locked]: locked,
        }
        )}
        form={forForm}
        type={nextType}
      >
        {buttonTitle}
      </button>
    );
  }

  return (
    <nav className={styles.container}>
      <div className={styles.left}>
        <CloseIcon
          className={styles.iconClose}
          onClick={() => handleBack()}
        />
      </div>
      <div className={styles.center}>
        {finalSteps.map((item, index) => (
          <StepNavigationItem
            currentStep={currentStep}
            goToStep={goToStep}
            index={index}
            title={item.title}
            key={index}
          />
        ))}
        {children}
        {nextButton}
      </div>
    </nav>
  );
};

StepNavigation.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    })
  ),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  currentStep: PropTypes.number.isRequired,
  goToStep: PropTypes.func,
  customNextButton: PropTypes.bool,
  nextType: PropTypes.string,
  nextTitle: PropTypes.string,
  finalTitle: PropTypes.string,
  forForm: PropTypes.string,
};

StepNavigation.defaultProps = {
  steps: [],
  children: [],
  goToStep: () => { },
  customNextButton: false,
  nextType: 'submit',
  nextTitle: 'Next',
  finalTitle: 'Save',
  forForm: '',
};

export default StepNavigation;
