import produce from 'immer';
import { arrayToObject } from '../common/utils';
import {
  getSubmissions, getSubmission, RESET_SUBMISSION_CREATED,
  updateSubmissionBody, createOrUpdateSubmission, getAssignmentSubmission,
  deleteSubmissionFile
} from '../actions/student-submissions';


const initialState = {
  submissions: {},
  submissionDetail: {},
  loading: false,
  currentSubmission: {
    body: '',
    plainText: ''
  },
  currentSubmissionFiles: {},
  submissionCreated: false,
};

const submissions = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case getSubmissions.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case getSubmissions.SUCCESS: {
      return {
        ...state,
        loading: false,
        submissions: arrayToObject(action.payload.results),
      };
    }
    case getSubmissions.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case getSubmission.REQUEST: {

      return {
        ...state,
        loading: true,
      };
    }
    case getSubmission.SUCCESS: {
      const stateDraft = draft;
      const submission = action.payload;
      stateDraft.currentSubmission = submission;
      if (submission.files.length > 0) {
        submission.files.forEach((file) => {
          stateDraft.currentSubmissionFiles[file.id] = file
        });
      }
      stateDraft.loading = false;
      break;
    }
    case getSubmission.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case RESET_SUBMISSION_CREATED: {
      return {
        ...state,
        loading: false,
        submissionDetail: {},
        submissionCreated: false,
      };
    }
    case updateSubmissionBody.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case updateSubmissionBody.SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case updateSubmissionBody.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case createOrUpdateSubmission.REQUEST: {
      return {
        ...state,
        loading: true,
        submissionCreated: false,
      };
    }
    case createOrUpdateSubmission.SUCCESS: {
      const stateDraft = draft;
      const submission = action.payload;
      stateDraft.currentSubmission = submission;
      if (submission.files.length > 0) {
        submission.files.forEach((file) => {
          stateDraft.currentSubmissionFiles[file.id] = file
        });
      }
      stateDraft.submissionCreated = true;
      stateDraft.loading = false;
      break;
    }
    case createOrUpdateSubmission.FAILURE: {
      return {
        ...state,
        loading: false,
        submissionCreated: false,
      };
    }
    case getAssignmentSubmission.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case getAssignmentSubmission.SUCCESS: {
      const stateDraft = draft;
      const submission = action.payload;
      stateDraft.currentSubmission = submission;
      if (submission.files.length > 0) {
        submission.files.forEach((file) => {
          stateDraft.currentSubmissionFiles[file.id] = file
        });
      }
      stateDraft.loading = false;
      break;
    }
    case getAssignmentSubmission.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case deleteSubmissionFile.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case deleteSubmissionFile.SUCCESS: {
      const stateDraft = draft;
      const submissionFileID = parseInt(action.meta.submissionFileID, 10);
      delete stateDraft.currentSubmissionFiles[submissionFileID];
      stateDraft.loading = false;
      break;
    }
    case deleteSubmissionFile.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
});

export default submissions;
