/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from 'react';
import PropTypes from 'prop-types';

import TrashIcon from '../../../assets/icons/bin-1.svg';

import XLSIcon from '../../../assets/icons/office-file-xls-1.svg';
import PDFIcon from '../../../assets/icons/office-file-pdf-1.svg';
import DOCIcon from '../../../assets/icons/office-file-doc-1.svg';
import JPGIcon from '../../../assets/icons/image-file-jpg.svg';
import PNGIcon from '../../../assets/icons/image-file-png.svg';
import BMPIcon from '../../../assets/icons/image-file-bmp.svg';
import GIFIcon from '../../../assets/icons/image-file-gif.svg';
import SVGIcon from '../../../assets/icons/image-file-svg.svg';
import UnknownIcon from '../../../assets/icons/common-file-question.svg';

import styles from './file-preview.scss';

const FilePreview = (props) => {
  const { file, handleClick, readOnly } = props;

  let icon = <UnknownIcon className={styles.icon} />;
  let suffix = '';

  if (file.name) {
    suffix = file.name.split('.').pop().toLowerCase();
    if (suffix === 'pdf') {
      icon = <PDFIcon className={styles.icon} />;
    }
    if (suffix === 'doc' || suffix === 'docx') {
      icon = <DOCIcon className={styles.icon} />;
    }
    if (suffix === 'xls' || suffix === 'xlsx') {
      icon = <XLSIcon className={styles.icon} />;
    }
    if (suffix === 'jpg' || suffix === 'jpeg') {
      icon = <JPGIcon className={styles.icon} />;
    }
    if (suffix === 'png') {
      icon = <PNGIcon className={styles.icon} />;
    }
    if (suffix === 'bmp') {
      icon = <BMPIcon className={styles.icon} />;
    }
    if (suffix === 'gif') {
      icon = <GIFIcon className={styles.icon} />;
    }
    if (suffix === 'svg') {
      icon = <SVGIcon className={styles.icon} />;
    }
  }

  return (
    <a className={styles.wrapper} key={file.id} href={file.file} target="blank">
      <div className={styles.container}>
        {!readOnly && (
          <div
            className={styles.fileDeleteAction}
            onClick={(e) => handleClick(e, file.id)}
          >
            <TrashIcon />
          </div>
        )}
        {icon}
        <span className={styles.label}>{file.name}</span>
        {/* <img
        alt={file.name}
        className={styles.icon}
        src={file.file}
      /> */}
      </div>
    </a>
  );
};

FilePreview.propTypes = {
  readOnly: PropTypes.bool,
  handleClick: PropTypes.func,
  file: PropTypes.shape({
    file: PropTypes.string,
    name: PropTypes.string,
  }),
};

FilePreview.defaultProps = {
  readOnly: false,
  file: {
    file: '',
    name: '',
  },
};

export default FilePreview;
