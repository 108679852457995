import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ScrollArea from '../../../components/scroll-area/scroll-area';
import Checkmark from '../../../components/checkmark/checkmark';
import X from '../../../components/x/x';
import Avatar from '../../../components/avatar/avatar';
import { submissionsSelector } from '../../../selectors/submissions';
import { getUserDetail } from '../../../queries/admin-queries';

import styles from './participant-detail.scss';

const Submission = (props) => {
  const { submission, index } = props;
  const history = useHistory();

  const handleClick = () => {
    history.push(`/submissions/${submission.id}`);
  };

  return (
    <tr key={index} onClick={handleClick} className={styles.tableHover}>
      <td>
        <div className={styles.author}>
          <div className={styles.authorAvatar}>
            <Avatar
              name={submission.author.display_name}
            />
          </div>
          <div className={styles.authorInfo}>
            <div className={styles.authorName}>{submission.author.display_name}</div>
            <div className={styles.authorEmail}>{submission.author.email}</div>
          </div>
        </div>
      </td>
      <td>{submission.is_complete ? <Checkmark /> : <X />}</td>
      <td>
        <Link
          to={`/submissions/${submission.id}`}
        >
          View
        </Link>
      </td>
    </tr>
  );
}

const ParticipantDetailSubmissions = (props) => {
  const assignmentDetail = useSelector((state) => state.assignments.assignmentDetail);
  const submissions = submissionsSelector(useSelector((state) => state.submissions.submissions));
  const reviews = useSelector((state) => state.reviews.reviews);
  const reviewFeedbacks = useSelector((state) => state.reviewFeedbacks.reviewFeedbacks);

  if (submissions.length === 0) {
    return (
      <p>There are currently no submissions</p>
    );
  }

  return (
    <table>
      <thead>
        <tr>
          <th>
            By
          </th>
          <th>
            Completed
          </th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {submissions.map((sub, index) => (
          <Submission key={sub.id} submission={sub} index={index} />
        ))}
      </tbody>
    </table>
  );
};

ParticipantDetailSubmissions.propTypes = {
};


export default ParticipantDetailSubmissions;
