import React from 'react';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Switch from 'react-switch';

import { formApiAction } from '../../actions/api';
import { updateSection } from '../../actions/sections';

import styles from './section-list-item.scss';


const SectionListItem = (props) => {
  const {
    title, subtitle, id, isActive, updateSection, studentCount,
  } = props;

  const history = useHistory();

  const handleClick = () => {
    history.push(`/sections/${id}`);
  };

  const handleSwitch = () => {
    updateSection({ is_active: !isActive }, () => { }, id);
  };

  return (
    <div className={styles.container}>

      <div className={styles.item}>
        <div
          className={styles.content}
          onClick={handleClick}
        >
          <div className={styles.title}>{title}</div>
          <div className={styles.subtitle}>{subtitle}</div>
        </div>

        <div className={styles.stats}>
          <div className={styles.stat}>
            <span className={styles.statNumber}>
              {studentCount}
            </span>
            <span className={styles.statTitle}>
              Participants
            </span>
          </div>
          {/*
          <div className={styles.stat}>
            <span className={styles.statNumber}>
              57
              <span className={styles.statPercentage}>%</span>
            </span>
            <span className={styles.statTitle}>
              Completed
            </span>
          </div>*/}
        </div>

        <div className={styles.actions}>
          {/*
          <Link className={styles.action} to={{ pathname: `/courses/${id}/edit`, state: { modal: true } }} >
            Add
            <br />
            Assignment
          </Link>
          <Link className={styles.action} to={{ pathname: `/courses/${id}/edit`, state: { modal: true } }} >
            Manage
            <br />
            Participants
          </Link>
        */}
        </div>
      </div>
    </div>
  );
};

SectionListItem.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  id: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  studentCount: PropTypes.number,
};

SectionListItem.defaultProps = {
  title: '',
  subtitle: '',
  studentCount: 0,
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const formUpdateSection = formApiAction(updateSection);
  return {
    updateSection: (formData, setError, id) => dispatch(formUpdateSection(formData, setError, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionListItem);
