import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Provider, ErrorBoundary } from '@rollbar/react';
import getRollbarConfig from './common/rollbarSettings';
import { useQuery } from 'react-query';
import { getMe, userQuerySettings } from './queries/user';
import { getUser } from './actions/auth';
import Routes from './routes/root';


const config = getRollbarConfig();


const App = (props) => {
  const { loggedIn, loadUser } = props;
  useEffect(() => {
    loadUser();
  }, [loggedIn]);

  // Loaded here just to warm the cache
  const info = useQuery('me', getMe, userQuerySettings);

  return (
    <BrowserRouter>
      <Provider config={config}>
        <ErrorBoundary>
          <Route component={Routes} />
        </ErrorBoundary>
      </Provider>
    </BrowserRouter>
  );
}

App.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  loadUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loggedIn: state.auth.loggedIn,
});

const mapDispatchToProps = {
  loadUser: getUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
