import produce from 'immer';
import { arrayToObject } from '../common/utils';
import {
  createOrUpdateReview, RESET_REVIEW_CREATED, getAssignmentReviews,
  getUserReviews, getReview,
} from '../actions/reviews';


const initialState = {
  reviews: {},
  reviewDetail: {},
  loading: false,
  reviewCreated: false,
};

const reviews = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case RESET_REVIEW_CREATED: {
      return {
        ...state,
        loading: false,
        reviewDetail: {},
        reviewCreated: false,
      };
    }
    case createOrUpdateReview.REQUEST: {
      return {
        ...state,
        loading: true,
        reviewCreated: false,
      };
    }
    case createOrUpdateReview.SUCCESS: {
      return {
        ...state,
        loading: false,
        reviewCreated: true,
      };
    }
    case createOrUpdateReview.FAILURE: {
      return {
        ...state,
        loading: false,
        reviewCreated: false,
      };
    }
    case getAssignmentReviews.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case getAssignmentReviews.SUCCESS: {
      return {
        ...state,
        loading: false,
        reviews: arrayToObject(action.payload.results),
      };
    }
    case getAssignmentReviews.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case getUserReviews.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case getUserReviews.SUCCESS: {
      return {
        ...state,
        loading: false,
        reviews: arrayToObject(action.payload.results),
      };
    }
    case getUserReviews.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case getReview.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case getReview.SUCCESS: {
      return {
        ...state,
        loading: false,
        reviewDetail: action.payload,
      };
    }
    case getReview.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
});

export default reviews;
