import { connect } from 'react-redux';

import {
  getSectionDetail, createSection, resetSectionCreated, updateSection
} from '../../../actions/sections';
import { formApiAction } from '../../../actions/api';
import SectionCreate from './section-create';

const mapStateToProps = (state) => ({
  user: state.auth.user,
  sections: state.sections.sections,
  sectionDetail: state.sections.sectionDetail,
  sectionCreated: state.sections.sectionCreated,
  courseDetail: state.courses.courseDetail,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const formCreateSection = formApiAction(createSection);
  const formUpdateSection = formApiAction(updateSection);
  const { match: { params: { id } } } = ownProps;
  return {
    getSectionDetail: () => dispatch(getSectionDetail(id)),
    createSection: (formData, setError) => dispatch(
      formCreateSection(formData, setError)
    ),
    updateSection: (formData, setError) => dispatch(
      formUpdateSection(formData, setError, id)
    ),
    resetSectionCreated: () => dispatch(resetSectionCreated()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionCreate);
