import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';

import Navigation from '../../../components/navigation/navigation';
import RubricForm from '../../../components/rubric-form/rubric-form';
import ScrollArea from '../../../components/scroll-area/scroll-area';

import styles from './feedback-detail.scss';

const FeedbackDetail = (props) => {
  const {
    getFeedback, feedbackDetail
  } = props;

  const history = useHistory();
  useEffect(() => {
    getFeedback();
  }, []);

  if (isEmpty(feedbackDetail)) {
    return (
      <div>Loading</div>
    );
  }
  return (
    <div className={styles.container}>
      <Navigation
        history={history}
        title="Feedback"
        subtitle={feedbackDetail.id}
        hasBackButton
      />
      <ScrollArea>
        <div className={styles.content}>
          <RubricForm
            readOnly
            data={feedbackDetail.rubric}
          />
        </div>
      </ScrollArea>
    </div>
  );
};

FeedbackDetail.propTypes = {
  getFeedback: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  feedbackDetail: PropTypes.shape().isRequired,
};

FeedbackDetail.defaultProps = {
};

export default FeedbackDetail;
