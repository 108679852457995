// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".notifications__container___3dYA2{\n  max-width:24rem;\n  border-radius:0.25rem;\n  overflow:hidden;\n  box-shadow:0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);\n  padding-left:1.5rem;\n  padding-right:1.5rem;\n  padding-top:1rem;\n  padding-bottom:1rem\n}\n\n.notifications__body___1MBdq{\n  --text-opacity:1;\n  color:#4a5568;\n  color:rgba(74, 85, 104, var(--text-opacity))\n}\n\n.notifications__progressBar___3kJCd{\n  --bg-opacity:1;\n  background-color:#4299e1;\n  background-color:rgba(66, 153, 225, var(--bg-opacity));\n  height:2px\n}\n\n.notifications__smallContainer___2omt1{\n  width:120px;\n  bottom:0px\n}\n\n.notifications__smallToast___26BAg{\n  min-height:auto;\n  overflow:hidden;\n  box-shadow:0 1px 15px rgba(27,31,35,.15)\n}\n\n.notifications__smallBody___1udE1{\n  text-align:center\n}", "",{"version":3,"sources":["notifications.scss","<no source>"],"names":[],"mappings":"AAAA;ECAA,gBAAA;EAAA,sBAAA;EAAA,gBAAA;EAAA,mFAAA;EAAA,oBAAA;EAAA,qBAAA;EAAA,iBAAA;EAAA;ADAsE;;AAAC;ECAvE,iBAAA;EAAA,cAAA;EAAA;ADAiG;;AAAC;ECAlG,eAAA;EAAA,yBAAA;EAAA,uDAAA;EDAkI;AAAU;;AAAC;EAAgB,WAAW;EAAC;AAAU;;AAAC;EAAY,eAAe;EAAC,eAAe;EAAC;AAAwC;;AAAC;EAAW;AAAiB","file":"notifications.scss","sourcesContent":[".container{@apply max-w-sm rounded overflow-hidden shadow-lg px-6 py-4}.body{@apply text-gray-700}.progressBar{@apply bg-blue-500;height:2px}.smallContainer{width:120px;bottom:0px}.smallToast{min-height:auto;overflow:hidden;box-shadow:0 1px 15px rgba(27,31,35,.15)}.smallBody{text-align:center}",null]}]);
// Exports
exports.locals = {
	"container": "notifications__container___3dYA2",
	"body": "notifications__body___1MBdq",
	"progressBar": "notifications__progressBar___3kJCd",
	"smallContainer": "notifications__smallContainer___2omt1",
	"smallToast": "notifications__smallToast___26BAg",
	"smallBody": "notifications__smallBody___1udE1"
};
module.exports = exports;
