import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getAssignmentParticipation } from '../../../queries/admin-queries';
import Loading from '../../../components/loading/loading';
import Checkmark from '../../../components/checkmark/checkmark';
import X from '../../../components/x/x';


const AssignmentReportsParticipation = (props) => {
  const { id } = useParams();
  const participation = useQuery(['admin-assignment-participation', id], () => getAssignmentParticipation(id), { cacheTime: 60000, staleTime: 10000, keepPreviousData: true });

  if (participation.isLoading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <h1 className="text-2xl font-bold my-6">All Stage Participation</h1>
      <table>
        <thead>
          <tr>
            <th>Participant</th>
            <th>Stage 1: Submission</th>
            <th>Stage 1: Likely to Participate</th>
            <th>Stage 2: Reviews</th>
            <th>Stage 3: Score Reviews</th>
          </tr>
        </thead>
        <tbody>
          {participation.data.map((p) => {
            let committed = null;
            if (p.committed == true) {
              committed = <Checkmark />;
            } else if (p.committed == false) {
              committed = <X />;
            } else {
              committed = 'No Answer';
            }
            return (
              <tr key={p.id}>
                <td>{p.first_name} {p.last_name}</td>
                <td>{p.submission ? (<Checkmark />) : (<X />)}</td>
                <td>{committed}</td>
                <td>{p.review_participation.toUpperCase()}</td>
                <td>{p.feedback_participation.toUpperCase()}</td>
              </tr>
            )
          })}
          {participation.data.length === 0 && (
            <tr>
              <td colSpan="5">No participation data found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </React.Fragment>

  );
};


export default AssignmentReportsParticipation;
