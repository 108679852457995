import React from 'react';

import { useQuery } from 'react-query';

import Navigation from '../../components/navigation/navigation';
import ScrollArea from '../../components/scroll-area/scroll-area';
import PlatformName from '../../components/platform';
import Button from '../../components/button/button';
import { getMe, userQuerySettings } from '../../queries/user';

import styles from './support.scss';


const HBPSupport = (props) => {
  const { user } = props;

  return (
    <div>
      <p className="my-4">
        For technical help please email <a className="text-blue-500 hover:text-blue-700 underline" href="mailto:techhelp@hbsp.harvard.edu">techhelp@hbsp.harvard.edu</a>.
      </p>
      <p className="my-4">
        Instructors and students can also “Live Chat” with one of our Customer Service agents by clicking the gray “Help” button at the bottom
        left on the Higher Ed Website. <a className="text-blue-500 underline" href="https://hbsp.harvard.edu/contact-us/">https://hbsp.harvard.edu/contact-us/</a>
      </p>
    </div>
  );
}

const NormalSupport = (props) => {
  return (
    <div>
      <p className="my-4">
        For technical help please email <a className="text-blue-500 hover:text-blue-700 underline" href="mailto:Help@PeerLearningVenture.com">Help@PeerLearningVenture.com</a>.
      </p>
    </div>
  );
}

const Support = (props) => {
  const user = useQuery('me', getMe, userQuerySettings);

  if (user.isLoading) {
    return <div></div>;
  }

  let helpComponent = <NormalSupport user={user} />;

  if (user.data.is_hbp) {
    helpComponent = <HBPSupport user={user} />;
  }

  return (
    <div className={styles.container}>
      <Navigation title="Support" />
      <ScrollArea>
        <div className={styles.content}>
          <h1 className="text-2xl font-bold mb-4"><PlatformName /> Support</h1>
          {helpComponent}
        </div>
      </ScrollArea>
    </div>
  );
};

export default Support;
