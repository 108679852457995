import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { sectionsSelector } from '../../../selectors/sections';
import {
  deleteAssignment, transitionToSubmission, transitionToReview, transitionToReviewFeedback,
  transitionToCompleteAndSkipFeedback, transitionToComplete
} from '../../../actions/assignments';

import SectionListItem from '../../../components/section-list-item/section-list-item';
import { getMe, userQuerySettings } from '../../../queries/user';

import styles from './course-detail.scss';


const CourseDetailOverview = (props) => {
  const {
    sections
  } = props;
  const { id } = useParams();
  const user = useQuery('me', getMe, userQuerySettings);

  if (user.isLoading) {
    return (<div>Loading...</div>);
  }

  return (
    <React.Fragment>
      <div className={styles.actions}>
        <div className="container">
          {!user.data.is_lti &&
            <Link
              className={styles.smallButton}
              to={{ pathname: `/sections/create/${id}`, state: { modal: true } }}
            >
              Add a new Section
            </Link>
          }
          {/* Temporarily turn off this feature
          <Link
            className={styles.smallButton}
            to={{ pathname: `/assignments/bulk-create/${id}`, state: { modal: true } }}
          >
            Create a new Assignment for all Sections
          </Link>
          */}
        </div>
      </div>
      <section className={styles.section}>
        <div className="container">
          {sections.length === 0 && (
            <h3>This Course currently has no sections</h3>
          )}
          {sections.map((item, index) => (
            <SectionListItem
              isActive={item.is_active}
              id={item.id}
              title={item.name}
              subtitle={item.description}
              studentCount={item.student_count}
              key={item.id}
            />
          ))}
        </div>
      </section>
    </React.Fragment>
  );
};

CourseDetailOverview.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  sections: sectionsSelector(state.sections.sections),
  course: state.courses.course,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetailOverview);
