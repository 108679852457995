import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import RubricCreateForm from '../../../components/rubric-create-form/rubric-create-form';
import StepSection from '../../../components/step-section/step-section';
import LeaveWarning from '../../../components/leave-warning/leave-warning';

import styles from './assignment-edit.scss';

const Step5 = (props) => {
  const { handleSubmit, formState: { isDirty } } = useForm();
  const history = useHistory();
  const [showDirtyMessage, setShowDirtyMessage] = useState(false);
  const {
    updateAssignment, assignmentDraft, setCanClose, help
  } = props;

  const [rubricState, setRubricState] = useState(
    assignmentDraft.review_rubric,
  );

  // Track react-hook-form and editor dirty state
  useEffect(() => {
    let editorDirty = assignmentDraft.review_rubric != rubricState;
    if (isDirty || editorDirty) {
      setShowDirtyMessage(true);
      setCanClose(false);
    } else {
      setShowDirtyMessage(false);
      setCanClose(true);
    }
  }, [isDirty, rubricState]);

  const onSubmit = () => {
    updateAssignment({
      ...assignmentDraft,
      review_rubric: rubricState,
    });
    let url = `/assignments/${assignmentDraft.id}`;
    history.push(url);
  };

  return (
    <React.Fragment>
      <LeaveWarning when={showDirtyMessage} />
      <form
        className={styles.step}
        id="step-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <StepSection noPadding fullWidth shadow={false} bgGray transparentContainer>
          <RubricCreateForm
            rubricState={rubricState}
            setRubricState={setRubricState}
            name="step_5_rubric"
            help={help}
          />
        </StepSection>
      </form>
    </React.Fragment>
  );
};

Step5.propTypes = {
  updateAssignment: PropTypes.func.isRequired,
  assignmentDraft: PropTypes.shape({}).isRequired,
};

Step5.defaultProps = {
};

export default Step5;
