import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useQueryClient } from 'react-query';

import Modal from '../../../components/modal/modal';
import Input from '../../../components/input/input';
import Button from '../../../components/button/button';


import styles from './section-create.scss';


const SectionCreate = (props) => {
  const { id, courseID } = useParams();
  const history = useHistory();
  const queryClient = useQueryClient();
  const {
    createSection, sectionCreated, resetSectionCreated, getSectionDetail,
    sectionDetail, updateSection, courseDetail
  } = props;
  let defaultValues = {};

  console.log("SectionCreate")
  console.log(id);
  console.log(courseID);

  if (id) {
    defaultValues = {
      name: sectionDetail.name,
      description: sectionDetail.description
    };
  }
  const {
    register, handleSubmit, setError, isSubmitting, formState: { errors }
  } = useForm({
    defaultValues
  });

  let modalTitle = 'Create a new Section';
  let buttonLabel = 'Create Section';
  let toastLabel = 'Section Created!';

  if (id) {
    modalTitle = 'Update Section';
    buttonLabel = 'Update Section';
    toastLabel = 'Section Updated!';
  }

  useEffect(() => {
    if (sectionCreated) {
      toast(toastLabel, { containerId: 'RIGHT' });
      resetSectionCreated();
      queryClient.invalidateQueries(['admin-course', courseID]);
      history.goBack();
    }
  }, [sectionCreated]);

  useEffect(() => {
    if (id) {
      getSectionDetail();
    }
    resetSectionCreated();
    return () => {
      resetSectionCreated();
    };
  }, []);

  const onSubmit = (data) => {
    const formData = data;

    if (id) {
      updateSection(formData, setError);
    } else {
      formData.course = courseDetail.id || courseID
      createSection(formData, setError);
    }
  };

  return (
    <Modal title={modalTitle}>
      <form onSubmit={handleSubmit(onSubmit)} >
        <div className={styles.row}>
          <Input
            label="Section Name"
            name="name"
            error={errors.name}
            {...register('name', { required: true })}
          />
        </div>
        <div className={styles.row}>
          <Input
            label="Description"
            name="description"
            type="textarea"
            error={errors.description}
            {...register('description', { required: true })}
          />
        </div>
        <Button
          type="submit"
          disabled={isSubmitting}
        >
          {buttonLabel}
        </Button>
      </form>
    </Modal>
  );
};

SectionCreate.propTypes = {
  createSection: PropTypes.func.isRequired,
  sectionCreated: PropTypes.bool,
  resetSectionCreated: PropTypes.func.isRequired,
  getSectionDetail: PropTypes.func.isRequired,
  sectionDetail: PropTypes.shape({}),
  updateSection: PropTypes.func.isRequired,
};

SectionCreate.defaultProps = {
  sectionCreated: false,
  sectionDetail: {},
};

export default SectionCreate;
