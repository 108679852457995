import React, { useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { shortDate, durationBetween, nowUtil } from '../../../common/utils';
import Loading from '../../../components/loading/loading';
import HelpLabel from '../../../components/help-label';
import { getAssignment, publish, getNoTeamMembers } from '../../../queries/admin-queries';
import { getFormHelp, formHelpQuerySettings } from '../../../queries/help';

const pClasses = "my-4";
const buttonClasses = classNames('rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 w-full cursor-pointer my-1');

const Automation = (props) => {
  const { automated } = props;

  if (automated) {
    return (<p className={pClasses}>Your assignment <strong>IS</strong> automated and will advance stages automatically.</p>);
  } else {
    return (<p className={pClasses}>Your assignment <strong>IS NOT</strong> automated and you will advance stages manually.</p>);
  }
};

const Email = (props) => {
  const { sendsEmail, delayStageChangeEmails } = props;

  if (!sendsEmail) {
    return (
      <React.Fragment>
        <p className={pClasses}>The system <strong>WILL NOT</strong> automatically send emails to your participants on your behalf.</p>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <p className={pClasses}>The system will automatically send emails to your participants on your behalf.</p>
      {delayStageChangeEmails && <p className={pClasses}>Stage change emails will be delayed and require you to choose to send them.</p>}
    </React.Fragment>
  );
};

const dateInPast = (d) => {
  const now = new Date();
  const compare = moment(d);
  if (compare <= now) {
    return true;
  } else {
    return false;
  }

};

const ShowPastDateError = (props) => {
  const { compareDate, children } = props;

  if (dateInPast(compareDate)) {
    return (<span className="text-red-500">DATE IN THE PAST</span>)
  }

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  )
};

const DueDates = (props) => {
  const { assignment } = props;

  const hasStage3 = !assignment.skip_feedback_state;

  return (
    <React.Fragment>
      <table className="my-4">
        <thead>
          <tr>
            <th>Stage</th>
            <th>Date / Time</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Open for Submission</td>
            <td>{shortDate(assignment.publish_date)}</td>
            <td><ShowPastDateError compareDate={assignment.publish_date}>{nowUtil(assignment.publish_date)} until Open For Submission</ShowPastDateError></td>
          </tr>
          <tr>
            <td>Stage 1: Submission Due</td>
            <td>{shortDate(assignment.due_date)}</td>
            <td><ShowPastDateError compareDate={assignment.due_date}>{durationBetween(assignment.publish_date, assignment.due_date)} in this stage</ShowPastDateError></td>
          </tr>
          <tr>
            <td>Stage 2: Review Due</td>
            <td>{shortDate(assignment.review_due_date)}</td>
            <td><ShowPastDateError compareDate={assignment.review_due_date}>{durationBetween(assignment.due_date, assignment.review_due_date)} in this stage</ShowPastDateError></td>
          </tr>
          <tr>
            <td>Stage 3: Score Review Due</td>
            {hasStage3 && <React.Fragment>
              <td>{shortDate(assignment.review_feedback_due_date)}</td>
              <td><ShowPastDateError compareDate={assignment.review_feedback_due_date}>{durationBetween(assignment.review_due_date, assignment.review_feedback_due_date)} in this stage</ShowPastDateError></td>
            </React.Fragment>}
            {!hasStage3 && <td colSpan="2">NO STAGE 3</td>}
          </tr>
        </tbody>
      </table>
    </React.Fragment >
  )
};


const PublishControlButtons = (props) => {
  const { id } = useParams();
  const { assignment, handlePublishClick, hasStage3, history } = props;
  const members = useQuery(['admin-assignment-no-team', id], () => getNoTeamMembers(id), { enabled: assignment.type == 'team' });
  const questionTypes = ["text", "5-stars", "bool"];
  let errors = [];
  let canPublish = true;

  // No members
  if (assignment.section_membership_count == 0) {
    canPublish = false;
    errors.push("No students in your section.  Cannot publish an Assignment to an empty section.")
  }

  // Blank assignment
  if (assignment.body_plaintext == '') {
    canPublish = false;
    errors.push('Assignment text cannot be blank');
  }

  // Check if dates are in the past
  if (dateInPast(assignment.publish_date)) {
    canPublish = false;
    errors.push('Publish Date cannot be in the past');
  }

  if (dateInPast(assignment.due_date)) {
    canPublish = false;
    errors.push('Stage 1: Submission Due cannot be in the past');
  }

  if (dateInPast(assignment.review_due_date)) {
    canPublish = false;
    errors.push('Stage 2: Review Due cannot be in the past');
  }

  if (hasStage3 && dateInPast(assignment.review_feedback_due_date)) {
    canPublish = false;
    errors.push('Stage 3: Score Reviews Due cannot be in the past');
  }

  // No stage two questions
  let foundStage2Questions = false;

  assignment.submission_rubric.forEach((item) => {
    if (questionTypes.includes(item.type)) {
      foundStage2Questions = true;
    }
  });

  if (!foundStage2Questions) {
    canPublish = false;
    errors.push("No Stage 2: Review questions")
  }

  // No stage three questions
  if (hasStage3) {
    let foundStage3Questions = false;

    assignment.review_rubric.forEach((item) => {
      if (questionTypes.includes(item.type)) {
        foundStage3Questions = true;
      }
    });

    if (!foundStage3Questions) {
      canPublish = false;
      errors.push("No Stage 3: Score Review questions")
    }
  }

  // If we're a team assignment and there are members not in a team, we can't proceed
  if (assignment.type == 'team' && members.isSuccess && members.data.length != 0) {
    canPublish = false;
    errors.push("Cannot publish a Team Assignment with members not in a team. Please ensure all Section Members are in a Team to proceed.")

  }
  console.log(canPublish)
  console.dir(errors)

  return (
    <div>
      {!canPublish && (<React.Fragment><div className="border border-red-500 p-6 bg-red-100">
        <h3 className="text-2xl text-red-700 font-bold">Cannot Publish Assignment Due to Errors</h3>

        <ul className="list-disc ml-6">
          {errors.map((error) => (
            <li className="my-4">{error}</li>
          ))}
        </ul>
      </div>
        <div className="my-8">
          <a className="rounded py-2 px-4 text-lg text-gray-800 border border-gray-600 bg-gray-200 hover:bg-gray-400 w-full cursor-pointer my-1 mx-4" onClick={() => { history.goBack() }}>Return to Assignment</a>
        </div>
      </React.Fragment>)}
      {canPublish &&
        <div className="my-8">
          <a className="rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 w-full cursor-pointer my-1" onClick={handlePublishClick}>Publish Assignment</a>
          <a className="rounded py-2 px-4 text-lg text-gray-800 border border-gray-600 bg-gray-200 hover:bg-gray-400 w-full cursor-pointer my-1 mx-4" onClick={() => { history.goBack() }}>Cancel</a>
        </div>
      }
    </div>
  );
};

const AssignmentDetailPublish = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const queryClient = useQueryClient();
  const assignment = useQuery(['admin-assignment', id], () => getAssignment(id));
  const help = useQuery(['form-help', 'confirm-and-publish'], () => getFormHelp('confirm-and-publish'), formHelpQuerySettings);

  if (assignment.isLoading) {
    return <Loading message="Loading assignment..." />;
  }

  let isAutomated = assignment.data.automated;

  const publishMutation = useMutation(publish, {
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries(['admin-assignment', variables.id]);
      history.push(`/assignments/${variables.id}/`);
    }
  });

  const handlePublishClick = (event) => {
    event.preventDefault();
    publishMutation.mutate({ id: assignment.data.id });
  }

  const hasStage3 = !assignment.data.skip_feedback_state;
  const lowLeagueSize = assignment.data.league_size === 1;
  const highLeagueSize = assignment.data.league_size > 12;
  const stupidLeagueSize = assignment.data.league_size > 20;

  return (
    <React.Fragment>
      <h1 className="my-4 text-2xl font-bold">
        Confirm and Publish Your Assignment
        <HelpLabel className="inline font-normal" name="publish" help={help} bold={false} />
      </h1>
      <hr className="border-1 border-solid border-black my-2" />
      <p className="my-6"><em>Be sure you are happy with all of your Assignment Settings.</em></p>
      <p className="my-4">After you publish {isAutomated ? 'the process starts.' : 'you must select "Advance to Stage 1: Submission" on the next screen for student access.'}</p>

      {lowLeagueSize && <p className="my-6">Your league size is set to <strong>ONE</strong> which means a participant will only review one submission.</p>}
      {highLeagueSize && <p className="my-6">Your league size is set <strong>HIGH</strong> which will increase the amount of work for participants in Stage 2 and/or 3.</p>}

      {stupidLeagueSize && <p className="my-6 p-6 border border-red-500 bg-red-100">
        <span className="text-red-600 font-bold mr-4">WARNING!!!</span>Having a league size over 20 is almost always a bad idea.  It will create tons of work for each student.
      </p>}

      <Automation automated={isAutomated} />
      <Email
        sendsEmail={assignment.data.sends_email}
        delayStageChangeEmails={assignment.data.delay_stage_change_emails}
      />
      <DueDates assignment={assignment.data} />
      {!hasStage3 && <p className="my-6">
        You selected to skip Stage 3. Did you delete 2 references to Stage 3 in the
        default text?  To do so first choose Edit Assignment, then delete and
        save changes on these 2 tabs: the Welcome Page and top of Review Rubric.
      </p>
      }

      <PublishControlButtons
        assignment={assignment.data}
        handlePublishClick={handlePublishClick}
        hasStage3={hasStage3}
        history={history}
      />
    </React.Fragment>
  );
};

export default AssignmentDetailPublish;
