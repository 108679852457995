import Cookies from 'js-cookie';

export const isAuthenticated = (check = true) => {
  let current = window.localStorage.getItem("authenticated");

  if (current) {
    return true;
  } else {

    if (check) {
      fetch("/api/users/me/", {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': Cookies.get('csrftoken'),
        }
      }).then((response) => {
        if (!response.ok) {
          clearAuthenticated();
          current = false;
        } else {
          let data = response.json();
          setAuthenticated();
          if (data.is_lti) {
            setLTI();
          }
          current = true;
        }
      }).catch((error) => {
        console.error("ERROR running isAuthenticated");
      });
    }
    else {
      current = false;
    }
  }

  console.log(`Called isAuthenticated ${current}`);
  return current;
}

export const setAuthenticated = () => {
  window.localStorage.setItem("authenticated", true);
}

export const clearAuthenticated = () => {
  window.localStorage.removeItem("authenticated")
  window.localStorage.removeItem("is_lti")
}

export const isLTI = () => {
  const current = window.localStorage.getItem("is_lti");
  return current;
}

export const setLTI = () => {
  window.localStorage.setItem("is_lti", true);
}
