import React from 'react';
import PropTypes from 'prop-types';

import styles from './scroll-area.scss';


const ScrollArea = (props) => {
  const {
    children
  } = props;

  return (
    <div className={styles.container}>
      <div className={styles.scrollArea}>
        {children}
      </div>
    </div>
  );
};

ScrollArea.propTypes = {
  children: PropTypes.node.isRequired,
};

ScrollArea.defaultProps = {
};

export default ScrollArea;
