import React from 'react';
import StudentDashboard from '../student/dashboard/dashboard';
import AdminDashboardContainer from '../admin/dashboard/dashboard-container';
import { useQuery } from 'react-query';
import { getMe, userQuerySettings } from '../../queries/user';

const Dashboard = (props) => {
  const user = useQuery('me', getMe, userQuerySettings);

  if (user.isLoading) {
    return <div></div>;
  }

  // Redirect admins to the Django admin
  if (user.data.is_staff) {
    window.location.href = "/admin/";
  }

  // Redirect factuly and org admins to their own dashbaord
  if (user.data.is_faculty || user.data.is_admin) {
    return (<AdminDashboardContainer {...props} />);
  }

  return <StudentDashboard {...props} />;
};


export default Dashboard;
