const initialState = {
  notifications: [],
};

const notifications = (state = initialState, action) => {
  if (action.meta && action.meta.error && action.payload.response) {
    return {
      ...state,
      notifications: action.payload.response.non_field_errors,
    };
  }
  if (action.meta && action.meta.error && action.payload) {
    return {
      ...state,
      notifications: [action.payload.message],
    };
  }
  return state;
};

export default notifications;
