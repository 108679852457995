// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".logout__errorContainer___1JnL7{\n  display:flex;\n  align-items:center;\n  justify-content:center;\n  min-height:100vh\n}\n\n.logout__errorMessage___31duk{\n  display:flex;\n  flex-direction:column\n}\n\n.logout__errorMessage___31duk h1{\n  text-transform:uppercase;\n  font-size:2.25rem;\n  font-weight:700;\n  margin-top:1rem;\n  margin-bottom:1rem\n}", "",{"version":3,"sources":["logout.scss","<no source>"],"names":[],"mappings":"AAAA;EAAgB,YAAY;EAAC,kBAAkB;EAAC,sBAAsB;EAAC;AAAgB;;AAAC;ECAxF,aAAA;EDAkH;AAAqB;;AAAC;ECAxI,yBAAA;EAAA,kBAAA;EAAA,gBAAA;EAAA,gBAAA;EAAA;ADAiM","file":"logout.scss","sourcesContent":[".errorContainer{display:flex;align-items:center;justify-content:center;min-height:100vh}.errorMessage{@apply flex;flex-direction:column}.errorMessage h1{@apply uppercase text-4xl font-bold my-4}",null]}]);
// Exports
exports.locals = {
	"errorContainer": "logout__errorContainer___1JnL7",
	"errorMessage": "logout__errorMessage___31duk"
};
module.exports = exports;
