import React, { useState, useEffect, useRef } from 'react';
import moment from "moment-timezone";

export const arrayToObject = (array) => array.reduce((obj, item) => {
  const arrayObject = obj;
  arrayObject[item.id] = item;
  return arrayObject;
}, {});


export const shortDateTimeFormat = 'MMM Do HH:mm A z';
export const longDateTimeFormat = 'ddd MMM Do YYYY HH:mm A z';


export const shortDate = (date) => {
  let tz = moment.tz.guess();
  return moment(date).tz(tz).format(shortDateTimeFormat)
};

export const longDate = (date) => {
  let tz = moment.tz.guess();
  return moment(date).tz(tz).format(longDateTimeFormat)
};

export const durationBetween = (start, end) => {
  let duration = moment.duration(moment(start).diff(moment(end)));
  return (duration.humanize());
}

export const nowUtil = (date) => {
  let duration = moment.duration(moment(date).diff(moment()));
  return (duration.humanize());
}

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}