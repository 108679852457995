import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getReview } from '../../../queries/admin-queries';
import Navigation from '../../../components/navigation/navigation';
import RubricForm from '../../../components/rubric-form/rubric-form';
import ScrollArea from '../../../components/scroll-area/scroll-area';
import Loading from '../../../components/loading/loading';

import styles from './review-detail.scss';

const ReviewDetail = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const review = useQuery(['review', id], () => getReview(id));

  if (review.isLoading) {
    return (
      <Loading />
    );
  }

  try {
    return (
      <div className={styles.container}>
        <Navigation
          history={history}
          title="Review"
          subtitle={review.data.id}
          hasBackButton
        />
        <ScrollArea>
          <div className={styles.content}>
            <RubricForm
              readOnly
              data={review.data.rubric}
            />
          </div>
        </ScrollArea>
      </div>
    );
  } catch (e) { console.dir(e); }
};

ReviewDetail.propTypes = {
};

ReviewDetail.defaultProps = {
};

export default ReviewDetail;
