import { connect } from 'react-redux';

import {
  getAssignment, resetAssignmentCreated,
  createMultipleAssignments
} from '../../../actions/assignments';
import { getSectionsForCourse } from '../../../actions/sections';
import { sectionsSelector } from '../../../selectors/sections';
import { formApiAction } from '../../../actions/api';

import AssignmentBulkCreate from './assignment-bulk-create';

const mapStateToProps = (state) => ({
  assignmentDetail: state.assignments.assignmentDetail,
  assignmentCreated: state.assignments.assignmentCreated,
  sections: sectionsSelector(state.sections.sections),
});

const mapDispatchToProps = (dispatch) => {
  const formCreateMultipleAssignments = formApiAction(createMultipleAssignments);
  return {
    getSectionsForCourse: (id) => dispatch(getSectionsForCourse(id)),
    getAssignment: (id) => dispatch(getAssignment(id)),
    resetAssignmentCreated: () => dispatch(resetAssignmentCreated()),
    createMultipleAssignments: (formData, setError) => dispatch(
      formCreateMultipleAssignments(formData, setError)
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentBulkCreate);
