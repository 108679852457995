import {
  loginUser, logoutUser, getUser, changePassword, RESET_PASSWORD_CHANGED,
  getUserDetail
} from '../actions/auth';

import { setAuthenticated, clearAuthenticated } from '../common/localstorage';

const initialState = {
  loading: false,
  loggedIn: false,
  user: undefined,
  userDetail: undefined,
  passwordUpdated: false,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case loginUser.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case loginUser.SUCCESS: {
      setAuthenticated();
      return {
        ...state,
        loading: false,
        key: action.payload.key,
        loggedIn: true,
      };
    }
    case loginUser.FAILURE: {
      clearAuthenticated();
      return {
        ...state,
        loading: false,
        loggedIn: false,
      };
    }
    case logoutUser.REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case logoutUser.SUCCESS: {
      clearAuthenticated();
      return {
        ...state,
        loading: false,
        key: '',
        loggedIn: false,
      };
    }
    case logoutUser.FAILURE: {
      clearAuthenticated();
      return {
        ...state,
        loading: false,
        key: '',
        loggedIn: false,
      };
    }
    case getUser.REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case getUser.SUCCESS: {
      return {
        ...state,
        loading: false,
        user: action.payload,
        loggedIn: true,
      };
    }
    case getUser.FAILURE: {
      return {
        ...state,
        loading: false,
        loggedIn: false,
      };
    }
    case changePassword.REQUEST: {
      return {
        ...state,
        loading: true,
        passwordUpdated: false,
      };
    }
    case changePassword.SUCCESS: {
      return {
        ...state,
        loading: false,
        passwordUpdated: true,
      };
    }
    case changePassword.FAILURE: {
      return {
        ...state,
        loading: false,
        loggedIn: false,
      };
    }
    case RESET_PASSWORD_CHANGED: {
      return {
        ...state,
        loading: false,
        passwordUpdated: false,
      };
    }
    case getUserDetail.REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case getUserDetail.SUCCESS: {
      return {
        ...state,
        loading: false,
        userDetail: action.payload,
        loggedIn: true,
      };
    }
    case getUserDetail.FAILURE: {
      return {
        ...state,
        loading: false,
        loggedIn: false,
      };
    }
    default:
      return state;
  }
};

export default auth;
