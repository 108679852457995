// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".scroll-area__container___30W49{\n  position:relative;\n  flex:1 1 100%;\n  z-index:10;\n  height:calc(100vh - 68px)\n}\n\n.scroll-area__scrollArea___2YY46{\n  position:absolute;\n  top:0px;\n  left:0px;\n  width:100%;\n  height:100%;\n  max-height:100%;\n  max-width:100%;\n  overflow-x:hidden;\n  overflow-y:auto\n}", "",{"version":3,"sources":["scroll-area.scss"],"names":[],"mappings":"AAAA;EAAW,iBAAiB;EAAC,aAAa;EAAC,UAAU;EAAC;AAAyB;;AAAC;EAAY,iBAAiB;EAAC,OAAO;EAAC,QAAQ;EAAC,UAAU;EAAC,WAAW;EAAC,eAAe;EAAC,cAAc;EAAC,iBAAiB;EAAC;AAAe","file":"scroll-area.scss","sourcesContent":[".container{position:relative;flex:1 1 100%;z-index:10;height:calc(100vh - 68px)}.scrollArea{position:absolute;top:0px;left:0px;width:100%;height:100%;max-height:100%;max-width:100%;overflow-x:hidden;overflow-y:auto}"]}]);
// Exports
exports.locals = {
	"container": "scroll-area__container___30W49",
	"scrollArea": "scroll-area__scrollArea___2YY46"
};
module.exports = exports;
