
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTransition, animated } from 'react-spring';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Step1 from './assignment-bulk-create-step-1';
import Step2 from './assignment-bulk-create-step-2';

import StepNavigation from '../../../components/step-navigation/step-navigation';

import styles from './assignment-bulk-create.scss';


function AssignmentBulkCreate(props) {
  const { id } = useParams();
  const history = useHistory();
  const {
    assignmentDetail, createAssignment, assignmentCreated,
    resetAssignmentCreated, getSectionsForCourse, sections, createMultipleAssignments
  } = props;
  const [currentStep, setCurrentStep] = useState(1);
  const [assignmentDraft, setAssignmentDraft] = useState({
    body: '',
    plain_text: '',
    thumbnail: '',
    title: '',
  });
  const [direction, setDirection] = useState('forward');
  const transition = useTransition(currentStep, (currentStep) => currentStep, {
    initial: null,
    from: {
      opacity: 0,
      transform: `translate3d(${direction === 'forward' ? '100%' : '-100%'},0,0)`
    },
    enter: {
      opacity: 1,
      transform: `translate3d(${direction === 'forward' ? '0%' : '0%'},0,0)`
    },
    leave: {
      opacity: 0,
      transform: `translate3d(${direction === 'forward' ? '-100%' : '100%'},0,0)`
    },
  });

  useEffect(() => {
    getSectionsForCourse(id);
  }, []);

  useEffect(() => {
    if (assignmentCreated) {
      toast('Thanks! Your assignment was successfully created!', { containerId: 'RIGHT' });
      resetAssignmentCreated();
      history.goBack();
    }
  }, [assignmentCreated]);

  const goToStep = (step) => {
    setDirection('back');
    if (step < currentStep) {
      setCurrentStep(step);
    }
  };

  const goToNextStep = () => {
    setDirection('forward');
    const newStep = currentStep >= 2 ? 3 : currentStep + 1;
    setCurrentStep(newStep);
  };

  const prepareStepFormData = (data) => {
    const formData = [];
    if (data.dates.general_due_date) {
      sections.forEach((item, index) => {
        formData.push({
          title: assignmentDraft.title,
          body: assignmentDraft.body,
          body_plaintext: assignmentDraft.body_plaintext,
          thumbnail: assignmentDraft.thumbnail,
          due_date: data.dates.general_due_date,
          section: item.id,
          review_score: data.reviewScoreState,
          review_comment: data.reviewCommentState,
          review_feedback_comment: data.reviewFeedbackScoreState,
          review_feedback_score: data.reviewFeedbackCommentState,
        });
      });
      createMultipleAssignments(formData);
    } else {
      sections.forEach((item, index) => {
        formData.push({
          title: assignmentDraft.title,
          body: assignmentDraft.body,
          body_plaintext: assignmentDraft.body_plaintext,
          thumbnail: assignmentDraft.thumbnail,
          due_date: data.dates[`section_${item.id}`],
          section: item.id,
          review_score: data.reviewScoreState,
          review_comment: data.reviewCommentState,
          review_feedback_comment: data.reviewFeedbackScoreState,
          review_feedback_score: data.reviewFeedbackCommentState,
        });
      });
      createMultipleAssignments(formData);
    }
  };

  return (
    <div className={styles.container}>
      <StepNavigation
        currentStep={currentStep}
        goToStep={goToStep}
        steps={[
          { title: 'Assignment' },
          { title: 'Due Dates' },
        ]}
        nextType="submit"
        forForm="step-form"
        nextTitle="Continue"
        finalTitle="Save"
      />
      {transition.map(({ item, props, key }) => (
        <animated.div
          className={styles.stepContainer}
          style={props}
          key={key}
        >
          {currentStep === 1 && (
            <Step1
              currentStep={currentStep}
              goToNextStep={goToNextStep}
              assignmentDraft={assignmentDraft}
              setAssignmentDraft={setAssignmentDraft}
            />
          )}
          {currentStep === 2 && (
            <Step2
              currentStep={currentStep}
              goToNextStep={goToNextStep}
              sections={sections}
              prepareStepFormData={prepareStepFormData}
            />
          )}
        </animated.div>
      ))}
    </div>
  );
}

AssignmentBulkCreate.propTypes = {
  getSectionsForCourse: PropTypes.func.isRequired,
  assignmentDetail: PropTypes.shape({}).isRequired,
  createMultipleAssignments: PropTypes.func.isRequired,
  assignmentCreated: PropTypes.bool.isRequired,
  resetAssignmentCreated: PropTypes.func.isRequired,
};

AssignmentBulkCreate.defaultProps = {
};

export default AssignmentBulkCreate;
