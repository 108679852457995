import React, { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getAssignment, getAssignmentDefaults, getLeagues } from '../../../queries/admin-queries';
import Loading from '../../../components/loading/loading';

const AssignmentReportsLeagues = (props) => {
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState(null);
  const assignment = useQuery(['admin-assignment', id], () => getAssignment(id), getAssignmentDefaults);
  const leagues = useQuery(['admin-assignment-leagues', id], () => getLeagues(id), { cacheTime: 60000, staleTime: 10000, keepPreviousData: true });

  // What to refresh when user clients refresh
  const refreshKeys = [
    "admin-assignment-reviewcounts",
    "admin-assignment-reviews",
  ];

  if (assignment.isLoading) {
    return (<Loading message="Loading assignment..." />);
  }
  if (leagues.isSuccess && leagues.data.length === 0) {
    return (
      <p>There are currently no Leagues</p>
    );
  }

  return (
    <React.Fragment>
      <h1 className="text-2xl font-bold mb-4">Leagues and Student Performance</h1>
      <table>
        <thead>
          <tr>
            <th>
              Name
            </th>
            <th>
              Members
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {leagues.data && leagues.data.map((item, index) => (
            <tr key={index}>
              <td>
                {item.name}
              </td>
              <td>{item.member_count}</td>
              <td>
                <NavLink
                  to={`/assignments/${id}/reports/league/${item.id}`}
                >View</NavLink>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </React.Fragment>

  );
};


export default AssignmentReportsLeagues;
