/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import produce from 'immer';

import RatingBox from '../rating/rating';
import Input from '../input/input';
import Checkbox from '../checkbox';

import EditIcon from '../../../assets/icons/pencil-1.svg';
import CheckIcon from '../../../assets/icons/check-1.svg';
import TrashIcon from '../../../assets/icons/old-bin.svg';
import TextIcon from '../../../assets/icons/paragraph-left-align.svg';

import styles from './rubric-widget.scss';

const RubrictWidget = (props) => {
  const {
    index, rubricState, setRubricState, item, questionNumber,
  } = props;

  const [editModeState, setEditModeState] = useState(false);

  const handleChangeInput = (e) => {
    const newState = produce(rubricState, (draftState) => {
      const objIndex = draftState.findIndex((obj => obj.id === item.id));
      draftState[objIndex].label = e.target.value;
    });
    setRubricState(newState);
  };

  if (item.label === '' && !editModeState) {
    setEditModeState(true);
  }

  const handleToggleEditMode = () => {
    setEditModeState(!editModeState);
  };

  const handleDelete = () => {
    const newState = produce(rubricState, (draftState) => {
      return draftState.filter((obj) => obj.id !== item.id);
    });
    setRubricState(newState);
  };

  const renderContent = () => {

    // ITEM TYPE TEXT & DESCRIPTION
    if (item.type === 'text' || item.type === 'description') {

      let placeHolder = "Enter your question here";
      let number = `${questionNumber}. `;
      if (item.type === 'description') {
        placeHolder = "This is for instructions and headings; this is not a question";
        number = null;
      }
      if (editModeState) {
        return (
          <Input
            type="textarea"
            name={`input-${index}`}
            defaultValue={item.label}
            onChange={handleChangeInput}
            placeholder={placeHolder}
          />
        );
      }

      if (!editModeState) {
        return (
          <label className={styles.label}>
            {number}{item.label}
          </label>
        );
      }
    }

    // ITEM TYPE 5-STARS
    if (item.type === '5-stars') {
      if (editModeState) {
        return (
          <>
            <Input
              name={`input-${item.id}`}
              defaultValue={item.label}
              onChange={handleChangeInput}
            />
            <RatingBox
              className={styles.ratingBox}
              initialRating={3}
              readonly
              name={`input-${item.id}`}
              maxRating={5}
            />
          </>
        );
      }

      if (!editModeState) {
        return (
          <>
            <label className={styles.label}>
              {questionNumber}. {item.label}
            </label>
            <RatingBox
              className={styles.ratingBox}
              initialRating={3}
              readonly
              name={`input-${item.id}`}
              maxRating={5}
            />
          </>
        );
      }
    }

    // ITEM TYPE CHECKBOX
    if (item.type === 'bool') {
      if (editModeState) {
        return (
          <>
            <Input
              name={`input-${item.id}`}
              defaultValue={item.label}
              onChange={handleChangeInput}
            />
            <Input
              type="checkbox"
              name={`input-checkbox-${item.id}`}
              defaultValue={item.answer}
              onChange={handleChangeInput}
            />
          </>
        );
      }

      if (!editModeState) {
        return (
          <>
            <label className={styles.label}>
              {questionNumber}. {item.label}
            </label>
            <Checkbox
              type="checkbox"
              name={`input-${item.id}`}
              checked={item.answer}
            />
          </>
        );
      }
    }
    return null;
  };

  let labelText = '';
  if (item.type === 'description') {
    labelText = 'Instructions for students';
  } else {
    labelText = `Question - Type ${item.type}`;
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <TextIcon />
          <div className={styles.headerTitle}>{labelText}</div>
          <div
            className={classNames(
              styles.button, styles.editButton, {
              [styles.editModeButton]: editModeState
            }
            )}
            onClick={handleToggleEditMode}
          >
            {!editModeState && (
              <EditIcon />
            )}
            {editModeState && (
              <CheckIcon />
            )}
          </div>
          <div
            className={classNames(
              styles.button, styles.deleteButton, {
              [styles.closeButton]: editModeState
            }
            )}
            onClick={handleDelete}
          >
            <TrashIcon />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.description}>
            {
              item.type === 'description' ? 'Instruction' : 'Question'
            }
          </div>
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

RubrictWidget.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    label: PropTypes.string,
    answer: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
    ])
  }).isRequired,
  index: PropTypes.number.isRequired,
  rubricState: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setRubricState: PropTypes.func.isRequired,
};

RubrictWidget.defaultProps = {
  questionNumber: null,
};

export default RubrictWidget;
