import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { configurablePendingTasksReducer } from 'react-redux-spinner';

import auth from './auth';
import assignments from './assignments';
import courses from './courses';
import sections from './sections';
import submissions from './submissions';
import studentSubmissions from './student-submissions';
import studentAssignments from './student-assignments';
import reviews from './reviews';
import reviewFeedbacks from './review-feedbacks';
import todos from './todos';
import notifications from './notifications';
import ui from './ui';

const pendingTasksReducer = configurablePendingTasksReducer({ actionKeyPath: ['meta'] });

const reducers = combineReducers({
  // router: connectRouter(history),
  pendingTasks: pendingTasksReducer,
  auth,
  assignments,
  studentAssignments,
  courses,
  sections,
  submissions,
  studentSubmissions,
  reviews,
  reviewFeedbacks,
  todos,
  notifications,
  ui,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_USER_SUCCESS') {
    // setting state to undefined like dan proposed here  https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store/35641992#35641992
    // will cause an issue with the pendingTasks state.
    // this should be not undefined. so for now I'm manually resetting the states
    // state.users = undefined;

    return reducers(state, action);
  }

  return reducers(state, action);
};

export default rootReducer;
