import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';

import { longDate } from '../../../common/utils';
import { submissionsSelector } from '../../../selectors/submissions';
import SubmissionListItem from '../../../components/submission-list-item/submission-list-item';
import List from '../../../components/list/list';

import styles from './section-detail.scss';


const SectionDetailOverview = (props) => {
  const {
    submissions
  } = props;

  return (
    <section className={styles.section}>
      <List>
        {submissions.length === 0 && (
          <h3>This Section currently has no submissions</h3>
        )}
        {submissions.map((item, index) => (
          <SubmissionListItem
            url="submissions"
            id={item.id}
            title={`${item.author.first_name} ${item.author.last_name}`}
            subtitle={`Last Modified ${longDate(item.modified)}`}
            footer={item.assignment}
            imageURL={item.thumbnail}
            key={item.id}
          />
        ))}
      </List>
    </section>
  );
};

SectionDetailOverview.propTypes = {
  submissions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  submissions: submissionsSelector(state.submissions.submissions),
  course: state.courses.course,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionDetailOverview);
