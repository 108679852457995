import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { useHistory, } from 'react-router';
import { createAssignment } from '../../../queries/admin-queries';
import { useMutation, useQueryClient } from 'react-query';
import defaultAssignment from '../../../common/assignment-defaults';
const buttonClasses = classNames('rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 w-full cursor-pointer my-1');


const CreateNewAssignmentButton = (props) => {
  const { sectionID, label, type, isHBP } = props;
  const history = useHistory();
  const queryClient = useQueryClient();

  // CREATE ASSIGNMENT
  const {
    mutate: mutateCreateAssigment,
  } = useMutation('admin-assignment', (values) => createAssignment(values));

  const createNewAssignment = () => {
    const dateNow = moment.utc().local().format('YYYY-MM-DD HH:mm');
    const initialData = defaultAssignment(sectionID, type, isHBP);

    mutateCreateAssigment(initialData, {
      onSuccess: (data, values) => {
        queryClient.invalidateQueries(['admin-assignment', data.id]);
        console.log("Created assignment!")
        console.dir(data);
        console.dir(values);
        let url = `/assignments/${data.id}/edit`;
        console.log(`URL: ${url}`);
        history.push(url);
      },
      onError: (error, variables, context) => {
        console.log(error.response, variables, context);
        history.push("/");
      },
    });
  };

  return (
    <a
      className={buttonClasses}
      onClick={createNewAssignment}
    >
      {label}
    </a>
  );
};

export default CreateNewAssignmentButton;
