import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Input from '../../components/input/input';
import Button from '../../components/button/button';
import PlatformName from '../../components/platform';

import Logo from './logo';
import styles from './login.scss';


const ForgotPassword = (props) => {
  const {
    register, handleSubmit, setError, formState: { errors, isSubmitted }
  } = useForm();

  const { resetUser, loggedIn, history } = props;

  const onSubmit = (data) => { resetUser(data, setError); };

  useEffect(() => {
    if (loggedIn) {
      history.push('/dashboard');
    }
  }, [loggedIn]);

  return (
    <div className={styles.container}>
      <div className="w-full max-w-xs">
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <Logo />
          {!isSubmitted &&
            <form onSubmit={handleSubmit(onSubmit)}>
              <p className="my-4">
                Enter the email address on file with us and we'll send you an email
                with a link you can use to reset it.
              </p>
              <div className="mb-4">
                <Input
                  label="Email"
                  name="email"
                  error={errors.email}
                  placeholder="Enter your email address"
                  {...register('email', { required: true })}
                />
              </div>
              <div className="w-full items-center">
                <Button
                  type="submit"
                >
                  Send Password Reset Email
                </Button>

              </div>
            </form>
          }
          {isSubmitted &&
            <div>
              <p className="my-4">
                If the email address you've entered is in our system, we'll send it
                an email with instructions on how to reset your password.
              </p>
              <p className="my-4">
                If you do not receive one within a few minutes please check your spam folder
                and verify the email address that was setup for you in <PlatformName />.
              </p>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

ForgotPassword.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  resetUser: PropTypes.func.isRequired,
};


export default ForgotPassword;
