import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import Loading from '../../../components/loading/loading';
import {
  getAssignment, getLeagues, createTeams, getNoTeamMembers,
  addTeamMember, randomizeTeams
} from '../../../queries/admin-queries';

const pClasses = "my-4";
const buttonClasses = classNames('rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 w-full cursor-pointer my-1');
const smallButtonClasses = classNames('rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 cursor-pointer my-4');

const ItemTypes = {
  TEAM: 'team',
  MEMBER: 'member'
}

const TeamCreationForm = (props) => {
  const { assignment } = props;
  const queryClient = useQueryClient();

  const { register, handleSubmit, setError, reset } = useForm();

  const { mutate: mutateCreateTeams } = useMutation(['admin-create-teams'], (values) => createTeams(assignment.data.id, values));

  const onSubmit = (data) => {
    console.log("Submitting")
    console.dir(data);
    mutateCreateTeams(data, {
      onSuccess: (data, values) => {
        queryClient.invalidateQueries(['admin-assignment-leagues', assignment.data.id]);
        reset()
      }
    })
  }

  // Don't show team creation except before the Assignment starts
  if (assignment.data.state !== 'pending') {
    return false;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-inline">
        <input
          placeholder="# of teams to add"
          type="number"
          className="p-2 rounded mr-4"
          {...register('count', { required: true })}
        />
        <button className={buttonClasses} type="submit">Create Teams</button>
      </div>
    </form>
  )
}

const Team = (props) => {
  const { id } = useParams();
  const { team, assignmentID, assignment } = props;
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();
  const queryClient = useQueryClient();
  const mutation = useMutation(addTeamMember,
    {
      onSuccess: (data, values) => {
        queryClient.invalidateQueries(['admin-assignment-leagues', assignmentID]);
        queryClient.invalidateQueries(['admin-assignment-no-team', assignmentID]);
      }
    }
  );

  const onDrop = (item) => {
    mutation.mutate({ leagueID: team.id, sectionMemberID: item.id })
  }

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: ItemTypes.MEMBER,
      canDrop: () => true,
      drop: (item) => onDrop(item),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop()
      })
    }),
  )

  const teamClasses = classNames("flex mb-4 border border-1 border-blue-500 p-4 bg-blue-200",
    {
      'bg-blue-400': isOver,
    })

  const smallButtonClasses = classNames('rounded py-1 px-2 text-base text-white bg-blue-500 hover:bg-blue-700 w-full cursor-pointer');

  const goToTeam = () => {
    history.push(`/assignments/${id}/edit-team/${team.id}`);
  }

  let suffix = ''
  if (team.member_count > 1) {
    suffix = 's'
  }

  return (
    <div ref={drop} className={teamClasses}>
      <div className="flex-grow">
        {team.name}
      </div>
      <div className="ml-2">
        {team.member_count == 0 && (
          <span><em>No members</em></span>
        )}
        {team.member_count > 0 && (
          <span>{team.member_count} member{suffix}</span>
        )}
      </div>
      <div className="ml-4">
        {assignment.state == 'pending' && (
          <button className={smallButtonClasses} onClick={goToTeam}>View / Edit Team</button>)
        }
      </div>
    </div>
  )
}

const Member = (props) => {
  const { member } = props;
  const memberID = member.id;
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.MEMBER,
      item: { id: memberID },
    }),
  )

  const memberClasses = classNames("w-full p-2 border border-1 border-yellow-500 mb-2 cursor-move hover:bg-yellow-200 ",
    {
      'bg-yellow-200': isDragging,
      'font-bold': isDragging,
    })

  return (
    <div ref={drag}>
      <div className={memberClasses} key={member.id}>
        {member.first_name} {member.last_name} {isDragging && <span>DRAG!</span>}
      </div>
    </div>
  )
}

const Members = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const members = useQuery(['admin-assignment-no-team', id], () => getNoTeamMembers(id));


  if (members.isLoading) {
    return <Loading message="Loading section members..." />;
  }

  if (members.data.length == 0) {
    return (
      <>
        <em>All team members assigned</em>
        <div className="w-full pt-4 pr-6 mt-8 text-center">
          <button className={buttonClasses} onClick={() => history.push(`/assignments/${id}/`)}>Return to Assignment</button>

        </div>
      </>
    )
  }

  return (
    <div className="flex flex-col w-full pr-4">
      {members.data.map((member) => (
        <Member key={member.id} member={member} />
      ))}
    </div>
  )
}

const AssignmentDetailTeams = (props) => {
  const { id } = useParams();
  const [randomizing, setRandomizing] = useState(false);
  const [hasMembers, setHasMembers] = useState(false);
  const history = useHistory();
  const queryClient = useQueryClient();

  const assignment = useQuery(['admin-assignment', id], () => getAssignment(id));
  const leagues = useQuery(['admin-assignment-leagues', id], () => getLeagues(id));

  const mutation = useMutation(randomizeTeams,
    {
      onSuccess: (data, values) => {
        queryClient.invalidateQueries(['admin-assignment-leagues', id]);
        queryClient.invalidateQueries(['admin-assignment-no-team', id]);
        setRandomizing(false);
      },
      onError: (error) => {
        console.log(error);
        setRandomizing(false);
      }
    }
  );

  useEffect(() => {
    // Can the randomize mutation if we should be randomizing
    if (randomizing) {
      mutation.mutate(id)
    }
  }, [randomizing])

  // See if any of our leagues have members already, as we can't randomize then
  useEffect(() => {
    if (leagues.data) {
      setHasMembers(false);
      Array.from(leagues.data).some((league) => {
        if (league.member_count !== 0) {
          setHasMembers(true);
        }
      })
    }
  }, [leagues])

  if (assignment.isLoading || leagues.isLoading) {
    return <Loading message="Loading assignment and teams..." />;
  }

  if (assignment.data.type !== 'team') {
    return (
      <React.Fragment>
        <h1 className="my-4 text-2xl font-bold">ERROR: This is not a Team Assignment</h1>
        <p className={pClasses}>
          Not entirely sure how you ended up here, but this is not a Team Assignment so you cannot
          manage teams for this.
        </p>
      </React.Fragment>
    );
  }


  // Handle call to randomizing
  if (randomizing) {
    return <Loading message="Randomizing team assignments..." />;
  }

  return (
    <React.Fragment>
      <div className="flex w-full">
        <h1 className="my-4 text-2xl flex-grow font-bold">Manage Teams</h1>
        <div className="flex-initial">
          <TeamCreationForm assignment={assignment} />
        </div>
      </div>
      <hr className="mb-4" />
      <DndProvider backend={HTML5Backend}>
        <div className="flex">
          <div className="w-1/3 py-4">
            <h2 className="text-lg font-bold mb-4">Members</h2>
            <p className="my-2">
              You can drag-and-drop members into teams. All changes are saved automatically.
            </p>
            {!hasMembers && (<button className={smallButtonClasses} onClick={() => setRandomizing(true)}>Randomly assign members</button>)}
            <Members />
          </div>
          <div className="w-2/3 py-4">
            <h2 className="text-lg font-bold mb-4">Teams</h2>
            <div className="w-full">
              {leagues.data.length == 0 && (
                <p className="p-4">
                  <em>There are currently no teams for this assignment. Please add the number of teams you would like to create in the box above and select “Create Teams.”</em>
                </p>
              )}
              {leagues.data.map((league) => (
                <Team
                  team={league}
                  key={league.id}
                  assignmentID={id}
                  assignment={assignment.data}
                />
              ))}

            </div>
          </div>
        </div>
      </DndProvider>
    </React.Fragment>
  );
};

export default AssignmentDetailTeams;
