import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import styles from './logout.scss';
import { clearAuthenticated, isLTI } from '../../common/localstorage';
import Button from '../../components/button/button';

const Logout = () => {
  const queryClient = useQueryClient();
  const lti = isLTI();

  const logUserOut = useMutation(() => {
    fetch('/api/logout/', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken'),
      },
    }).then((response) => {
      if (response.status != 200) {
        queryClient.invalidateQuery('me');
      }
    });
    clearAuthenticated();
  });

  useEffect(() => {
    logUserOut.mutate();
    if (!lti) {
      window.location = '/';
    }
  }, []);


  return (
    <div className="page">
      <div className={styles.errorContainer}>
        <div className={styles.errorMessage}>
          <h1>You have been logged out</h1>
          <p className="my-4">
            You may now close this browser tab.
          </p>
        </div>
      </div>
    </div>
  );
};


export default Logout;
