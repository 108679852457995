import React, { useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import classNames from 'classnames';
import { getMe, userQuerySettings } from '../../../queries/user';
import { getAssignment, getAssignmentReviewFeedback } from '../../../queries/student_assignments';
import Loading from '../../../components/loading/loading';
import Navigation from '../../../components/navigation/navigation';
import ScrollArea from '../../../components/scroll-area/scroll-area';
import styles from './results.scss';
import ReactHtmlParser from 'react-html-parser';

const buttonClasses = classNames("rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 cursor-pointer");

const ShowReview = (props) => {
  const { feedback, index } = props;

  let classes = classNames(
    "my-4 p-4 border border-gray-600 hover:cursor-pointer bg-blue-300",
  );


  return (
    <div className={classes}>
      <h3 className="my-2 font-bold">#{index + 1} Feedback</h3>
      {feedback.qualitative.map((qualitative, i) => (
        <>
          <p className="my-2 font-bold">{qualitative.label}</p>
          <p className="my-2">{qualitative.answer}</p>
        </>
      ))}
      {!feedback.qualitative.length && <p className="my-2">No qualitative text questions in this assignment's score feedback</p>}
    </div>
  )
}

const DisplayReviewFeedback = (props) => {
  const { result, index, chosen, toggleChosen } = props;
  let isChosen = false;
  if (chosen == result.id) {
    isChosen = true;
  }

  let classes = classNames(
    "my-4 p-4 border border-gray-400",
    { "bg-gray-200": !isChosen },
    { "bg-blue-200": isChosen },
    { "hover:bg-gray-300": !isChosen },
  );

  return (
    <div className={classes} onClick={() => toggleChosen(result.id)}>
      <h2 className="text-xl font-bold">Review #{index + 1}</h2>
      {isChosen && <div className="my-4">
        <div className="my-4 p-4 bg-blue-100">
          {result.qualitative.map((qualitative, i) => (
            <>
              <p className="my-2 font-bold">{qualitative.label}</p>
              <p className="my-2">{qualitative.answer}</p>
            </>
          ))}
        </div>
        {result.feedbacks.map((feedback, i) => (
          <ShowReview key={i} feedback={feedback} index={i} />
        ))}
      </div>}
    </div>
  )
}

const FeedbackResults = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const [chosen, setChosen] = useState(null);
  const user = useQuery('me', getMe, userQuerySettings);
  const assignment = useQuery(['student-assignment', id], () => getAssignment(id));
  const results = useQuery(['student-assignment-results-feedback', id], () => getAssignmentReviewFeedback(id));

  const toggleChosen = (newChoice) => {
    console.log(newChoice);
    if (newChoice === chosen) {
      setChosen(null);
    } else {
      setChosen(newChoice);
    }
  }

  if (user.isLoading || assignment.isLoading || results.isLoading) {
    return <Loading />
  }

  let isMultiple = results.data.length > 1;

  return (
    <div className={styles.container}>
      <Navigation
        history={history}
        title="Your Review Feedback"
        backURL={`/results/${id}`}
        hasBackButton
      />
      <ScrollArea>
        <div className={styles.content}>
          <div className={styles.welcome}>
            <h3>
              Feedback for Reviews you wrote
            </h3>
            <span className={buttonClasses}>
              <Link to={`/results/${id}`}>Back to Results</Link>
            </span>
          </div>
          <p className="my-4">Click on {isMultiple ? 'each' : 'the'} Review below to view the Review and all Feedback. {isMultiple && (<span>Click it again to close and view another.</span>)}</p>
          <div className="my-6">
            {results.data.map((result, index) => (
              <DisplayReviewFeedback
                key={index}
                index={index}
                result={result}
                chosen={chosen}
                toggleChosen={toggleChosen}
              />
            ))}
          </div>
        </div>
      </ScrollArea>
    </div>
  )
};

export default FeedbackResults;
