// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".assignment-bulk-create__container___1aag5{\n  position:absolute;\n  top:0;\n  right:0;\n  bottom:0;\n  left:0;\n  width:100%;\n  height:100vh;\n  background-color:#fff\n}\n\n.assignment-bulk-create__stepContainer___3y6jS{\n  position:absolute;\n  left:0;\n  height:calc(100vh - 68px);\n  width:100vw\n}\n\n.assignment-bulk-create__step___1tlMu{\n  height:calc(100vh - 68px);\n  overflow:scroll\n}\n\n.assignment-bulk-create__row___2k2g9{\n  margin-bottom:1rem\n}\n\n.assignment-bulk-create__switch___eUMY9{\n  display:flex\n}\n\n.assignment-bulk-create__switch___eUMY9 span{\n  margin-left:1rem\n}", "",{"version":3,"sources":["assignment-bulk-create.scss","<no source>"],"names":[],"mappings":"AAAA;EAAW,iBAAiB;EAAC,KAAK;EAAC,OAAO;EAAC,QAAQ;EAAC,MAAM;EAAC,UAAU;EAAC,YAAY;EAAC;AAAqB;;AAAC;EAAe,iBAAiB;EAAC,MAAM;EAAC,yBAAyB;EAAC;AAAW;;AAAC;EAAM,yBAAyB;EAAC;AAAe;;AAAC;ECAvO;ADAuP;;AAAC;EAAQ;AAAY;;AAAC;EAAa;AAAgB","file":"assignment-bulk-create.scss","sourcesContent":[".container{position:absolute;top:0;right:0;bottom:0;left:0;width:100%;height:100vh;background-color:#fff}.stepContainer{position:absolute;left:0;height:calc(100vh - 68px);width:100vw}.step{height:calc(100vh - 68px);overflow:scroll}.row{@apply mb-4}.switch{display:flex}.switch span{margin-left:1rem}",null]}]);
// Exports
exports.locals = {
	"container": "assignment-bulk-create__container___1aag5",
	"stepContainer": "assignment-bulk-create__stepContainer___3y6jS",
	"step": "assignment-bulk-create__step___1tlMu",
	"row": "assignment-bulk-create__row___2k2g9",
	"switch": "assignment-bulk-create__switch___eUMY9"
};
module.exports = exports;
