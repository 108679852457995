import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Checkmark from '../../../components/checkmark/checkmark';
import X from '../../../components/x/x';
import Avatar from '../../../components/avatar/avatar';
import { reviewsSelector } from '../../../selectors/reviews';

import styles from './participant-detail.scss';

const Review = (props) => {
  const { review, index } = props;
  const history = useHistory();

  const handleClick = () => {
    history.push(`/reviews/${review.id}`);
  };

  return (
    <tr key={index} onClick={handleClick} className={styles.tableHover}>
      <td>
        <div className={styles.author}>
          <div className={styles.authorAvatar}>
            <Avatar
              name={review.author.display_name}
            />
          </div>
          <div className={styles.authorInfo}>
            <div className={styles.authorName}>{review.author.display_name}</div>
            <div className={styles.authorEmail}>{review.author.email}</div>
          </div>
        </div>
      </td>
      <td className={styles.reviewTitle}>{review.submission_author.display_name}</td>
      <td>{review.is_complete ? <Checkmark /> : <X />}</td>
      <td>
        <Link
          exact
          to={`/reviews/${review.id}`}
        >
          View
        </Link>
      </td>
    </tr>
  );
};

const ParticipantDetailreviews = (props) => {
  const reviews = reviewsSelector(useSelector((state) => state.reviews.reviews));

  if (reviews.length === 0) {
    return (
      <p>There are currently no reviews</p>
    );
  }

  return (
    <table>
      <thead>
        <tr>
          <th>
            By
          </th>
          <th>
            Reviewed
          </th>
          <th>
            Completed
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        {reviews.map((review, index) => (
          <Review key={review.id} review={review} index={index} />
        ))}
      </tbody>
    </table>
  );
};

ParticipantDetailreviews.propTypes = {
};

export default ParticipantDetailreviews;
