import Cookies from 'js-cookie';

export const getAssignments = async () => {
  const response = await fetch("/api/student/assignments/", {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
};

export const getAssignmentSettings = {
  cacheTime: 60000,
  staleTime: 60000,
};

export const getAssignment = async (id) => {
  const response = await fetch(`/api/student/assignments/${id}/`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
};

export const getAssignmentSubmission = async (queryKey) => {
  const response = await fetch(`/api/student/assignments/${queryKey}/submission/`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
};


export const getAssignmentResults = async (queryKey) => {
  const response = await fetch(`/api/student/results/${queryKey}/`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
};

export const getAssignmentCompareResults = async (queryKey) => {
  const response = await fetch(`/api/student/results/compare/${queryKey}/`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
};

export const getAssignmentReviewFeedback = async (queryKey) => {
  const response = await fetch(`/api/student/results/feedback/${queryKey}/`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
};

export const getAssignmentMembers = async (id) => {
  const response = await fetch(`/api/student/assignments/${id}/team_members/`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
};

export const getOral = async (id) => {
  const response = await fetch(`/api/student/assignments/${id}/oral/`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
};

export const startOral = async (id) => {
  const response = await fetch(`/api/oral/${id}/start/`, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
};

export const uploadOral = async ({ id, videoBlob }) => {
  let data = new FormData();
  data.append("video", videoBlob, `oral-${id}.mp4`);

  console.log("uploading data")
  console.dir(videoBlob)
  const response = await fetch(`/api/oral/${id}/upload/`, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
    body: data,
  });
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
};
