import React, { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import classNames from 'classnames';
import { getAssignment, getAssignmentDefaults, getSectionMembers } from '../../../queries/admin-queries';
import { getMe, userQuerySettings } from '../../../queries/user';
import Loading from '../../../components/loading/loading';

const Writing = (props) => {
  const { writing } = props
  const [expanded, setExpanded] = useState(false);

  const tdStyle = {
    "width": "50%",
    "maxWidth": "50%",

  }
  const smallStyle = {
    "maxHeight": "100px",
    "textOverflow": "ellipsis",
    "overflow": "hidden",
    "overWrap": "break-word",
    "whiteSpace": "normal",
    "WebkitLineClamp": "6",
    "WebkitBoxOrient": "vertical",
    "display": "-webkit-box",
  }
  const largeStyle = {
    "overflow": "hidden",
    "whiteSpace": "pre-line",
  }

  const toggle = () => {
    setExpanded(!expanded);
  }

  return (
    <td onClick={() => toggle()} style={tdStyle}>
      <span style={expanded ? largeStyle : smallStyle}>{writing}</span>
      {!expanded && <span>...</span>}
    </td>
  )
}

const AssignmentReportsResults = (props) => {
  const { id } = useParams();
  const user = useQuery('me', getMe, userQuerySettings);
  const assignment = useQuery(['admin-assignment', id], () => getAssignment(id), getAssignmentDefaults);
  const members = useQuery(['admin-assignment-members', id], () => getSectionMembers(id));
  const buttonClasses = classNames('rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 w-full cursor-pointer my-4');

  if (assignment.isLoading || members.isLoading || user.isLoading) {
    return <Loading />;
  }

  // Guardrail for when it's too early to see anything useful
  if (assignment.data.state == 'pending' || assignment.data.state == 'submission') {
    return (
      <React.Fragment>
        <h1 className="text-2xl font-bold mb-4">Student Feedback</h1>
        <p className="p-4 mt-8 border bg-blue-100">
          This report is only available in Stage 3 and Completed stages of an Assignment.
        </p>
      </React.Fragment>
    )
  }

  let demoInsert = null
  if (user.data.is_demo) {
    const linkClasses = "text-blue-500 hover:text-blue-700 underline"
    demoInsert = <React.Fragment>
      <div className="my-6 p-4 border border-blue-300 bg-blue-100">
        This demo does not currently include any ChatGPT generated responses, but you can view sample results in these PDFs:
        <ul className="list-disc ml-6 my-4">
          <li>
            <a href="https://peer-learning-uat-static.nyc3.digitaloceanspaces.com/pdfs/ai-comparison-report-1.pdf" target="_blank" className={linkClasses}>
              PDF 1
            </a>
          </li>
          <li>
            <a href="https://peer-learning-uat-static.nyc3.digitaloceanspaces.com/pdfs/ai-comparison-report-2.pdf" target="_blank" className={linkClasses}>
              PDF 2
            </a>
          </li>
        </ul>
      </div>
    </React.Fragment>
  }
  return (
    <React.Fragment>
      <h1 className="text-2xl font-bold my-6">Student Feedback</h1>
      {demoInsert}
      <table className="table-fixed">
        <thead>
          <tr>
            <th className="w-1/2 align-top">Participant</th>
            <th className="w-1/2">Feedback</th>
          </tr>
        </thead>
        <tbody>
          {members.data.map((m) => {
            return (
              <tr key={m.id} className="hover:bg-blue-100">
                <td className="w-1/4 align-top">
                  <span className="font-bold">{m.first_name} {m.last_name}</span><br />
                  <span className="text-gray-500">{m.email}</span>
                </td>
                <td className="w-1/4 align-middle">
                  <NavLink
                    exact
                    className={buttonClasses}
                    to={`/assignments/${id}/reports/results/${m.id}/`}
                  >
                    View Feedback
                  </NavLink>
                </td>
              </tr>
            )
          })}
          {members.data.length === 0 && (
            <tr>
              <td colSpan="5">No participants found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </React.Fragment>

  );
};


export default AssignmentReportsResults;
