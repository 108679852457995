import React from 'react';


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log("ERROR: ", error)
    console.dir("ERROR INFO: ", errorInfo)
    this.setState({ error: error, errorInfo: errorInfo });
  }

  render() {
    if (this.state.hasError) {
      console.dir(this.state);
      return (
        <div className="container">
          <h1 className="my-6 text-xl font-bold p-4 bg-red-400 border-gray-500 rounded">Whoops there was an error</h1>
          <div className="font-bold my-6">
            {this.state.error && this.state.error.message}
          </div>

          <div className="my-6 text-sl bg-gray-200 p-4 border-gray-800">
            This error has been sent to our technical staff. Please only report this via our support channels if
            it happens to you consistently.
          </div>

          <div className="my-6 bg-gray-200 p-4 border-gray-800">
            <a href="/">Return to dashboard</a>
          </div>
          <div className="my-2"></div>
        </div>

      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
