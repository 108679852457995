// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".help-label__label___1UcaB{\n  display:block;\n  --text-opacity:1;\n  color:#4a5568;\n  color:rgba(74, 85, 104, var(--text-opacity));\n  font-size:0.875rem;\n  font-weight:700;\n  margin-bottom:0.5rem;\n  display:inline-block;\n  cursor:pointer\n}\n\n.help-label__label___1UcaB:hover{\n  color:#3b82f6\n}\n\n.help-label__disabledLabel___36DlD{\n  display:block;\n  --text-opacity:1;\n  color:#fc8181;\n  color:rgba(252, 129, 129, var(--text-opacity));\n  font-size:0.875rem;\n  font-weight:700;\n  margin-bottom:0.5rem;\n  display:inline-block\n}\n\n.help-label__helplabel___14cuR{\n  cursor:pointer\n}", "",{"version":3,"sources":["help-label.scss","<no source>"],"names":[],"mappings":"AAAA;ECAA,cAAA;EAAA,iBAAA;EAAA,cAAA;EAAA,6CAAA;EAAA,mBAAA;EAAA,gBAAA;EAAA,qBAAA;EAAA,qBAAA;EAAA;ADAoF;;AAAC;EAAa;AAAa;;AAAC;ECAhH,cAAA;EAAA,iBAAA;EAAA,cAAA;EAAA,+CAAA;EAAA,mBAAA;EAAA,gBAAA;EAAA,qBAAA;EAAA;ADA4L;;AAAC;ECA7L;ADA6N","file":"help-label.scss","sourcesContent":[".label{@apply block text-gray-700 text-sm font-bold mb-2 inline-block cursor-pointer}.label:hover{color:#3b82f6}.disabledLabel{@apply block text-red-400 text-sm font-bold mb-2 inline-block}.helplabel{@apply cursor-pointer}",null]}]);
// Exports
exports.locals = {
	"label": "help-label__label___1UcaB",
	"disabledLabel": "help-label__disabledLabel___36DlD",
	"helplabel": "help-label__helplabel___14cuR"
};
module.exports = exports;
