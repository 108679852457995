import { createApiAction, API_URL } from './api';

export const getTodos = createApiAction('GET_TODOS', () => ({
  endpoint: `${API_URL}/api/student/todo-list/`,
  method: 'GET',
}));

export const getTodoDetail = createApiAction('GET_TODO_DETAILS', (formData) => ({
  endpoint: `${API_URL}/api/student/todo-details/`,
  method: 'POST',
  body: JSON.stringify(formData),
}));
