/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Help from '../help';
import styles from './help-label.scss';
import classNames from 'classnames';

const HelpLabel = (props) => {
  const { label, name, help, className, disabled } = props;
  const [showModal, setShowModal] = useState(false);

  const handleOpen = () => {
    if (!showModal) {
      setShowModal(true);
    }
  };

  const labelClasses = classNames("block text-sm font-bold mb-2 inline-block", {
    "text-gray-700": !disabled,
    "text-gray-400": disabled,
    "hover:text-blue-400": !disabled,
    "cursor-pointer": !disabled,
  })

  if (help && help.isSuccess && help.data.hasOwnProperty(name)) {
    return (
      <div onClick={handleOpen} className={className || styles.helplabel}>
        <label
          className={labelClasses}
          htmlFor={name}
        >
          {label}
        </label>
        <Help
          showModal={showModal}
          setShowModal={setShowModal}
          title={help.data[name].metadata.title}
          markdown={help.data[name].markdown}
          buttonText={help.data[name].metadata.help_url_text}
          buttonURL={help.data[name].metadata.help_url}
        />
      </div>
    )
  }
  else {
    return (
      <React.Fragment>
        <label
          className={labelClasses}
          htmlFor={name}
        >
          {label}
        </label>
      </React.Fragment>
    );
  }

};

HelpLabel.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

export default HelpLabel;
