import produce from 'immer';
import moment from 'moment';

import { arrayToObject } from '../common/utils';
import {
  getAssignments, createAssignment, RESET_ASSIGNMENT_CREATED, getAssignment,
  updateAssignment, createMultipleAssignments, deleteAssignment, transitionToSubmission,
  transitionToReview, transitionToReviewFeedback, transitionToCompleteAndSkipFeedback,
  transitionToComplete
} from '../actions/assignments';

const dateNow = moment.utc().local().format('YYYY-MM-DD HH:mm');

const initialState = {
  assignments: {},
  assignmentDetail: {
    body: '<p></p>',
    body_plaintext: '',
    title: '',
    description: '<p></p>',
    description_plaintext: '',
    publish_date: dateNow,
    due_date: dateNow,
    review_due_date: dateNow,
    review_feedback_due_date: dateNow,
    submission_rubric: [],
    review_rubric: [],
  },
  loading: false,
  assignmentCreated: false,
};

const assignments = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    // ---------------------------------------------- GET_ASSIGNMENTS
    case getAssignments.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case getAssignments.SUCCESS: {
      return {
        ...state,
        loading: false,
        assignments: arrayToObject(action.payload.results),
      };
    }
    case getAssignments.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    // ---------------------------------------------- GET_ASSIGNMENT
    case getAssignment.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case getAssignment.SUCCESS: {
      return {
        ...state,
        loading: false,
        assignmentDetail: action.payload,
      };
    }
    case getAssignment.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    // ---------------------------------------------- CREATE_ASSIGNMENT
    case createAssignment.REQUEST: {
      return {
        ...state,
        loading: true,
        assignmentCreated: false,
      };
    }
    case createAssignment.SUCCESS: {
      const stateDraft = draft;
      const assignment = action.payload;
      stateDraft.assignments[assignment.id] = assignment;
      stateDraft.assignmentDetail = assignment;
      stateDraft.loading = false;
      stateDraft.assignmentCreated = true;
      break;
    }
    case createAssignment.FAILURE: {
      return {
        ...state,
        loading: false,
        assignmentCreated: false,
      };
    }
    // ---------------------------------------------- RESET_ASSIGNMENT_CREATED
    case RESET_ASSIGNMENT_CREATED: {
      return {
        ...state,
        loading: false,
        assignmentDetail: initialState.assignmentDetail,
        assignmentCreated: false,
      };
    }
    // ---------------------------------------------- UPDATE_ASSIGNMENT
    case updateAssignment.REQUEST: {
      return {
        ...state,
        loading: true,
        assignmentCreated: false,
      };
    }
    case updateAssignment.SUCCESS: {
      const stateDraft = draft;
      const assignment = action.payload;
      stateDraft.assignments[assignment.id] = assignment;
      stateDraft.assignment = assignment;
      stateDraft.loading = false;
      stateDraft.assignmentCreated = true;
      break;
    }
    case updateAssignment.FAILURE: {
      return {
        ...state,
        loading: false,
        assignmentCreated: false,
      };
    }
    // ---------------------------------------------- CREATE_MULTIPLE_ASSIGNMENTS
    case createMultipleAssignments.REQUEST: {
      return {
        ...state,
        loading: true,
        assignmentCreated: false,
      };
    }
    case createMultipleAssignments.SUCCESS: {
      const stateDraft = draft;
      action.payload.forEach((assignment) => {
        stateDraft.assignments[assignment.id] = assignment;
      });
      stateDraft.loading = false;
      stateDraft.assignmentCreated = true;
      break;
    }
    case createMultipleAssignments.FAILURE: {
      return {
        ...state,
        loading: false,
        assignmentCreated: false,
      };
    }
    // ---------------------------------------------- DELETE ASSIGNMENT
    case deleteAssignment.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case deleteAssignment.SUCCESS: {
      const stateDraft = draft;
      const assignment = action.payload;
      stateDraft.assignments[assignment.id] = assignment;
      stateDraft.loading = false;
      break;
    }
    case deleteAssignment.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    // ---------------------------------------------- TRANSITION_TO_SUBMISSION
    case transitionToSubmission.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case transitionToSubmission.SUCCESS: {
      const stateDraft = draft;
      const assignment = action.payload;
      stateDraft.assignments[assignment.id] = assignment;
      stateDraft.loading = false;
      break;
    }
    case transitionToSubmission.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    // ---------------------------------------------- TRANSITION_TO_REVIEW
    case transitionToReview.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case transitionToReview.SUCCESS: {
      const stateDraft = draft;
      const assignment = action.payload;
      stateDraft.assignments[assignment.id] = assignment;
      stateDraft.loading = false;
      break;
    }
    case transitionToReview.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    // ---------------------------------------------- TRANSITION_TO_REVIEW_FEEDBACK
    case transitionToReviewFeedback.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case transitionToReviewFeedback.SUCCESS: {
      const stateDraft = draft;
      const assignment = action.payload;
      stateDraft.assignments[assignment.id] = assignment;
      stateDraft.loading = false;
      break;
    }
    case transitionToReviewFeedback.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    // ---------------------------------------------- TRANSITION_TO_COMPLETE_AND_SKIP_FEEDBACK
    case transitionToCompleteAndSkipFeedback.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case transitionToCompleteAndSkipFeedback.SUCCESS: {
      const stateDraft = draft;
      const assignment = action.payload;
      stateDraft.assignments[assignment.id] = assignment;
      stateDraft.loading = false;
      break;
    }
    case transitionToCompleteAndSkipFeedback.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    // ---------------------------------------------- TRANSITION_TO_COMPLETE
    case transitionToComplete.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case transitionToComplete.SUCCESS: {
      const stateDraft = draft;
      const assignment = action.payload;
      stateDraft.assignments[assignment.id] = assignment;
      stateDraft.loading = false;
      break;
    }
    case transitionToComplete.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
});

export default assignments;
