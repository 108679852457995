import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import styles from './submission-list-item.scss';

const SubmissionListItem = (props) => {
  const {
    title, subtitle, url, id
  } = props;

  const history = useHistory();

  const handleClick = () => {
    history.push(`/${url}/${id}`);
  };

  return (
    <div
      className={styles.container}
      onClick={handleClick}
    >
      <div className={styles.item}>
        <div className={styles.content}>
          <div className={styles.title}>{title}</div>
          <div className={styles.subtitle}>{subtitle}</div>
        </div>
      </div>
    </div>
  );
};

SubmissionListItem.propTypes = {
  imageURL: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SubmissionListItem.defaultProps = {
  imageURL: '',
  title: '',
  subtitle: '',
};

export default SubmissionListItem;
