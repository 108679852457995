import { connect } from 'react-redux';

import { toggleFeedbackJoyride } from '../../../actions/ui';
import {
  createOrUpdateReviewFeedback, resetReviewFeedbackCreated
} from '../../../actions/review-feedbacks';
import { formApiAction } from '../../../actions/api';

import ReviewFeedbackCreate from './review-feedback-create';

const mapStateToProps = (state) => ({
  reviewFeedbackCreated: state.reviewFeedbacks.reviewFeedbackCreated,
});

const mapDispatchToProps = (dispatch) => {
  const formCreateOrUpdateReviewFeedback = formApiAction(createOrUpdateReviewFeedback);
  return {
    resetReviewFeedbackCreated: () => dispatch(resetReviewFeedbackCreated()),
    toggleFeedbackJoyride: () => dispatch(toggleFeedbackJoyride()),
    createOrUpdateReviewFeedback: (formData, setError) => dispatch(
      formCreateOrUpdateReviewFeedback(formData, setError)
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewFeedbackCreate);
