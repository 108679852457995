// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".button__button___wDjWB{\n  --bg-opacity:1;\n  background-color:#4299e1;\n  background-color:rgba(66, 153, 225, var(--bg-opacity));\n  --text-opacity:1;\n  color:#fff;\n  color:rgba(255, 255, 255, var(--text-opacity));\n  padding-top:0.5rem;\n  padding-bottom:0.5rem;\n  padding-left:1rem;\n  padding-right:1rem;\n  border-radius:0.25rem;\n  transition:all .5s cubic-bezier(0.25, 0.1, 0.25, 1)\n}\n\n.button__button___wDjWB:hover{\n  --bg-opacity:1;\n  background-color:#2b6cb0;\n  background-color:rgba(43, 108, 176, var(--bg-opacity))\n}\n\n.button__button___wDjWB:focus{\n  outline:2px solid transparent;\n  outline-offset:2px;\n  box-shadow:0 0 0 3px rgba(66, 153, 225, 0.5)\n}", "",{"version":3,"sources":["button.scss","<no source>"],"names":[],"mappings":"AAAA;ECAA,eAAA;EAAA,yBAAA;EAAA,uDAAA;EAAA,iBAAA;EAAA,WAAA;EAAA,+CAAA;EAAA,mBAAA;EAAA,sBAAA;EAAA,kBAAA;EAAA,mBAAA;EAAA,sBAAA;EDAwD;AAAmD;;AAAC;ECA5G,eAAA;EAAA,yBAAA;EAAA;ADA4I;;AAAC;ECA7I,8BAAA;EAAA,mBAAA;EAAA;ADA6L","file":"button.scss","sourcesContent":[".button{@apply bg-blue-500 text-white py-2 px-4 rounded;transition:all .5s cubic-bezier(0.25, 0.1, 0.25, 1)}.button:hover{@apply bg-blue-700}.button:focus{@apply outline-none shadow-outline}",null]}]);
// Exports
exports.locals = {
	"button": "button__button___wDjWB"
};
module.exports = exports;
