import Cookies from 'js-cookie';
import { useQuery } from 'react-query';


export const getTodos = async () => {
  const response = await fetch("/api/student/todo-list/", {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
  });
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
}

export const getReviewTodo = (id) => {
  const result = useQuery(['review-todos', id], () => getReviewTodoDetail(id));
  return result;
};

export const getReviewTodoDetail = async (id) => {
  const response = await fetch("/api/student/todo-details/", {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
    body: JSON.stringify({ review_id: id }),
  });
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
}

export const getFeedbackTodo = (id) => {
  const result = useQuery(['feedback-todos', id], () => getFeedbackTodoDetail(id));
  return result;
};

export const getFeedbackTodoDetail = async (id) => {
  const response = await fetch("/api/student/todo-details/", {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
    body: JSON.stringify({ review_feedback_id: id }),
  });
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
}