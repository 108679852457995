// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".help__help___35CFm{\n  cursor:pointer;\n  margin-left:0.25rem;\n  display:inline-block\n}\n\n.help__graysvg___2RX3V path{\n  fill:#475569\n}\n\n.help__graysvg___2RX3V path:hover{\n  fill:#3b82f6\n}\n\n.help__markdown____B7YV ol{\n  list-style-type:decimal;\n  list-style-position:inside;\n  margin-top:0.5rem;\n  margin-bottom:0.5rem\n}\n\n.help__markdown____B7YV ul{\n  list-style-type:disc;\n  list-style-position:inside;\n  margin-top:0.5rem;\n  margin-bottom:0.5rem\n}\n\n.help__markdown____B7YV p{\n  margin-top:0.5rem;\n  margin-bottom:0.5rem\n}\n\n.help__markdown____B7YV h2{\n  font-size:1.25rem;\n  font-weight:700;\n  margin-top:1rem;\n  margin-bottom:1rem\n}\n\n.help__markdown____B7YV h3{\n  font-weight:700;\n  margin-top:0.5rem;\n  margin-bottom:0.5rem\n}", "",{"version":3,"sources":["help.scss","<no source>"],"names":[],"mappings":"AAAA;ECAA,eAAA;EAAA,oBAAA;EAAA;ADA6C;;AAAC;EAAc;AAAY;;AAAC;EAAoB;AAAY;;AAAC;ECA1G,wBAAA;EAAA,2BAAA;EAAA,kBAAA;EAAA;ADA2J;;AAAC;ECA5J,qBAAA;EAAA,2BAAA;EAAA,kBAAA;EAAA;ADA0M;;AAAC;ECA3M,kBAAA;EAAA;ADAkO;;AAAC;ECAnO,kBAAA;EAAA,gBAAA;EAAA,gBAAA;EAAA;ADA6Q;;AAAC;ECA9Q,gBAAA;EAAA,kBAAA;EAAA;ADAgT","file":"help.scss","sourcesContent":[".help{@apply cursor-pointer ml-1 inline-block}.graysvg path{fill:#475569}.graysvg path:hover{fill:#3b82f6}.markdown ol{@apply list-decimal list-inside my-2}.markdown ul{@apply list-disc list-inside my-2}.markdown p{@apply my-2}.markdown h2{@apply text-xl font-bold my-4}.markdown h3{@apply font-bold my-2}",null]}]);
// Exports
exports.locals = {
	"help": "help__help___35CFm",
	"graysvg": "help__graysvg___2RX3V",
	"markdown": "help__markdown____B7YV"
};
module.exports = exports;
