import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, NavLink, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getMe, userQuerySettings } from '../../../queries/user';
import { getCourse, getCourseDefaults } from '../../../queries/admin-queries';
import ProtectedRoute from '../../../routes/protected-route';
import Navigation from '../../../components/navigation/navigation';
import ScrollArea from '../../../components/scroll-area/scroll-area';
import Footer from '../../../components/footer/footer';
import Spinner from '../../../components/spinner/spinner';

import CourseDetailOverview from './course-detail-overview';
import CourseDetailSections from './course-detail-sections';
import CourseDetailAssignments from './course-detail-assignments';
import CourseDetailSubmissions from './course-detail-submissions';

import styles from './course-detail.scss';


const CourseDetail = (props) => {
  const {
    match, getSectionsForCourse, getAssignments, getSubmissions,
    loading
  } = props;
  const { path } = match;
  const { id } = useParams();
  const course = useQuery(['admin-course', id], () => getCourse(id), getCourseDefaults);
  const user = useQuery(['me'], getMe, userQuerySettings);

  useEffect(() => {
    getSectionsForCourse(id);
    getAssignments(`?section__course=${id}`);
    getSubmissions(`?assignment__section__course=${id}`);
  }, []);


  if (loading || course.isLoading || user.isLoading) {
    return <Spinner />;
  }

  return (
    <div className={styles.container}>
      <Navigation
        backURL="/dashboard"
        title={course.data.name}
        hasBackButton
      />
      <ScrollArea>
        <div className={styles.tabsContainer}>
          <div
            className={classNames(
              'container',
              styles.tabs,
            )}
          >
            <NavLink
              exact
              className={styles.tabLink}
              activeClassName={styles.tabLinkActive}
              to={`/courses/${id}`}
            >
              Course Overview
            </NavLink>
            <NavLink
              exact
              className={styles.tabLink}
              activeClassName={styles.tabLinkActive}
              to={`/courses/${id}/sections`}
            >
              Sections
            </NavLink>
            <NavLink
              exact
              className={styles.tabLink}
              activeClassName={styles.tabLinkActive}
              to={`/courses/${id}/assignments`}
            >
              All Assignments
            </NavLink>
            <NavLink
              exact
              className={styles.tabLink}
              activeClassName={styles.tabLinkActive}
              to={`/courses/${id}/submissions`}
            >
              All Submissions
            </NavLink>
            {/*
            <NavLink
              exact
              className={styles.tabLink}
              activeClassName={styles.tabLinkActive}
              to={`/help/pedagogy/`}
            >
              Pedagogy
            </NavLink>*/}
          </div>
        </div>
        <div className={styles.switch}>
          <Switch>
            <ProtectedRoute exact path={`${path}`} component={CourseDetailOverview} />
            <ProtectedRoute path={`${path}/sections`} component={CourseDetailSections} />
            <ProtectedRoute path={`${path}/assignments`} component={CourseDetailAssignments} />
            <ProtectedRoute path={`${path}/submissions`} component={CourseDetailSubmissions} />
          </Switch>
        </div>
        <Footer />
      </ScrollArea>
    </div>
  );
};

CourseDetail.propTypes = {
  history: PropTypes.shape().isRequired,
  getCourse: PropTypes.func.isRequired,
  getAssignments: PropTypes.func.isRequired,
  getSectionsForCourse: PropTypes.func.isRequired,
  getSubmissions: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default CourseDetail;
