import { connect } from 'react-redux';


import { changePassword, resetPasswordChanged } from '../../../actions/auth';
import { formApiAction } from '../../../actions/api';

import Settings from './settings';


const mapStateToProps = (state) => ({
  user: state.auth.user,
  passwordUpdated: state.auth.passwordUpdated,

});

const mapDispatchToProps = (dispatch) => {
  const formChangePassword = formApiAction(changePassword);
  return {
    changePassword: (formData, setError) => dispatch(
      formChangePassword(formData, setError)
    ),
    resetPasswordChanged: () => dispatch(resetPasswordChanged()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
