import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Switch, NavLink, useParams
} from 'react-router-dom';

import ProtectedRoute from '../../../routes/protected-route';
import Navigation from '../../../components/navigation/navigation';
import ScrollArea from '../../../components/scroll-area/scroll-area';
import Footer from '../../../components/footer/footer';
import Spinner from '../../../components/spinner/spinner';

import SectionDetailOverview from './section-detail-overview';
import SectionDetailMembers from './section-detail-participants';
import SectionDetailAssignments from './section-detail-assignments';
import SectionDetailSubmissions from './section-detail-submissions';

import styles from './section-detail.scss';

const SectionDetail = (props) => {
  const {
    match, sectionDetail, getSectionDetail, getSectionMembers,
    getAssignments, getSubmissions, loading,
  } = props;

  const { id } = useParams();
  const { path } = match;

  useEffect(() => {
    getSectionDetail();
    getSectionMembers();
    getAssignments(`?section=${id}`);
    getSubmissions(`?assignment__section=${id}`);
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className={styles.container}>
      <Navigation
        backURL={`/courses/${sectionDetail.course}`}
        title={sectionDetail.course_name}
        subtitle={sectionDetail.name}
        hasBackButton
      />
      <ScrollArea>
        <div className={styles.tabsContainer}>
          <div
            className={classNames(
              'container',
              styles.tabs,
            )}
          >
            <NavLink
              exact
              className={styles.tabLink}
              activeClassName={styles.tabLinkActive}
              to={`/sections/${id}`}
            >
              Section Overview
            </NavLink>
            <NavLink
              exact
              className={styles.tabLink}
              activeClassName={styles.tabLinkActive}
              to={`/sections/${id}/participants`}
            >
              Participants
            </NavLink>
            <NavLink
              exact
              className={styles.tabLink}
              activeClassName={styles.tabLinkActive}
              to={`/sections/${id}/assignments`}
            >
              Assignments
            </NavLink>
            <NavLink
              exact
              className={styles.tabLink}
              activeClassName={styles.tabLinkActive}
              to={`/sections/${id}/submissions`}
            >
              Submissions
            </NavLink>
          </div>
        </div>
        <Switch>
          <ProtectedRoute exact path={`${path}`} component={SectionDetailOverview} />
          <ProtectedRoute path={`${path}/participants`} component={SectionDetailMembers} />
          <ProtectedRoute path={`${path}/assignments`} component={SectionDetailAssignments} />
          <ProtectedRoute path={`${path}/submissions`} component={SectionDetailSubmissions} />
        </Switch>
        <Footer />
      </ScrollArea>
    </div>
  );
};

SectionDetail.propTypes = {
  match: PropTypes.shape().isRequired,
  sectionDetail: PropTypes.shape().isRequired,
  getSectionDetail: PropTypes.func.isRequired,
  getSectionMembers: PropTypes.func.isRequired,
  getAssignments: PropTypes.func.isRequired,
  getSubmissions: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SectionDetail;
