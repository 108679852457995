import produce from 'immer';
import { arrayToObject } from '../common/utils';
import {
  getSubmissions, getSubmission, RESET_SUBMISSION_CREATED,
  updateSubmissionBody, createOrUpdateSubmission, getAssignmentSubmission,
  CLEAR_CURRENT_SUBMISSION, getAssignmentSubmissions, getUserSubmissions
} from '../actions/submissions';


const initialState = {
  submissions: {},
  submissionDetail: {},
  loading: false,
  currentSubmission: {
    body: '',
    plainText: ''
  },
  submissionCreated: false,
};

const submissions = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case CLEAR_CURRENT_SUBMISSION: {
      return {
        ...state,
        currentSubmission: {
          body: '',
          plainText: '',
        }
      };
    }
    case getSubmissions.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case getSubmissions.SUCCESS: {
      return {
        ...state,
        loading: false,
        submissions: arrayToObject(action.payload.results),
      };
    }
    case getSubmissions.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case getSubmission.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case getSubmission.SUCCESS: {
      return {
        ...state,
        loading: false,
        currentSubmission: action.payload,
      };
    }
    case getSubmission.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case RESET_SUBMISSION_CREATED: {
      return {
        ...state,
        loading: false,
        submissionDetail: {},
        submissionCreated: false,
      };
    }
    case updateSubmissionBody.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case updateSubmissionBody.SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case updateSubmissionBody.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case createOrUpdateSubmission.REQUEST: {
      return {
        ...state,
        loading: true,
        submissionCreated: false,
      };
    }
    case createOrUpdateSubmission.SUCCESS: {
      return {
        ...state,
        loading: false,
        submissionCreated: true,
      };
    }
    case createOrUpdateSubmission.FAILURE: {
      return {
        ...state,
        loading: false,
        submissionCreated: false,
      };
    }
    case getAssignmentSubmission.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case getAssignmentSubmission.SUCCESS: {
      return {
        ...state,
        loading: false,
        currentSubmission: action.payload,
      };
    }
    case getAssignmentSubmission.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case getAssignmentSubmissions.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case getAssignmentSubmissions.SUCCESS: {
      return {
        ...state,
        loading: false,
        submissions: arrayToObject(action.payload.results),
      };
    }
    case getAssignmentSubmissions.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case getUserSubmissions.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case getUserSubmissions.SUCCESS: {
      return {
        ...state,
        loading: false,
        submissions: arrayToObject(action.payload.results),
      };
    }
    case getUserSubmissions.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
});

export default submissions;
