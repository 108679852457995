// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".submission-detail__container___1vNWo{\n  position:absolute;\n  width:100%;\n  height:100vh;\n  top:0px;\n  left:0px;\n  overflow:hidden;\n  box-shadow:0px 0px 31px 0px rgba(0,0,0,.75)\n}\n\n.submission-detail__editor___2fAcf{\n  height:calc(100vh - 68px)\n}\n\n.submission-detail__actions___2YZnI{\n  position:absolute;\n  bottom:0;\n  right:0;\n  left:0;\n  padding:1rem;\n  display:flex;\n  flex-direction:row;\n  align-items:center;\n  background-color:#fff;\n  z-index:999;\n  box-shadow:0 1px 30px 1px rgba(60,64,67,.15)\n}", "",{"version":3,"sources":["submission-detail.scss"],"names":[],"mappings":"AAAA;EAAW,iBAAiB;EAAC,UAAU;EAAC,YAAY;EAAC,OAAO;EAAC,QAAQ;EAAC,eAAe;EAAC;AAA2C;;AAAC;EAAQ;AAAyB;;AAAC;EAAS,iBAAiB;EAAC,QAAQ;EAAC,OAAO;EAAC,MAAM;EAAC,YAAY;EAAC,YAAY;EAAC,kBAAkB;EAAC,kBAAkB;EAAC,qBAAqB;EAAC,WAAW;EAAC;AAA4C","file":"submission-detail.scss","sourcesContent":[".container{position:absolute;width:100%;height:100vh;top:0px;left:0px;overflow:hidden;box-shadow:0px 0px 31px 0px rgba(0,0,0,.75)}.editor{height:calc(100vh - 68px)}.actions{position:absolute;bottom:0;right:0;left:0;padding:1rem;display:flex;flex-direction:row;align-items:center;background-color:#fff;z-index:999;box-shadow:0 1px 30px 1px rgba(60,64,67,.15)}"]}]);
// Exports
exports.locals = {
	"container": "submission-detail__container___1vNWo",
	"editor": "submission-detail__editor___2fAcf",
	"actions": "submission-detail__actions___2YZnI"
};
module.exports = exports;
