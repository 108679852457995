import { createApiAction, API_URL } from './api';


export const getCourses = createApiAction('GET_COURSES', () => ({
  endpoint: `${API_URL}/api/admin/courses/?ordering=-created`,
  method: 'GET',
}));

export const createCourse = createApiAction('CREATE_COURSE', (formData) => ({
  endpoint: `${API_URL}/api/admin/courses/`,
  method: 'POST',
  body: JSON.stringify(formData),
}));

export const RESET_COURSE_CREATED = 'RESET_COURSE_CREATED';

export const resetCourseCreated = () => {
  return {
    type: RESET_COURSE_CREATED,
  };
};

export const getCourse = createApiAction('GET_COURSE', (courseID) => ({
  endpoint: `${API_URL}/api/admin/courses/${courseID}/`,
  method: 'GET',
}));

export const updateCourse = createApiAction('UPDATE_COURSE', (formData, setError, courseID) => {
  return {
    endpoint: `${API_URL}/api/admin/courses/${courseID}/`,
    method: 'PATCH',
    body: JSON.stringify(formData),
  };
});
