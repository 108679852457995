export const TOGGLE_SUBMISSION_JOY_RIDE = 'TOGGLE_SUBMISSION_JOY_RIDE';

export const toggleSubmissionJoyride = () => {
  return {
    type: TOGGLE_SUBMISSION_JOY_RIDE,
  };
};

export const TOGGLE_REVIEW_JOY_RIDE = 'TOGGLE_REVIEW_JOY_RIDE';

export const toggleReviewJoyride = () => {
  return {
    type: TOGGLE_REVIEW_JOY_RIDE,
  };
};

export const TOGGLE_FEEDBACK_JOY_RIDE = 'TOGGLE_FEEDBACK_JOY_RIDE';

export const toggleFeedbackJoyride = () => {
  return {
    type: TOGGLE_FEEDBACK_JOY_RIDE,
  };
};