import { createApiAction, API_URL } from './api';


export const getSections = createApiAction('GET_SECTIONS', () => ({
  endpoint: `${API_URL}/api/admin/sections/`,
  method: 'GET',
}));

export const getSectionDetail = createApiAction('GET_SECTION_DETAIL', (sectionID) => ({
  endpoint: `${API_URL}/api/admin/sections/${sectionID}/`,
  method: 'GET',
}));

export const getSectionsForCourse = createApiAction('GET_SECTIONS_FOR_COURSE', (courseID) => ({
  endpoint: `/api/admin/sections/?course=${courseID}`,
  method: 'GET',
}));

export const createSection = createApiAction('CREATE_SECTION', (formData) => ({
  endpoint: `${API_URL}/api/admin/sections/`,
  method: 'POST',
  body: JSON.stringify(formData),
}));

export const RESET_SECTION_CREATED = 'RESET_SECTION_CREATED';

export const resetSectionCreated = () => ({
  type: RESET_SECTION_CREATED,
});

export const getSection = createApiAction('GET_SECTION', (sectionID) => ({
  endpoint: `${API_URL}/api/admin/sections/${sectionID}/`,
  method: 'GET',
}));

export const updateSection = createApiAction('UPDATE_SECTION', (formData, setError, sectionID) => {
  return {
    endpoint: `${API_URL}/api/admin/sections/${sectionID}/`,
    method: 'PATCH',
    body: JSON.stringify(formData),
  };
});

export const getSectionMembers = createApiAction('GET_SECTION_MEMBERS', (sectionID) => ({
  endpoint: `${API_URL}/api/admin/sections/${sectionID}/members/`,
  method: 'GET',
}));

export const createSectionParticipants = createApiAction('CREATE_SECTION_PARTICIPANTS', (formData) => ({
  endpoint: `${API_URL}/api/admin/user_upload/`,
  method: 'POST',
  body: JSON.stringify(formData),
}));

export const RESET_PARTICIPANTS_CREATED = 'RESET_PARTICIPANTS_CREATED';

export const resetParticipantsCreated = () => ({
  type: RESET_PARTICIPANTS_CREATED,
});

export const createSingleSectionParticipant = createApiAction('CREATE_SINGLE_SECTION_PARTICIPANT', (formData) => ({
  endpoint: `${API_URL}/api/admin/user_add_to_section/`,
  method: 'POST',
  body: JSON.stringify(formData),
}));
