import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import { loginUser } from '../../actions/auth';
import { formApiAction } from '../../actions/api';
import Login from './login';

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
  loginUser: formApiAction(loginUser),
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
