import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import Editor from '../../../components/editor/editor';
import Input from '../../../components/input/input';
import HelpLabel from '../../../components/help-label';
import StepSection from '../../../components/step-section/step-section';
import LeaveWarning from '../../../components/leave-warning/leave-warning';
import { getMe, userQuerySettings } from '../../../queries/user';
import styles from './assignment-edit.scss';

const Step1 = (props) => {
  const {
    setAssignmentDraft, goToNextStep, assignmentDraft, setCanClose, help
  } = props;
  const {
    register, handleSubmit, setValue, formState: { errors, isDirty },
  } = useForm();
  const user = useQuery('me', getMe, userQuerySettings);
  const [showDirtyMessage, setShowDirtyMessage] = useState(false);

  const [editorDraftState, setEditorDraftState] = useState({
    body: assignmentDraft.description,
    plain_text: assignmentDraft.description_plaintext,
  });

  useEffect(() => {
    setValue('title', assignmentDraft.title);
    setEditorDraftState({
      body: assignmentDraft.description,
      plain_text: assignmentDraft.description_plaintext,
    });
  }, [assignmentDraft]);

  // Track react-hook-form and editor dirty state
  useEffect(() => {
    let editorDirty = assignmentDraft.description != editorDraftState.body;
    if (isDirty || editorDirty) {
      setShowDirtyMessage(true);
      setCanClose(false);
    } else {
      setShowDirtyMessage(false);
      setCanClose(true);
    }
  }, [isDirty, editorDraftState]);

  const onSubmit = (data) => {
    setAssignmentDraft({
      ...assignmentDraft,
      title: data.title,
      description: editorDraftState.body,
      description_plaintext: editorDraftState.plain_text,
    });
    goToNextStep();
  };

  const handleScroll = (e) => {
    const node = document.getElementsByClassName('rdw-editor-toolbar')[0];
    if (e.target.scrollTop > 162) {
      node.classList.add('stickyToolbar');
    } else {
      node.classList.remove('stickyToolbar');
    }
  };

  if (user.isLoading) {
    return (<Loading />)
  }
  // Give special message to HBP demo users
  let hbpDemoHint = null;
  if (user.data.is_hbp && user.data.is_demo) {
    hbpDemoHint = (
      <div className="m-6 bg-green-100 text-lg border border-green-400 p-6">
        <p className="my-2"><strong>Demo Users:</strong> Click Save & Continue to advance through the demo assignment</p>
        <p className="my-2">When you click Save on the final tab, you'll be taken to the Summary page</p>
        <p className="my-2">On the Summary Page choose <strong>Reports</strong> to see all metrics, including optional results from ChatGPT.</p>
      </div>

    )
  }
  return (
    <React.Fragment>
      <LeaveWarning when={showDirtyMessage} />
      <form
        className={styles.step}
        id="step-form"
        onSubmit={handleSubmit(onSubmit)}
        onScroll={handleScroll}
      >
        {hbpDemoHint}
        <div className="m-6 text-lg bg-blue-100 border border-blue-400 p-4">
          <HelpLabel label="Background Information for instructors" name="step_1_overview" help={help} />
        </div>
        <div className="m-6 bg-blue-100 text-lg border border-blue-400 p-6">
          <p className="my-2"><strong>Instructors: Begin here</strong> to add your assignment.</p>
          <p className="my-2"><strong>Step 1:</strong> Add Assignment Title. Students see this title</p>
        </div>
        <StepSection noPadding shadow={false}>
          <Input
            label="Assignment Title - Students see this text"
            name="title"
            error={errors.title}
            {...register('title', { required: true })}
          />
        </StepSection>
        <div className="m-6 text-lg bg-blue-100 border border-blue-400 p-4">
          <div className="block text-gray-700 my-2">
            <p className="my-2">
              <strong>Step 2:</strong> Review the text in the white text box below that begins with: "Stage One - Submission".
              &nbsp; <strong>Students see this text when they open Peer Exchange.</strong> You can edit the text or leave it unchanged.
            </p>
            <p className="my-2">Note: "Welcome [Student Name]" automatically becomes the first line of text to greet each student.</p>
          </div>
          <div className="block text-gray-700 my-2">
            <strong>Step 3:</strong> Hit Save &amp; Continue for the next tab.
          </div>
        </div>
        <Editor
          body={assignmentDraft.description}
          handleBodyUpdate={setEditorDraftState}
        />
      </form>
    </React.Fragment>
  );
};

Step1.propTypes = {
  setAssignmentDraft: PropTypes.func.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  assignmentDraft: PropTypes.shape({}).isRequired,
};

Step1.defaultProps = {
};

export default Step1;
