import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { getMe, userQuerySettings } from '../../../queries/user';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import Joyride from 'react-joyride';
import AssignmentNavigation from '../../../components/assignment-navigation/assignment-navigation';
import Editor from '../../../components/editor/editor';
import RubricForm from '../../../components/rubric-form/rubric-form';
import AssignmentSectionTitle from '../../../components/assignment-section-title/assignment-section-title';
import FilePreview from '../../../components/file-preview';

import { getReviewTodo } from '../../../queries/todos';

import styles from './review-create.scss';


const getSteps = (user) => {
  // Return demo steps
  if (user.data.is_demo) {
    return [
      {
        content: <h2>Welcome! On this page you will review Submissions. </h2>,
        placement: 'center',
        target: 'body',
      },
      {
        content: <h2>You can access related documents for this review.</h2>,
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 0,
        target: '.assignment-navigation',
      },
      {
        content: <p>
          You must:
          <br />
          <br />
          Complete all questions for each Review
          <br />
          <br />
          SAVE your work - look for the green banner pop-up to know that your work is saved.
        </p>,
        placement: 'center',
        target: 'body',
      },
    ];

  } else {
    return [
      {
        content: <h2>Welcome! On this page you will review Submissions. </h2>,
        placement: 'center',
        target: 'body',
      },
      {
        content: <h2>You can access related documents for this review.</h2>,
        floaterProps: {
          disableAnimation: true,
        },
        spotlightPadding: 0,
        target: '.assignment-navigation',
      },
      {
        content: <p>
          You must:
          <br />
          <br />
          Complete all questions for each Review
          <br />
          <br />
          SAVE your work - look for the green banner pop-up to know that your work is saved.
          <br />
          <br />
          You cannot save work past the deadline.
        </p>,
        placement: 'center',
        target: 'body',
      },
    ];
  }

}
function ReviewCreate(props) {
  const { id } = useParams();

  const {
    createOrUpdateReview, reviewCreated, toggleReviewJoyride, resetReviewCreated
  } = props;

  const [previewOpen, setPreviewOpen] = useState(false);
  const [formState, setFormState] = useState();
  const queryClient = useQueryClient();
  const enableJoyride = useSelector((state) => state.ui.joyride_review);
  const user = useQuery('me', getMe, userQuerySettings);

  const detail = getReviewTodo(id);

  useEffect(() => {
    setPreviewOpen('submission');

    return () => {
      resetReviewCreated();
    };
  }, [id]);

  const handleJoyRide = (data) => {
    const { action } = data;
    if (action === 'reset' || action === 'skip') {
      toggleReviewJoyride();
    }
  };

  const handleSubmitForm = () => {
    const formData = {
      submission: detail.data.id,
      rubric: formState.rubricFormState,
    };

    createOrUpdateReview(formData, () => { });
    queryClient.removeQueries(['todo-list']);
  };

  useEffect(() => {
    if (reviewCreated) {
      detail.refetch();
      if (!toast.isActive('review-create-save')) {
        toast('Saved', { containerId: 'BOTTOM', className: 'bg-blue-200 text-gray-900', toastId: 'review-create-save' });
      }
    }
  }, [reviewCreated]);

  if (user.isLoading || detail.isLoading) {
    return (
      <div>Loading</div>
    );
  }

  const steps = getSteps(user);
  const NoSubmissionText = () => {
    console.log('noSubmissionText');
    return (
      <div className="my-6 h-56 w-full flex bg-gray-400">
        <div className="m-auto">
          <h3 className="text-2xl text-gray-800">The submission is an attached file above</h3>
        </div>
      </div>
    )
  }

  const renderPreview = () => {
    if (previewOpen === 'assignment') {
      return (
        <div className={styles.previewWrapper}>
          <AssignmentSectionTitle
            title="The Assignment"
            backgroundGray
          />
          <div className={classNames(
            styles.scrollwrapper, styles.backgroundGray
          )}
          >
            <div className={styles.content}>
              <Editor
                body={detail.data.assignment.body}
                readOnly
                toolbarHidden
              />
            </div>
          </div>
        </div>
      );
    }

    if (previewOpen === 'submission') {
      return (
        <div className={styles.previewWrapper}>
          <AssignmentSectionTitle
            title="The Submission"
            backgroundGray
          />
          <div className={styles.fileUploadContainer}>
            <div className={styles.filesPreviewContainer}>
              {detail.data.submission.files.map((file, index) => (
                <FilePreview
                  file={file}
                  key={file.file}
                  readOnly
                />
              ))}
            </div>
          </div>
          <div className={classNames(
            styles.scrollwrapper, styles.backgroundGray
          )}
          >
            <div className={styles.content}>
              {detail.data.submission.body_plaintext && <Editor
                body={detail.data.submission.body}
                readOnly
                toolbarHidden
              />
              }
              {!detail.data.submission.body_plaintext && <NoSubmissionText />}
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  // GLE class ID dba0fdfd-25b0-4f71-aed3-db54f1d648ae
  // if (detail.data.assignment.id !== 'dba0fdfd-25b0-4f71-aed3-db54f1d648ae') {
  //   quantHelp = null;
  // }

  let reviewHeader = 'Your Review';
  if (detail.isSuccess && detail.data.review.is_complete) {
    reviewHeader = 'Your Completed Review';
  }

  try {
    return (
      <React.Fragment>
        <div className={classNames(
          styles.container, {
          'split-view': previewOpen
        }
        )}
        >
          <AssignmentNavigation
            title={detail.data.assignment.title}
            setPreviewOpen={setPreviewOpen}
            previewOpen={previewOpen}
            isDemo={user.data.is_demo}
            showAssignmentButton
            showSubmissionButton
          />
          <div className={styles.sectionWrapper}>
            {renderPreview()}

            <div className={styles.previewWrapper}>
              <AssignmentSectionTitle
                title={reviewHeader}
                handleSave={handleSubmitForm}
                backgroundGreen={detail.data.review.is_complete}
                showActions
              />
              <div className={classNames(
                styles.scrollwrapper,
              )}
              >
                <div className={styles.content}>
                  <div className={classNames(
                    styles.formWrapper, {
                    [styles.splitView]: previewOpen,
                  }
                  )}
                  >
                    <RubricForm
                      data={detail.data.review.rubric}
                      submitForm={handleSubmitForm}
                      setFormState={setFormState}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Joyride
          run={enableJoyride}
          steps={steps}
          continuous
          scrollToFirstStep
          showProgress
          showSkipButton
          callback={handleJoyRide}
          styles={{
            options: {
              zIndex: 10000,
              arrowColor: '#fff',
              backgroundColor: '#fff',
              beaconSize: 36,
              overlayColor: 'rgba(0, 0, 0, 0.5)',
              primaryColor: '#5850ec',
              spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
              textColor: '#333',
              width: undefined,
            },
          }}
        />
      </React.Fragment>
    );
  } catch (e) { console.dir(e); }
}

ReviewCreate.propTypes = {
  createOrUpdateReview: PropTypes.func.isRequired,
  reviewCreated: PropTypes.bool.isRequired,
  toggleReviewJoyride: PropTypes.func.isRequired,
  resetReviewCreated: PropTypes.func.isRequired,
};

ReviewCreate.defaultProps = {
};

export default ReviewCreate;
