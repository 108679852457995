import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import Loading from '../../../components/loading/loading';
import {
  getAssignment, getLeagues,

} from '../../../queries/admin-queries';

const pClasses = "my-4";
const buttonClasses = classNames('rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 w-full cursor-pointer my-1');

const ItemTypes = {
  TEAM: 'team',
  MEMBER: 'member'
}

const DisplayLeague = (props) => {
  const { league } = props
  return (
    <div className="border bg-blue-200 mt-8 p-2">
      <h2 className="text-lg font-bold border-b border-gray-500 mb-2">{league.name}</h2>
      {league.members.map((member, index) => (
        <p key={member.id} className="mb-2">{index + 1}. {member.name} ({member.email})</p>
      ))}
    </div>
  )
}

const AssignmentDetailViewTeams = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const queryClient = useQueryClient();

  const assignment = useQuery(['admin-assignment', id], () => getAssignment(id));
  const leagues = useQuery(['admin-assignment-leagues', id], () => getLeagues(id), { cacheTime: 60000, staleTime: 10000, keepPreviousData: true });

  if (assignment.isLoading || leagues.isLoading) {
    return <Loading message="Loading assignment and teams..." />;
  }

  console.log(leagues.data)
  if (assignment.data.type !== 'team') {
    return (
      <React.Fragment>
        <h1 className="my-4 text-2xl font-bold">ERROR: This is not a Team Assignment</h1>
        <p className={pClasses}>
          Not entirely sure how you ended up here, but this is not a Team Assignment so you cannot
          manage teams for this.
        </p>
      </React.Fragment>
    );
  }


  return (
    <React.Fragment>
      <div className="flex w-full">
        <h1 className="my-4 text-2xl flex-grow font-bold">View Teams</h1>
      </div>
      <hr className="mb-4" />
      <div className="">
        {leagues.data.length == 0 && (
          <p className="p-4"><em>There are currently no Teams for this assignment. Please create some using the white box above.</em></p>
        )}
        {leagues.data.map((league) => (
          <DisplayLeague
            league={league}
            key={league.id}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

export default AssignmentDetailViewTeams;
