import React from 'react';
import PropTypes from 'prop-types';

const Loading = (props) => {
  const { message } = props;
  return (
    <div className="rounded m-4 p-4 bg-blue-300 font-bold">
      <h4>{message}</h4>
    </div>
  );
};

Loading.propTypes = {
  message: PropTypes.string,
};

Loading.defaultProps = {
  message: "Loading..."
};

export default Loading;