import React, { useState } from 'react';
import classNames from 'classnames';

import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import Loading from '../../../components/loading/loading';
import { getAssignment, getLeagues, removeTeamMember, deleteTeam } from '../../../queries/admin-queries';

const pClasses = "my-4";
const buttonClasses = classNames('rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 w-full cursor-pointer my-1');

const ItemTypes = {
  TEAM: 'team',
  MEMBER: 'member'
}

const AssignmentDetailEditTeam = (props) => {
  const { id, leagueID } = useParams();
  const history = useHistory();
  const queryClient = useQueryClient();
  const assignment = useQuery(['admin-assignment', id], () => getAssignment(id));
  const leagues = useQuery(['admin-assignment-leagues', id], () => getLeagues(id), { cacheTime: 60000, staleTime: 10000, keepPreviousData: true });
  console.log(`Assignment ID: ${id} League ID: ${leagueID}`);

  const mutation = useMutation(removeTeamMember,
    {
      onSuccess: (data, values) => {
        queryClient.invalidateQueries(['admin-assignment-leagues', id]);
        queryClient.invalidateQueries(['admin-assignment-no-team', id]);
      }
    }
  );

  const deleteMutation = useMutation(deleteTeam,
    {
      onSuccess: (data, values) => {
        queryClient.invalidateQueries(['admin-assignment-leagues', id]);
        queryClient.invalidateQueries(['admin-assignment-no-team', id]);
        const url = `/assignments/${id}/teams`;
        console.log("Going to: " + url);
        history.push(url)
      }
    }
  );

  if (assignment.isLoading || leagues.isLoading) {
    return <Loading />;
  }

  let league = null
  leagues.data.map((l) => {
    if (l.id == leagueID) {
      league = l
    }
  })


  const removeStudent = (memberID) => {
    mutation.mutate({ leagueID: leagueID, sectionMemberID: memberID })
  }

  const removeTeam = (leagueID) => {
    deleteMutation.mutate(leagueID)
  }

  const buttonClasses = classNames('rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 w-full cursor-pointer my-1');
  const smallButtonClasses = classNames('rounded py-1 px-2 text-base text-white bg-blue-500 hover:bg-blue-700 cursor-pointer');
  const deleteButtonClasses = classNames('rounded py-1 px-2 text-base text-white bg-red-500 hover:bg-red-700 cursor-pointer');

  let editing = true;
  if (assignment.data.state !== 'pending') {
    editing = false;
  }

  return (
    <React.Fragment>
      <div className="flex w-full">
        <h1 className="my-4 text-2xl flex-grow font-bold">Manage Team: {league.name} </h1>
        <div className="flex-initial">
          <button className={buttonClasses} onClick={() => history.goBack()}>Back to Teams</button>
        </div>
      </div>
      <hr className="mb-4" />
      <div className="flex flex-col w-full">
        {league.members.map((member) => (
          <div className="border mb-2 p-4 flex bg-blue-200 items-center" key={`members-${member.id}`}>
            <span className="flex-grow">{member.name}</span>
            {editing && (
              <button className={smallButtonClasses} onClick={() => removeStudent(member.id)}>Remove from Team</button>
            )}
          </div>
        ))}
      </div>
      {editing && (
        <div className="my-16 border border-2 border-red-500 bg-red-200 p-4 flex flex-inline justify-between align-center">
          <span className="mt-1">Deleting a team returns all current team members back to the pool to be re-assigned.</span>
          <button className={deleteButtonClasses} onClick={() => removeTeam(league.id)}>Delete Team</button>
        </div>
      )}
    </React.Fragment>
  );
};

export default AssignmentDetailEditTeam;
