import React from 'react';

const Checkmark = (props) => {
  return (
    <div className="text-green-700 w-6 h-6">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
      </svg>
    </div>
  );
};

export default Checkmark;
