import React, { useState } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getAssignment, getAssignmentDefaults, getAssignmentStudentReport } from '../../../queries/admin-queries';
import Loading from '../../../components/loading/loading';
import Help from '../../../components/help/';
import { getMe, userQuerySettings } from '../../../queries/user';

const Button = (props) => {
  const { text, disabled, onClick } = props;
  const buttonClasses = classNames("inline-flex mr-2 items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500", {
    'bg-green-500': disabled,
    'bg-white': !disabled,
    'hover:bg-blue-100': !disabled,
    'hover:border-blue-300': !disabled,
    'cursor-not-allowed': disabled,
  });
  return (
    <button
      className={buttonClasses}
      disabled={disabled}
      onClick={onClick}>{text}</button>
  )
}

const StageButtons = (props) => {
  const { stage, setStage } = props;


  return (
    <React.Fragment>
      <Button text="Stage 1: Submission - Evaluated in Stage 2" disabled={stage === "review"} onClick={() => setStage("review")} />
      <Button text="Stage 2: Reviews - Evaluated in Stage 3" disabled={stage === "feedback"} onClick={() => setStage("feedback")} />
    </React.Fragment>
  )
}

const BandButtons = (props) => {
  const { bands, setBands } = props;

  return (
    <React.Fragment>
      <Button text="Two Bands" disabled={bands === 2} onClick={() => setBands(2)} />
      <Button text="Three Bands" disabled={bands === 3} onClick={() => setBands(3)} />
      <Button text="Four Bands" disabled={bands === 4} onClick={() => setBands(4)} />
    </React.Fragment>
  )
}

const rowColor = (index, bands) => {
  let bgClass = null;
  let groups = [bands[0]];
  let last = 0;
  let number = bands.length;

  bands.forEach((band, i) => {
    if (i === 0) {
      last = bands[0]
    } else {
      groups.push(band + last)
      last = band + last
    }
  })

  // Two Groups
  if (number === 2) {
    // Top group
    if (0 <= index && index <= groups[0]) {
      bgClass = "bg-green-200";
    }
    else {
      bgClass = "bg-red-200";
    }

  }

  // Three Groups
  if (number === 3) {
    if (0 <= index && index <= groups[0]) {
      bgClass = "bg-green-200";
    } else if (groups[0] < index && index <= groups[1]) {
      bgClass = "bg-yellow-200";
    }
    else {
      bgClass = "bg-red-200";
    }

  }

  // Three Groups
  if (number === 4) {
    if (0 <= index && index <= groups[0]) {
      bgClass = "bg-green-200";
    } else if (groups[0] < index && index <= groups[1]) {
      bgClass = "bg-blue-200";
    } else if (groups[1] < index && index <= groups[2]) {
      bgClass = "bg-yellow-200";
    } else if (groups[2] < index && index <= groups[3]) {
      bgClass = "bg-red-200";
    }
  }

  return bgClass;
}

const StudentRow = (props) => {
  const { student, index, bands, bandChoice, total, debug } = props;
  const bgClass = rowColor(index, bands);
  let mailTo = `mailto:${student.email}`;
  let score = "N/A"

  if (student.average !== null) {
    score = student.average
  }

  if (student.full_participant === false) {
    score = "Did not fully participate"
  }

  let warningText = null;
  if (student.item_count <= 2) {
    let plural = 's'
    if (student.item_count === 1) {
      plural = ''
    }
    warningText = <span className="float-right font-bold text-red-500">(based on {student.item_count} evaluation{plural})</span>
  }
  return (
    <tr key={student.id} className={bgClass}>
      <td>{student.first_name} {student.last_name}</td>
      <td><a href={mailTo}>{student.email}</a></td>
      <td>{score} {warningText}</td>
    </tr>
  )
}


const AssignmentReportsStudents = (props) => {
  const { id } = useParams();
  const [stage, setStage] = useState('review');
  const [bands, setBands] = useState(3);
  const user = useQuery('me', getMe, userQuerySettings);
  const report = useQuery(['admin-assignment-report', id, stage, bands], () => getAssignmentStudentReport(id, stage, bands), { cacheTime: 60000, staleTime: 10000, keepPreviousData: true, retry: 0 });
  const assignment = useQuery(['admin-assignment', id], () => getAssignment(id), getAssignmentDefaults);

  if (user.isLoading || assignment.isLoading) {
    return <Loading />;
  }

  if (assignment.data.state !== 'review_feedback' && assignment.data.state !== 'completed') {
    return (
      <React.Fragment>
        <h1 className="text-2xl font-bold mb-4">Standardized Scores</h1>
        <p className="p-4 mt-8 border bg-blue-100">
          This report is only available in Stage 3 and Completed stages of an Assignment.
        </p>
      </React.Fragment>
    )
  }

  if (report.isLoading) {
    return <Loading />;
  }

  const totalRows = report.data.members.length;
  const isHijacking = user.data.is_hijacked_user;

  return (
    <React.Fragment>
      <h1 className="text-2xl font-bold mb-4">Standardized Scores</h1>

      <p className="my-2">The ranking below presents standardized student-assigned scores indicating class rank. The calculation requires that student work be evaluated by at least 3 classmates.  Select <a className="hover:text-blue-500" href="/help/faculty/standardized-scoring.md" >ⓘ</a> for more information about standardized reports, including the equation that we use. {/*<Help url="https://www.cnn.com" /> */}</p>
      <p className="my-2">Please use this list with caution, the standardized scores reflect only the class rank and cannot be considered as an absolute grading point. You might prefer alternative methods for evaluating student performance. On the Summary page you can download CSV files containing all data for each assignment.</p>
      <p className="my-4">The colored bands are simply visual representations; they separate students into two, three, or four groups.</p>
      <div className="py-6 flex">
        <StageButtons stage={stage} setStage={setStage} />
        <BandButtons bands={bands} setBands={setBands} />
      </div>
      <table>
        <thead>
          <tr>
            <th>Student</th>
            <th>Email</th>
            <th>Standardized Score</th>
          </tr>
        </thead>
        <tbody>
          {report.data.members.map((student, index) => (
            <StudentRow
              key={student.id}
              student={student}
              index={index}
              bands={report.data.bands}
              bandChoice={bands}
              total={totalRows}
              debug={isHijacking}
            />
          ))}
        </tbody>
      </table>

      <h1 className="text-2xl font-bold mb-4 my-6">"N/A" = Unable to Accurately Calculate Scores For These Students</h1>
      <p className="my-2">This can be caused by the student not fully participating in the assignment or if they received a low rate of feedback through no fault of their own.</p>
    </React.Fragment>

  );
};


export default AssignmentReportsStudents;
