import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getAssignment, getAssignmentDefaults, getAssignmentReviews, getAssignmentReviewCounts } from '../../../queries/admin-queries';
import { getMe, userQuerySettings } from '../../../queries/user';
import Loading from '../../../components/loading/loading';
import { FilterTable } from './common';

const AssignmentDetailReviews = (props) => {
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState(null);
  const assignment = useQuery(['admin-assignment', id], () => getAssignment(id), getAssignmentDefaults);
  const reviews = useQuery(['admin-assignment-reviews', id, currentPage, filter], () => getAssignmentReviews(id, currentPage, filter), { cacheTime: 600000, staleTime: 10000, keepPreviousData: true });
  const counts = useQuery(['admin-assignment-reviewcounts', id], () => getAssignmentReviewCounts(id), { cacheTime: 600000, staleTime: 10000 });
  const user = useQuery('me', getMe, userQuerySettings);

  // What to refresh when user clients refresh
  const refreshKeys = [
    "admin-assignment-reviewcounts",
    "admin-assignment-reviews",
  ];

  if (assignment.isLoading || counts.isLoading || reviews.isLoading || user.isLoading) {
    return (<Loading message="Loading assignment..." />);
  }
  if (reviews.isSuccess && reviews.data.length === 0) {
    return (
      <p>There are currently no reviews</p>
    );
  }

  let demoText = null

  if (user.data.is_demo) {
    demoText = (
      <div className="m-6 bg-green-100 text-lg border border-green-400 p-6">
        <p className="my-2">Default setting: Students see only text-based feedback from peers. Instructors can show scores if they choose.</p>
      </div>
    )
  }

  return (
    <React.Fragment>
      {demoText}
      <table className="my-4">
        <thead>
          <tr>
            <th>Complete Reviews</th>
            <th>Incomplete Reviews</th>
            <th>Total Reviews</th>
          </tr>
        </thead>
        <tbody>
          {counts.isLoading && <tr><td colSpan="3">Loading...</td></tr>}
          {counts.isSuccess &&
            <tr>
              <td>{counts.data.complete_reviews}</td>
              <td>{counts.data.incomplete_reviews}</td>
              <td>{counts.data.total_reviews}</td>
            </tr>}
        </tbody>
      </table>
      {reviews.isLoading && <Loading message="Loading reviews..." />}
      {reviews.isSuccess &&
        <FilterTable
          items={reviews.data.results}
          linkPrefix="reviews"
          assignmentID={id}
          refreshKeys={refreshKeys}
          currentPage={currentPage}
          nextPageNum={reviews.data.next_page}
          previousPageNum={reviews.data.previous_page}
          totalPages={reviews.data.total_pages}
          setCurrentPage={setCurrentPage}
          setQueryFilter={setFilter}
        />
      }
    </React.Fragment>
  );
};

AssignmentDetailReviews.propTypes = {
};


export default AssignmentDetailReviews;
