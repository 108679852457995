import { connect } from 'react-redux';

import { getSectionsForCourse } from '../../../actions/sections';
import { getSubmissions, clearCurrentSubmission } from '../../../actions/submissions';
import { getAssignments } from '../../../actions/assignments';
import { coursesSelector } from '../../../selectors/courses';
import { sectionsSelector } from '../../../selectors/sections';
import { assignmentsSelector } from '../../../selectors/assignments';

import CourseDetail from './course-detail';


const mapStateToProps = (state) => ({
  user: state.auth.user,
  sections: sectionsSelector(state.sections.sections),
  assignments: assignmentsSelector(state.assignments.assignments),
  submissions: state.submissions.submissions,
  courses: coursesSelector(state.courses.courses),
  loading: state.courses.loading,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const { match: { params: { id } } } = ownProps;
  return {
    getSectionsForCourse: (courseID) => dispatch(getSectionsForCourse(courseID)),
    getAssignments: (params) => dispatch(getAssignments(params)),
    getSubmissions: (params) => dispatch(getSubmissions(params)),
    clearCurrentSubmission: () => dispatch(clearCurrentSubmission()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetail);
