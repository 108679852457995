import { createApiAction, API_URL } from './api';


export const getAssignments = createApiAction('GET_ASSIGNMENTS', (params = '') => ({
  endpoint: `${API_URL}/api/admin/assignments/${params}`,
  method: 'GET',
}));

export const getAssignment = createApiAction('GET_ASSIGNMENT', (assignmentID) => ({
  endpoint: `${API_URL}/api/admin/assignments/${assignmentID}/`,
  method: 'GET',
}));

export const createAssignment = createApiAction('CREATE_ASSIGNMENT', (formData) => ({
  endpoint: `${API_URL}/api/admin/assignments/`,
  method: 'POST',
  body: JSON.stringify(formData),
}));

export const RESET_ASSIGNMENT_CREATED = 'RESET_ASSIGNMENT_CREATED';

export const resetAssignmentCreated = () => ({
  type: RESET_ASSIGNMENT_CREATED,
});

export const updateAssignment = createApiAction('UPDATE_ASSIGNMENT', (formData, setError, assignmentID) => ({
  endpoint: `${API_URL}/api/admin/assignments/${assignmentID}/`,
  method: 'PATCH',
  body: JSON.stringify(formData),
}));

export const createMultipleAssignments = createApiAction('CREATE_MULTIPLE_ASSIGNMENTS', (formData) => ({
  endpoint: `${API_URL}/api/admin/assignments/bulk_create/`,
  method: 'POST',
  body: JSON.stringify(formData),
}));

export const deleteAssignment = createApiAction('DELETE_ASSIGNMENT', (assignmentID) => ({
  endpoint: `${API_URL}/api/admin/assignments/${assignmentID}/`,
  method: 'DELETE',
}));

export const transitionToSubmission = createApiAction('TRANSITION_TO_SUBMISSION', (assignmentID) => {
  return {
    endpoint: `${API_URL}/api/admin/assignments/${assignmentID}/to_submission_state/`,
    method: 'PATCH',
  };
});

export const transitionToReview = createApiAction('TRANSITION_TO_REVIEW', (assignmentID) => ({
  endpoint: `${API_URL}/api/admin/assignments/${assignmentID}/to_review_state/`,
  method: 'PATCH',
}));

export const transitionToReviewFeedback = createApiAction('TRANSITION_TO_REVIEW_FEEDBACK', (assignmentID) => ({
  endpoint: `${API_URL}/api/admin/assignments/${assignmentID}/to_review_feedback_state/`,
  method: 'PATCH',
}));

export const transitionToCompleteAndSkipFeedback = createApiAction('TRANSITION_TO_COMPLETE_AND_SKIP_FEEDBACK', (assignmentID) => ({
  endpoint: `${API_URL}/api/admin/assignments/${assignmentID}/to_completed_skip_feedback_state/`,
  method: 'PATCH',
}));

export const transitionToComplete = createApiAction('TRANSITION_TO_COMPLETE', (assignmentID) => ({
  endpoint: `${API_URL}/api/admin/assignments/${assignmentID}/to_completed_state/`,
  method: 'PATCH',
}));
