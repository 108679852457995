import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';

import Modal from '../../../components/modal/modal';
import FileUpload from '../../../components/file-upload/file-upload';


const ParticipantsCreate = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const {
    createSectionParticipants, resetParticipantsCreated, participantsCreated
  } = props;
  const [binaryString, setBinaryString] = useState();
  const { setError, formState: { errors } } = useForm();

  useEffect(() => {
    if (participantsCreated) {
      toast('Participants Created', { containerId: 'RIGHT' });
      resetParticipantsCreated();
      history.goBack();
    }
  }, [participantsCreated]);

  useEffect(() => {
    resetParticipantsCreated();
    return () => {
      resetParticipantsCreated();
    };
  }, []);

  useEffect(() => {
    if (binaryString) {
      const formData = {};
      formData.section = id;
      formData.file = binaryString;
      createSectionParticipants(formData, setError);
    }
  }, [binaryString]);


  return (
    <Modal title="Create Participants">
      <FileUpload
        setBinaryString={setBinaryString}
      />
      {errors.name}
      {errors.description}
    </Modal>
  );
};

ParticipantsCreate.propTypes = {
  createSectionParticipants: PropTypes.func.isRequired,
  participantsCreated: PropTypes.bool,
  resetParticipantsCreated: PropTypes.func.isRequired,
};

ParticipantsCreate.defaultProps = {
  participantsCreated: false,
};

export default ParticipantsCreate;
