import React from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import classNames from 'classnames';
import { getMe, userQuerySettings } from '../../../queries/user';
import { getAssignment, getAssignmentResults } from '../../../queries/student_assignments';
import Button from '../../../components/button/button';
import Loading from '../../../components/loading/loading';
import Navigation from '../../../components/navigation/navigation';
import ScrollArea from '../../../components/scroll-area/scroll-area';
import Editor from '../../../components/editor/editor';
import FilePreview from '../../../components/file-preview';
import styles from './results.scss';

const buttonClasses = classNames("rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 cursor-pointer");

const Submission = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const user = useQuery('me', getMe, userQuerySettings);
  const assignment = useQuery(['student-assignment', id], () => getAssignment(id));
  const results = useQuery(['student-assignment-results', id], () => getAssignmentResults(id));

  if (user.isLoading || assignment.isLoading || results.isLoading) {
    return <Loading />
  }

  return (
    <div className={styles.container}>
      <Navigation
        history={history}
        title="Your Submission"
        backURL={`/results/${id}`}
        hasBackButton
      />
      <ScrollArea>
        <div className={styles.content}>
          <div className={styles.welcome}>
            <h3>
              Your Submission for {assignment.data.title}
            </h3>
            <span className={buttonClasses}>
              <Link to={`/results/${id}`}>Back to Results</Link>
            </span>
          </div>
          <div className="flex w-full flex-column">
            {results.data.submission.files.map((file, index) => (
              <FilePreview
                file={file}
                key={file.file} />
            ))}
          </div>
          <div className={styles.content}>
            <Editor
              body={results.data.submission.body}
              readOnly
              toolbarHidden
            />
          </div>
        </div>
      </ScrollArea>
    </div>
  )
};

export default Submission;