import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';

import { assignmentsSelector } from '../../../selectors/assignments';

import AssignmentListItem from '../../../components/assignment-list-item/assignment-list-item';
import List from '../../../components/list/list';


import styles from './course-detail.scss';


const CourseDetailOverview = (props) => {
  const { history, assignments } = props;

  return (
    <section className={styles.section}>
      <List>
        {assignments.length === 0 && (
          <h3>This Course currently has no assignments</h3>
        )}
        {assignments.map((assignment) => (
          <AssignmentListItem
            history={history}
            url="assignments"
            id={assignment.id}
            title={assignment.title}
            dueDate={assignment.due_date}
            imageURL={assignment.thumbnail}
            state={assignment.state}
            paused={assignment.paused}
            key={assignment.id}
            active={assignment.active}
            type={assignment.type}
          />
        ))}
      </List>
    </section>
  );
};

CourseDetailOverview.propTypes = {
  assignments: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  assignments: assignmentsSelector(state.assignments.assignments),
  course: state.courses.course,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetailOverview);
