import React, { useEffect, useState, useRef } from 'react';
import { useTransition, animated } from 'react-spring';
import { useQuery } from 'react-query';
import { getMe, userQuerySettings } from '../../queries/user';
import Avatar from '../avatar/avatar';
import ArrowDown from '../../../assets/icons/arrow-button-down.svg';

import styles from './dropdown.scss';


const Dropdown = ({ children }) => {
  const node = useRef();

  const [open, setOpen] = useState(false);

  const user = useQuery('me', getMe, userQuerySettings);

  const transitions = useTransition(open, null, {
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  let fullName = '';
  let firstName = '';
  if (user.isSuccess) {
    fullName = user.data.display_name;
    firstName = user.data.first_name;
  }

  if (!user.isSuccess) {
    return (
      <div ref={node} className={styles.container}>
        Login
      </div>
    );
  }

  return (
    <div ref={node} className={styles.container}>
      <button
        type="button"
        className={styles.button}
        onClick={() => setOpen(!open)}
      >
        <div className={styles.name}>
          <span>Hi, {firstName}</span>
        </div>
        <div className={styles.arrow}>
          <ArrowDown className={styles.icon} />
        </div>
        <div className={styles.avatar}>
          <Avatar
            name={fullName}
          />
        </div>
      </button>
      {transitions.map(({ item, key, props }) => item && (
        <animated.div key={key} style={props} className={styles.dropdown}>
          {children}
        </animated.div>
      ))}
    </div>
  );
};

export default Dropdown;
