import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';

import Navigation from '../../../components/navigation/navigation';
import List from '../../../components/list/list';
import ScrollArea from '../../../components/scroll-area/scroll-area';
import CourseListItem from '../../../components/course-list-item/course-list-item';
import Footer from '../../../components/footer/footer';
import AddIcon from '../../../../assets/icons/add-square.svg';

import { getMe, userQuerySettings } from '../../../queries/user';

import styles from './dashboard.scss';


const Dashboard = (props) => {
  const { getCourses, getSections, getSubmissions, courses } = props;

  const user = useQuery('me', getMe, userQuerySettings);
  const history = useHistory();

  useEffect(() => {
    getCourses();
    getSections();
    getSubmissions();
  }, []);

  if (user.isLoading) {
    return (<div>Loading...</div>);
  }

  return (
    <div className={styles.container}>
      <Navigation />
      <ScrollArea>
        <div className={styles.intro}>
          <div className={styles.welcome}>
            <h3>
              Welcome Back,
              {' '}
              {user.data.first_name}
              !
            </h3>
            <span>
              This is the administrator dashboard. Here you can create new Courses
              or administer your existing Courses.
            </span>
          </div>
        </div>
        <List>
          {!user.data.is_lti &&
            <div className={styles.button}>
              <Link
                className={styles.buttonInner}
                to={{ pathname: '/courses/create', state: { modal: true } }} >
                <AddIcon />
                <span>Create new Course</span>
              </Link>
            </div>
          }
          {courses.map((item, index) => (
            <CourseListItem
              id={item.id}
              title={item.name}
              key={item.id}
              createdDate={item.created}
              showCreatedDate={user.data.is_lti}
            />
          ))}
        </List>
        <Footer />
      </ScrollArea>
    </div>
  );
};

Dashboard.propTypes = {
  getSubmissions: PropTypes.func.isRequired,
  getCourses: PropTypes.func.isRequired,
  getSections: PropTypes.func.isRequired,
};


export default Dashboard;
