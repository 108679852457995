// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".footer__container___OomQ2{\n  background-color:#fff;\n  padding-top:4rem;\n  margin-top:8rem\n}\n\n.footer__container___OomQ2 h4{\n  text-transform:uppercase;\n  font-weight:bold;\n  letter-spacing:1px;\n  margin-bottom:1rem\n}\n\n.footer__container___OomQ2 li{\n  --text-opacity:1;\n  color:#718096;\n  color:rgba(113, 128, 150, var(--text-opacity));\n  padding:8px 0\n}\n\n.footer__container___OomQ2 li:hover{\n  --text-opacity:1;\n  color:#3182ce;\n  color:rgba(49, 130, 206, var(--text-opacity))\n}\n\n.footer__container___OomQ2 ul{\n  margin-bottom:5rem\n}\n\n.footer__subline___1maOB{\n  font-size:10px;\n  color:#9b9b9b;\n  padding-bottom:2rem\n}", "",{"version":3,"sources":["footer.scss","<no source>"],"names":[],"mappings":"AAAA;EAAW,qBAAqB;EAAC,gBAAgB;EAAC;AAAe;;AAAC;EAAc,wBAAwB;EAAC,gBAAgB;EAAC,kBAAkB;EAAC;AAAkB;;AAAC;ECAhK,iBAAA;EAAA,cAAA;EAAA,+CAAA;EDAmM;AAAa;;AAAC;ECAjN,iBAAA;EAAA,cAAA;EAAA;ADAyP;;AAAC;EAAc;AAAkB;;AAAC;EAAS,cAAc;EAAC,aAAa;EAAC;AAAmB","file":"footer.scss","sourcesContent":[".container{background-color:#fff;padding-top:4rem;margin-top:8rem}.container h4{text-transform:uppercase;font-weight:bold;letter-spacing:1px;margin-bottom:1rem}.container li{@apply text-gray-600;padding:8px 0}.container li:hover{@apply text-blue-600}.container ul{margin-bottom:5rem}.subline{font-size:10px;color:#9b9b9b;padding-bottom:2rem}",null]}]);
// Exports
exports.locals = {
	"container": "footer__container___OomQ2",
	"subline": "footer__subline___1maOB"
};
module.exports = exports;
