import { createApiAction, API_URL } from './api';

export const loginUser = createApiAction('LOGIN_USER', (formData) => ({
  endpoint: `${API_URL}/api/login/`,
  method: 'POST',
  body: JSON.stringify(formData),
}));

export const logoutUser = createApiAction('LOGOUT_USER', {
  endpoint: `${API_URL}/api/logout/`,
  method: 'POST',
});

export const passwordReset = createApiAction('PASSWORD_RESET', (formData) => ({
  endpoint: `${API_URL}/api/reset/`,
  method: 'POST',
  body: JSON.stringify(formData),
}));

export const passwordResetConfirm = createApiAction('PASSWORD_RESET_CONFIRM', (formData) => ({
  endpoint: `${API_URL}/api/reset/confirm/`,
  method: 'POST',
  body: JSON.stringify(formData),
}));

export const registerUser = createApiAction('REGISTER_USER', (formData) => ({
  endpoint: `${API_URL}/api/register/`,
  method: 'POST',
  body: JSON.stringify(formData),
}));

export const getUser = createApiAction('GET_USER', () => ({
  endpoint: `${API_URL}/api/users/me/`,
  method: 'GET',
}));

export const changePassword = createApiAction('PASSWORD_CHANGE', (formData) => ({
  endpoint: `${API_URL}/api/password/change/`,
  method: 'POST',
  body: JSON.stringify(formData),
}));

export const RESET_PASSWORD_CHANGED = 'RESET_PASSWORD_CHANGED';

export const resetPasswordChanged = () => {
  return {
    type: RESET_PASSWORD_CHANGED,
  };
};

export const getUserDetail = createApiAction('GET_USER_DETAIL', (userID) => ({
  endpoint: `${API_URL}/api/users/${userID}/`,
  method: 'GET',
}));

