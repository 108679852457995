import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getLeague } from '../../../queries/admin-queries';
import Loading from '../../../components/loading/loading';

const LeagueRow = (props) => {
  const { item, index, stageOneWinners, stageTwoWinners } = props
  const stageOneClasses = stageOneWinners.includes(item.id) ? 'bg-green-200' : ''
  const stageTwoClasses = stageTwoWinners.includes(item.id) ? 'bg-green-200' : ''

  return (
    <tr key={index}>
      <td>{item.first_name} {item.last_name}</td>
      <td><a href={`mailto:${item.real_email}`}>{item.real_email}</a></td>
      <td className={stageOneClasses}>{item.submission_averages.overall_average && item.submission_averages.overall_average.toFixed(2)}</td>
      <td className={stageTwoClasses}>{item.review_averages.overall_average && item.review_averages.overall_average.toFixed(2)}</td>
    </tr>
  )
}

const LeagueDetail = (props) => {
  const { id, leagueID } = useParams();
  const league = useQuery(['admin-assignment-league', leagueID], () => getLeague(leagueID), { cacheTime: 60000, staleTime: 10000, keepPreviousData: true });

  if (league.isLoading) {
    return <Loading />;
  }

  // Determine stage 1 league winner
  let stageOneWinners = [];
  let stageOneLargest = null;
  // - determine winning score
  league.data.members.forEach((member) => {
    if (stageOneLargest === null) {
      stageOneLargest = member.submission_averages.overall_average;
    }
    if (stageOneLargest < member.submission_averages.overall_average) {
      stageOneLargest = member.submission_averages.overall_average;
    }
  })

  league.data.members.forEach((member) => {
    if (member.submission_averages.overall_average === stageOneLargest) {
      stageOneWinners.push(member.id);
    }
  })

  // Determine stage 2 league winner
  let stageTwoWinners = [];
  let stageTwoLargest = null;
  league.data.members.forEach((member) => {
    if (stageTwoLargest === null) {
      stageTwoLargest = member.review_averages.overall_average;
    }
    if (stageTwoLargest < member.review_averages.overall_average) {
      stageTwoLargest = member.review_averages.overall_average;
    }
  })

  league.data.members.forEach((member) => {
    if (member.review_averages.overall_average === stageTwoLargest) {
      stageTwoWinners.push(member.id);
    }
  })

  return (
    <React.Fragment>
      <h1 className="text-2xl font-bold mb-4">{league.data.name} Detail</h1>
      <p className="mb-4">
        This league reviews Stage 1: Submissions for {' '}
        <NavLink
          className="text-blue-500 hover:text-blue-700 underline"
          to={`/assignments/${id}/reports/league/${league.data.reviews_submission_for}`}>{league.data.reviews_submission_for_name}</NavLink>
        {' '}and Stage 2: Reviews for {' '}
        <NavLink
          className="text-blue-500 hover:text-blue-700 underline"
          to={`/assignments/${id}/reports/league/${league.data.reviews_reviews_for}`}>{league.data.reviews_reviews_for_name}</NavLink>
      </p>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Stage 1 Average {league.data.overall_submission_averages}</th>
            <th>Stage 2 Average {league.data.overall_review_averages}</th>
          </tr>
        </thead>
        <tbody>
          {league.data && league.data.members.map((item, index) => (
            <LeagueRow
              index={index}
              item={item}
              key={index}
              stageOneWinners={stageOneWinners}
              stageTwoWinners={stageTwoWinners}
            />
          ))}
        </tbody>
      </table>
      <p className="mt-4 mb-4">
        This league has it's Stage 1: Submissions reviewed by {' '}
        <NavLink
          className="text-blue-500 hover:text-blue-700 underline"
          to={`/assignments/${id}/reports/league/${league.data.submissions_reviewed_by}`}>{league.data.submissions_reviewed_by_name}</NavLink>
        {' '}and it's Stage 2: Reviews reviewed by {' '}
        <NavLink
          className="text-blue-500 hover:text-blue-700 underline"
          to={`/assignments/${id}/reports/league/${league.data.reviews_reviewed_by}`}>{league.data.reviews_reviewed_by_name}</NavLink>
      </p>

      <p className="mt-8 mb-4 text-gray-600">
        No score: The student did not receive peer evaluations<br />
        A score of 0: The student did not participate during that stage
      </p>
    </React.Fragment >

  );
};


export default LeagueDetail;
