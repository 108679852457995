import { connect } from 'react-redux';

import { passwordReset } from '../../actions/auth';
import { formApiAction } from '../../actions/api';
import ForgotPassword from './forgot-password';

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  loggedIn: state.auth.loggedIn,
  user: state.auth.user,
});

const mapDispatchToProps = {
  resetUser: formApiAction(passwordReset),
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
