import { connect } from 'react-redux';

import { getSectionDetail, getSectionMembers } from '../../../actions/sections';
import { getAssignments } from '../../../actions/assignments';
import { getSubmissions } from '../../../actions/submissions';
import SectionDetail from './section-detail';

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.sections.loading,
  sectionDetail: state.sections.sectionDetail,
  sectionMembers: state.sections.sectionMembers
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const { match: { params: { id } } } = ownProps;
  return {
    getSectionDetail: () => dispatch(getSectionDetail(id)),
    getSectionMembers: () => dispatch(getSectionMembers(id)),
    getAssignments: (params) => dispatch(getAssignments(params)),
    getSubmissions: (params) => dispatch(getSubmissions(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionDetail);
