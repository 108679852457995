import React from 'react';
import {
  NavLink, useParams
} from 'react-router-dom';
import classNames from 'classnames';
import { useQuery } from 'react-query';
import { getMe, userQuerySettings } from '../../../queries/user';


const AssignmentDetailReports = (props) => {
  const { id } = useParams();
  const user = useQuery('me', getMe, userQuerySettings);
  const buttonClasses = classNames('rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 w-full cursor-pointer my-4');

  if (user.isLoading) {
    return <div>Loading...</div>;
  }

  let hbpDemoText = null
  let linkClasses = "text-blue-500 hover:text-blue-700 underline"
  if (user.data.is_hbp && user.data.is_demo) {
    hbpDemoText = (
      <div className="m-6 bg-blue-100 text-lg border border-blue-400 p-6">
        <p className="my-2">
          Feel free to edit this demo's text and questions. This Reports page
          will stay unchanged, ensuring ongoing access to the Carlsbad Home Care
          demo reports. Your edits will be saved for 7 days before the demo resets to its
          original content.
        </p>
        <p className="my-2">
          We suggest that instructors watch these short videos that explain the
          student experience. (The videos combined are less than 3 minutes).
        </p>
        <p className="my-2">
          <a className={linkClasses} href="https://peer-learning-prod-static.nyc3.digitaloceanspaces.com/videos/PeerExchange%20Stage1.mp4">Stage 1: Submission</a>
        </p>
        <p className="my-2">
          <a className={linkClasses} href="https://peer-learning-prod-static.nyc3.digitaloceanspaces.com/videos/PeerExchange%20Stage2.mp4">Stage 2: Review</a>
        </p>
        <p className="my-2">
          <a className={linkClasses} href="https://peer-learning-prod-static.nyc3.digitaloceanspaces.com/videos/PeerExchange%20Stage3.mp4">Stage 3: Score Reviews</a>
        </p>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="flex flex-col">
        <h1 className="text-2xl font-bold my-6">Assignment Reports</h1>
        <NavLink
          exact
          className={buttonClasses}
          to={`/assignments/${id}/reports/participation`}
        >All Stage Participation Reports</NavLink>
        <NavLink
          exact
          className={buttonClasses}
          to={`/assignments/${id}/reports/leagues/`}
        >View Leagues and Student Performance</NavLink>
        <NavLink
          exact
          className={buttonClasses}
          to={`/assignments/${id}/reports/students`}
        >Standardized Scoring</NavLink>
        <NavLink
          exact
          className={buttonClasses}
          to={`/assignments/${id}/reports/results`}
        >View Student Feedback</NavLink>
        <NavLink
          exact
          className={buttonClasses}
          to={`/assignments/${id}/reports/criticalthinking`}
        >ChatGPT advice re: Critical Thinking </NavLink>
        <NavLink
          exact
          className={buttonClasses}
          to={`/assignments/${id}/reports/writing`}
        >ChatGPT advice re: Writing</NavLink>
      </div>
      <div className="my-8">
        {hbpDemoText}
      </div>
    </React.Fragment>

  );
};


export default AssignmentDetailReports;
