import { createApiAction, API_URL } from './api';


export const getSubmissions = createApiAction('GET_SUBMISSIONS', (params = '') => ({
  endpoint: `${API_URL}/api/student/submissions/${params}`,
  method: 'GET',
}));

export const getSubmission = createApiAction('GET_SUBMISSION', (submissionID) => ({
  endpoint: `${API_URL}/api/student/submissions/${submissionID}/`,
  method: 'GET',
}));

export const RESET_SUBMISSION_CREATED = 'RESET_SUBMISSION_CREATED';

export const resetSubmissionCreated = () => ({
  type: RESET_SUBMISSION_CREATED,
});

export const updateSubmissionBody = createApiAction('UPDATE_SUBMISSION_BODY', (submissionID, data) => ({
  endpoint: `${API_URL}/api/student/submissions/${submissionID}/`,
  method: 'PATCH',
  body: JSON.stringify(data),
}));

export const SET_SUBMISSION_DRAFT_DATA = 'SET_SUBMISSION_DRAFT_DATA';

export const setSubmissionDraftData = (body, plainText) => ({
  type: SET_SUBMISSION_DRAFT_DATA,
  body,
  plainText,
});

export const createOrUpdateSubmission = createApiAction('CREATE_OR_UPDATE_SUBMISSION', (formData) => ({
  endpoint: `${API_URL}/api/student/save-submission/`,
  method: 'POST',
  body: JSON.stringify(formData),
}));

export const getAssignmentSubmission = createApiAction('GET_ASSIGNMENT_SUBMISSION', (assignmentID) => ({
  endpoint: `${API_URL}/api/student/assignments/${assignmentID}/submission/`,
  method: 'GET',
}));

export const deleteSubmissionFile = createApiAction('DELETE_SUBMISSION_FILE', (submissionFileID) => ({
  endpoint: `${API_URL}/api/student/submission-files/${submissionFileID}/`,
  method: 'DELETE',
  meta: { submissionFileID }
}));
