import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import styles from './notifications.scss';


const Notifications = (props) => {
  const { notifications } = props;

  useEffect(() => {
    if (notifications) {
      for (let i = 0; i < notifications.length; i++) {
        toast(notifications[i], { containerId: 'RIGHT' });
      }
    }
  }, [notifications]);

  return (
    <React.Fragment>
      <ToastContainer
        draggablePercent={60}
        toastClassName={styles.container}
        bodyClassName={styles.body}
        progressClassName={styles.progressBar}
        enableMultiContainer
        containerId="RIGHT"
      />
      <ToastContainer
        draggable={false}
        className={styles.smallContainer}
        toastClassName={styles.smallToast}
        bodyClassName={styles.smallBody}
        enableMultiContainer
        containerId="BOTTOM"
        autoClose={1000}
        transition={Slide}
        position={toast.POSITION.BOTTOM_RIGHT}
        pauseOnFocusLoss={false}
        hideProgressBar
        pauseOnHover={false}
        closeButton={false}
      />
    </React.Fragment>
  );
};

Notifications.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.string)
};

Notifications.defaultProps = {
  notifications: []
};

const mapStateToProps = (state) => ({
  notifications: state.notifications.notifications,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
