import React from 'react';
import classNames from 'classnames';
import { NavLink, useParams } from 'react-router-dom';
import Navigation from '../components/navigation/navigation';
import ScrollArea from '../components/scroll-area/scroll-area';

const Card = (props) => {
  const { title } = props
  return (
    <div className="col-span-2 flex max-w-sm rounded overflow-hidden shadow-lg m-2 bg-blue-200 border border-blue-400">
      <div className="p-6 text-center font-semibold text-xl text-gray-700 m-auto">
        {title}
      </div>
    </div>
  )
}

const Arrow = (props) => {
  return (
    <div className="flex justify-center items-center">
      <svg version="1.1" viewBox="0 0 1200 1200" className="w-6 h-6" style={{ fill: "#2c5282" }}>
        <path d="m1200 600-525 525v-300h-675v-450h675v-300z" />
      </svg>
    </div>
  )
}

const Demo = (props) => {
  const { id } = useParams();

  const buttonClasses = classNames('rounded py-3 px-5 text-2xl text-white bg-green-500 hover:bg-green-700 w-full cursor-pointer my-1');

  // Determine next page location based on whether or not we have a Course ID which indicates it
  // is a faculty demo
  let linkURL = "/dashboard"
  let buttonText = "CLICK HERE for Demo: Student View"
  if (id) {
    linkURL = `/assignments/${id}/edit`
    buttonText = "CLICK HERE for Demo: Instructor View"
  }

  console.log(id)
  console.log(linkURL)

  return (
    <div className="w-full h-screen">
      <Navigation
        backURL="/dashboard"
        title="Welcome to Peer Exchange Demo"
        hasBackButton
      />
      <ScrollArea>
        <div className="p-6">

          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Save Time, Enhance Learning, Fast Feedback and Evaluation
            </h2>
          </div>

          <div className="mx-auto max-w-4xl my-6">
            <h3 className="my-6 text-2xl font-bold tracking-tight text-gray-600 sm:text-4xl">
              Instructor Flow
            </h3>
            <div className="grid md:grid-cols-8 sm:grid-cols-1">
              <Card title="Choose Assignment" />
              <Arrow />
              <Card title="Logistics, automate student experience" />
              <Arrow />
              <Card title="Preview reports, keep or release ChatGPT feedback" />
            </div>
          </div>

          <div className="mx-auto max-w-4xl my-6">
            <h3 className="my-6 text-2xl font-bold tracking-tight text-gray-600 sm:text-4xl">
              Student Flow
            </h3>
            <div className="grid md:grid-cols-8 sm:grid-cols-1">
              <Card title="Complete Assignment" />
              <Arrow />
              <Card title="Review peers' work" />
              <Arrow />
              <Card title="Find feedback from peers &amp; ChatGPT" />
            </div>
          </div>

          <div className="mt-10 mx-auto max-w-2xl text-center">
            <NavLink exact to={linkURL} className={buttonClasses}>
              {buttonText}
            </NavLink>
          </div>
        </div>

      </ScrollArea>
    </div>
  )
}

export default Demo;
