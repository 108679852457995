import React, { useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CloseIcon from '../../../assets/icons/close.svg';

import styles from './modal.scss';


const Modal = (props) => {
  const {
    children, className, disableScrolling, title, fullWidth, customCloseHandler
  } = props;

  const node = useRef();
  const history = useHistory();

  const handleClose = () => {
    if (customCloseHandler) {
      customCloseHandler();
    } else {
      history.goBack();
    }
  };

  const handleEscKey = useCallback((event) => {
    if (event.keyCode === 27) {
      handleClose();
    }
  }, []);

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    handleClose();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscKey, false);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscKey, false);
    };
  }, []);

  return (
    <div
      className={classNames(
        styles.container, {
        [styles.disableScrolling]: disableScrolling,
        [styles.fullWidth]: fullWidth,
      },
        className,
      )}
    >
      <div
        ref={node}
        className={classNames(
          styles.modal, {
          [styles.disableScrolling]: disableScrolling,
          [styles.fullWidth]: fullWidth,
        },
        )}
      >
        <div className={styles.header}>
          <div className={styles.title}>
            {title}
          </div>
          <div
            className={styles.close}
            onClick={handleClose}
          >
            <CloseIcon />
          </div>
        </div>
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  title: PropTypes.string,
  handleCLose: PropTypes.func,
};

Modal.defaultProps = {
  children: [],
  title: '',
  handleCLose: undefined
};

export default Modal;
