import React, { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import classNames from 'classnames';
import { getAssignment, getAssignmentDefaults, getAssignmentAICriticalThinking } from '../../../queries/admin-queries';
import { getMe, userQuerySettings } from '../../../queries/user';
import Loading from '../../../components/loading/loading';

const Demo = (props) => {
  return (
    <>
      <div className="my-6 bg-blue-100 border border-blue-300 p-6">
        The demo does not currently include any ChatGPT-generated responses.  However, you can view some
        example Critical Thinking responses below in the form the students would see it.
      </div>

      <div className="my-6">
        <h4 className="text-2xl font-bold border border-1 border-red-400 border-solid p-2 bg-red-200">
          ChatGPT Critical Thinking Feedback #1
        </h4>
        <p className="my-4">The Submission: Accounting and Financial Management. MBA midterm exam requiring a memo and recommendations regarding “material misstatements” in payroll expenses.</p>
        <div className="my-4 p-1 border border-1 border-red-400 bg-red-100 p-4">
          <p className="my-4">The logical structure of the submission is generally clear and follows a coherent progression. The author begins by stating the business problem and the goal of assessing the risk of material misstatements in the payroll expenses of Bibitor. They then describe the steps taken in the analysis, including reviewing the dataset, focusing on specific stores, identifying duplicate payments to employees, creating a new dataset, and analyzing store efficiency.</p>
          <p className="my-4">The use of evidence is present throughout the submission. The author references the available dataset, specifically mentioning information about employees, payroll, store location, and store activity. They also provide specific examples, such as the identification of stores that were open for less than 350 days and the discovery of employees with the same ID in the same year but belonging to different stores. These examples help support the argument that there are errors or fraud in the payroll expenses.</p>
          <p className="my-4">The identification of assumptions is somewhat limited in the submission. While the author mentions the decision to focus on stores open for less than 350 days, they do not provide a clear rationale for this decision. Similarly, when discussing the creation of a new variable to measure store efficiency, there is no explanation for why this is a relevant metric. Providing more explicit reasoning and rationale for these assumptions would strengthen the argument.</p>
          <p className="my-4">Counterarguments are not explicitly addressed in the submission. The focus is primarily on identifying errors and misstatements in the payroll expenses, rather than considering alternative explanations or potential counterarguments. Considering and addressing counterarguments would help strengthen the overall argument.</p>
          <p className="my-4">The overall coherence of the submission is fairly strong. The author presents a clear progression of steps taken in the analysis and supports their findings with evidence from the dataset. However, the submission would benefit from clearer explanations and justifications for certain assumptions made, as well as a more thorough consideration of potential counterarguments. Additionally, providing more specific recommendations for how to avoid fraud and overstatement of expenses would add strength to the conclusion.</p>
        </div>
      </div>

      <div className="my-6">
        <h4 className="text-2xl font-bold border border-1 border-red-400 border-solid p-2 bg-red-200">
          ChatGPT Critical Thinking Feedback #2
        </h4>
        <p className="my-4">The Submission: An Undergraduate course Business & Society. The draft paper focuses on the detrimental effects of adding sugar to food.  The recommendation: McDonalds purchase Sweetgreen's</p>
        <div className="my-4 p-1 border border-1 border-red-400 bg-red-100 p-4">
          <p className="my-4">The argument presented in the submission focuses on the potential benefits of McDonald's acquiring Sweetgreen as a means to reduce added sugar consumption in low-income communities worldwide. The logical structure of the argument is generally clear, with the author introducing the problem of added sugar consumption, presenting evidence on the health risks associated with high sugar intake, discussing the lack of food choices in low-income communities, examining the role of key stakeholders, and finally proposing McDonald's acquisition of Sweetgreen as a possible solution. The evidence provided includes statistics on obesity, diabetes, and cancer rates related to high sugar intake, as well as studies on the high sugar content in fast food meals and the lack of healthy food options in low-income areas. This evidence helps support the argument that added sugar consumption is a significant issue that needs to be addressed. </p>
          <p className="my-4">The identification of assumptions is somewhat limited in the submission. There is an implicit assumption that McDonald's acquisition of Sweetgreen would result in the availability of healthier food options at McDonald's restaurants, without specifically addressing the challenges or potential changes that may be required in McDonald's current practices or menu offerings. Additionally, the assumption that this acquisition would benefit society as a whole is also not thoroughly explored or supported with evidence. Considering counterarguments is a crucial aspect of critical thinking, but this submission does not sufficiently address potential counterarguments or opposing viewpoints. For example, counterarguments that McDonald's acquisition of Sweetgreen may be driven by financial motives rather than a genuine commitment to reducing added sugar consumption are not addressed. </p>
          <p className="my-4">The overall coherence of the submission is reasonably maintained, and the author effectively presents evidence and examples to support their claims. The areas where the argument is particularly strong include the discussion of the health risks associated with high sugar intake and the lack of healthy food options in low-income areas. The submission also highlights the potential benefits of McDonald's acquiring Sweetgreen, such as access to a growing market of health-conscious consumers and the integration of Sweetgreen's technological and operational expertise. </p>
          <p className="my-4">To strengthen the argument, the author could provide more comprehensive analysis of potential counterarguments and address the assumptions underlying the proposed solution. They could also provide more evidence or examples to support the assertion that McDonald's acquisition of Sweetgreen would lead to the availability of healthier food options at McDonald's restaurants. Additionally, the submission would benefit from a more nuanced examination of the potential risks and challenges associated with this acquisition, including potential changes in employment practices, competition, and pricing. This would help strengthen the overall credibility and persuasiveness of the argument.</p>
        </div>
      </div>

      <div className="my-6">
        <h4 className="text-2xl font-bold border border-1 border-red-400 border-solid p-2 bg-red-200">
          ChatGPT Critical Thinking Feedback #3
        </h4>
        <p className="my-4">The Submission: An undergraduate course: Human Resource management. The student's draft paper is on The Impact of Abusive Supervision on Employees' Emotions</p>
        <div className="my-4 p-1 border border-1 border-red-400 bg-red-100 p-4">
          <p className="my-4">The overall structure of the submission follows a logical progression, starting with an introduction to the topic of abusive supervision and its impact on employees' emotions. The submission then provides evidence to support this claim, including a study that demonstrates the negative effects of abusive supervision on employees' affect, customer orientation, and life satisfaction. The submission also discusses the practical consequences of abusive supervision, such as reduced work quality, increased turnover, and negative workplace culture. </p>
          <p className="my-4">The use of evidence in the submission is generally strong, as specific studies and findings are cited to support the claims made. However, the submission could benefit from providing more specific details about the studies, such as sample size, research methods, and any limitations or potential biases. This would enhance the credibility and depth of the evidence presented. </p>
          <p className="my-4">The submission does a good job of identifying assumptions related to the topic, such as the assumption that abusive supervision negatively impacts employees' emotions and job performance. However, it could be strengthened by explicitly addressing any potential counterarguments or alternative perspectives. For example, the submission could acknowledge that not all employees may be equally affected by abusive supervision and that individual differences may play a role in how employees respond to such behavior. </p>
          <p className="my-4">The coherence of the submission is generally good, as the ideas and arguments are presented in a logical and organized manner. However, there are some areas where the submission could be further improved. One improvement would be to provide clearer transitions between ideas and paragraphs to enhance the overall flow of the submission. Additionally, the submission could benefit from more precise and concise language to improve clarity and readability. </p>
          <p className="my-4">Overall, the submission provides a solid analysis of the topic of abusive supervision and its impact on employees' emotions. It effectively uses evidence to support its claims, identifies assumptions related to the topic, and considers counterarguments. The structure and coherence of the submission are generally strong, but there are areas where improvements could be made to enhance the clarity and flow of the argument.</p>
        </div>
      </div>
    </>
  )
}

const CriticalThinking = (props) => {
  const { thinking } = props
  const [expanded, setExpanded] = useState(false);

  const tdStyle = {
    "width": "50%",
    "maxWidth": "50%",

  }
  const smallStyle = {
    "maxHeight": "100px",
    "textOverflow": "ellipsis",
    "overflow": "hidden",
    "overWrap": "break-word",
    "whiteSpace": "normal",
    "WebkitLineClamp": "6",
    "WebkitBoxOrient": "vertical",
    "display": "-webkit-box",
  }
  const largeStyle = {
    "overflow": "hidden",
    "whiteSpace": "pre-line",
  }

  const toggle = () => {
    setExpanded(!expanded);
  }

  return (
    <td onClick={() => toggle()} style={tdStyle}>
      <span style={expanded ? largeStyle : smallStyle}>{thinking}</span>
      {!expanded && <span>...</span>}
    </td>
  )
}

const AssignmentReportsAICriticalThinking = (props) => {
  const { id } = useParams();
  const user = useQuery('me', getMe, userQuerySettings);
  const assignment = useQuery(['admin-assignment', id], () => getAssignment(id), getAssignmentDefaults);
  const thinking = useQuery(['admin-assignment-ai-critical-thinking', id], () => getAssignmentAICriticalThinking(id), { cacheTime: 60000, staleTime: 10000, keepPreviousData: true });
  const buttonClasses = classNames('rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 w-full cursor-pointer my-4');

  if (assignment.isLoading || thinking.isLoading || user.isLoading) {
    return <Loading />;
  }

  if (user.data.is_demo) {
    return (<Demo />)
  }

  if (!assignment.data.include_ai_critical_thinking) {
    return (
      <React.Fragment>
        <h1 className="text-2xl font-bold my-6">ChatGPT Critical Thinking</h1>
        <p>This feedback type was not turned on for this assignment.</p>
      </React.Fragment>
    )
  }

  if (assignment.data.state == 'pending' || assignment.data.state == 'submission') {
    return (
      <React.Fragment>
        <h1 className="text-2xl font-bold mb-4">ChatGPT Critical Thinking</h1>
        <p className="p-4 mt-8 border bg-blue-100">
          This report is only available in later stages of an Assignment.
        </p>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <h1 className="text-2xl font-bold my-6">ChatGPT Critical Thinking</h1>
      <table className="table-fixed">
        <thead>
          <tr>
            <th className="w-1/4 align-top">Participant</th>
            <th className="w-1/2">Critical Thinking</th>
            <th className="w-1/4">Submission</th>
          </tr>
        </thead>
        <tbody>
          {thinking.data.map((t) => {
            return (
              <tr key={t.id} className="hover:bg-blue-100">
                <td className="w-1/4 align-top">
                  <span className="font-bold">{t.first_name} {t.last_name}</span><br />
                  <span className="text-gray-500">{t.email}</span>
                </td>
                <CriticalThinking key={t.id} thinking={t.critial_thinking} />
                <td className="w-1/4 align-middle">
                  <NavLink
                    exact
                    className={buttonClasses}
                    to={`/submissions/${t.submission_id}`}
                  >
                    View Submission
                  </NavLink>
                </td>
              </tr>
            )
          })}
          {thinking.data.length === 0 && (
            <tr>
              <td colSpan="5">No Critical Thinking data found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </React.Fragment>

  );
};


export default AssignmentReportsAICriticalThinking;
